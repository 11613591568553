/// Component: Multimedia Container
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Multimedia Container
/// sh-textfield($options: (
///
/// ));
///
@mixin sh-multimedia-container($options: ()) {
  $_default: ();
  $_config: map-merge($_default, $options);
  $_component: ".#{$prefix}multimedia-container";

  // Settings

  $_vertical-breakpoint: 49em; // smaller images on viewports with a height of 780px and lower

  #{component} {
    display: flex;
    flex-direction: column;
    margin: sh-grid("gutter-width") 0;
    overflow-x: hidden;

    @include media-query("sm") {
      align-items: center; // breaks slider on xs viewport
    }

    // triple class selector to override .slick-initialised .slick-slide style
    &__item {
      &#{component}__item {
        &#{component}__item {
          margin: 0 sh-grid("gutter-width") * 0.5;
          display: table; // [1]
        }
      }
    }

    &__link {
      position: relative;
      display: block;
    }

    &__height-control {
      max-height: none;
      max-width: 100%;

      @include media-query("sm") {
        max-height: 48vw;
      }
      @include media-query("lg") {
        max-height: 480px;
      }
      @media only screen and (max-height: $_vertical-breakpoint) {
        max-height: 380px;
      }
    }

    &__caption {
      display: table-caption; // [1]
      caption-side: bottom;
      background-color: sh-color("black-7"); // [1]
      min-height: 5.7em;
      padding: 1rem;
      color: sh-color("black-90");

      @include media-query("sm") {
        min-height: 7.3em; // reserves space for 2 lines of text for optional headline and description
        min-height: 8.498em; // reserves space for 3 lines of text
        min-height: 9.9em; // reserves space for 4 lines of text
        padding: sh-grid("gutter-width");
      }
    }

    &__controls {
      order: 2;
      margin: sh-grid("gutter-width") 0;
      text-align: center;
    }

    &__dots {
      display: inline-block;
      position: relative;
    }

    //
    // avoid jumpiness on loading
    //
    &:not(.slick-initialized) {
      #{$_component}__item:not(:first-child) {
        display: none;
      }

      #{$_component}__height-control {
        @include media-query("sm") {
          height: 48vw;
        }
        @include media-query("lg") {
          height: 480px;
        }
      }
    }

    &__height-control[data-lazy] {
      // @include loader;
      background: sh-color("black-7");
      display: block;
      width: 95vw; // should be full content width, so caption wont collapse

      @include media-query("sm") {
        height: 48vw;
        width: auto;
        min-width: 15em; // musst be smaller than the image for slicks center mode
      }
      @include media-query("md") {
        min-width: 21em; // musst be smaller than the image for slicks center mode
      }
      @include media-query("lg") {
        height: 480px;
        min-width: 26em; // musst be smaller than the image for slicks center mode
      }
    }

    .slick-slide {
      cursor: pointer;

      @include media-query("sm") {
        opacity: 0.3;
        transition: opacity map-get(sh-transition("fade"), "duration") map-get(sh-transition("fade"), "easing");
      }
    }

    .slick-current {
      opacity: 1;
      cursor: default;
    }
    .slick-arrow {
      min-width: auto;
      display: none !important; // override inline style

      @include media-query("md") {
        display: block !important;
      }
    }

    .slick-dots {
      position: static;
      margin-top: 0;
    }

    // [1] prevent flowplayer from collapsing inside slick slides with variable width
    // [2] omit invisible bottom margin
    // [3] hide height control before flowplayer is loaded
    // [4] stop propagation of clicks on inactive slides

    &__height-control--hidden {
      display: none; // [3]
    }

    .flowplayer {
      display: block; // [2]
      pointer-events: none; // [4]

      &:not(.is-fullscreen) .fp-player {
        position: relative; // [1]
      }
      .fp-ratio {
        display: none; // [1]
      }

      #{$_component}__height-control--hidden {
        display: block; // [3]
        visibility: hidden; // [3]
      }
    }

    #{$_component}__link {
      pointer-events: none;
    }

    .slick-current .flowplayer,
    .slick-current #{$_component}__link {
      pointer-events: auto; // [4]
    }

    @content;
  }
}

p {
  @include sh-font-family("text");
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  margin-top: 0;
  margin-bottom: to-rem(30);
  text-rendering: optimizeLegibility;
}

p.lead {
  @include sh-font-size("medium");
  @include sh-font-weight("semibold");
  @include sh-line-height("medium"); //      \
  margin-top: to-rem(30); //                  |––– 90px in total fits the baseline grid 
  margin-bottom: to-rem(30); //              /

  @include media-query("md") {
    @include sh-font-size("large");
    @include sh-font-weight("semibold");
    @include sh-line-height("medium"); //    \
    margin-top: to-rem(29); //                |––– 90px in total fits the baseline grid 
    margin-bottom: to-rem(31); //            /
  }
}

p.small {
  @include sh-font-size("small");
  @include sh-line-height("small");
  margin-bottom: sh-line-height("small");
}

p.xsmall {
  @include sh-font-size("xsmall");
  @include sh-line-height("xsmall");
  margin-bottom: sh-line-height("xsmall");
}

/// Grid System
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Grid System
/// sh-grid($options: (
///   breakpoints: ()
/// ));
///
@use "sass:math";

@mixin sh-grid($options: ()) {
  $_defaults: (
    breakpoints: $sh-breakpoints,
    max-widths: sh-grid("max-widths"),
    gutter-width: sh-grid("gutter-width"),
    columns: 12,
  );
  $config: map-merge($_defaults, $options);

  $gutter-width: map-get($config, "gutter-width");
  $max-widths: map-get($config, "max-widths");

  $padding: $gutter-width * 0.5;
  $margin: math.div($gutter-width, -2);

  // Container widths
  // Set the container width, and override it for fixed navbars in media queries.
  .container {
    @include _container(
      (
        padding: $padding,
      )
    );
    @include _container-max-widths($max-widths);
  }

  // Fluid container
  // Utilizes the mixin meant for fixed width containers, but with 100% width for
  // fluid, full width layouts.
  .container-fluid {
    @include _container(
      (
        padding: $padding,
      )
    );
  }

  // Row
  // Rows contain and clear the floats of your columns.
  .row {
    @include _row(
      (
        margin: $margin,
      )
    );
  }

  // Remove the negative margin from default .row, then the horizontal padding
  // from all immediate children columns (to prevent runaway style inheritance).
  .no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }

  // Columns
  // Common styles for small and large grid columns
  @include _columns(map-get($config, "columns"), map-get($config, "gutter-width"), map-get($config, "breakpoints"));
}

@mixin _container($options) {
  $padding: map-get($options, "padding");

  width: 100%;
  padding-right: $padding;
  padding-left: $padding;
  margin-right: auto;
  margin-left: auto;
}

// For each breakpoint, define the maximum width of the container in a media query
@mixin _container-max-widths($max-widths) {
  @each $breakpoint, $max-width in $max-widths {
    @include media-query($breakpoint) {
      max-width: $max-width;
    }
  }
}

@mixin _row($options) {
  $margin: map-get($options, "margin");

  display: flex;
  flex-wrap: wrap;
  margin-right: $margin;
  margin-left: $margin;
}

@mixin _column($size, $columns: $grid-columns) {
  flex: 0 0 percentage(math.div($size, $columns));
  // Add a `max-width` to ensure content within each column does not blow out
  // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
  // do not appear to require this.
  max-width: percentage(math.div($size, $columns));
}

@mixin _column-offset($size, $columns: $grid-columns) {
  $num: math.div($size, $columns);
  margin-left: if($num == 0, 0, percentage($num));
}

@mixin _columns($columns, $gutter, $breakpoints) {
  // Common properties for all breakpoints
  %grid-column {
    position: relative;
    width: 100%;
    min-height: 1px; // Prevent columns from collapsing when empty
    padding-right: ($gutter * 0.5);
    padding-left: ($gutter * 0.5);
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    // Allow columns to stretch full width below their breakpoints
    @for $i from 1 through $columns {
      .col#{$infix}-#{$i} {
        @extend %grid-column;
      }
    }
    .col#{$infix},
    .col#{$infix}-auto {
      @extend %grid-column;
    }

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      // Provide basic `.col-{bp}` classes for equal-width flexbox columns
      .col#{$infix} {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }
      .col#{$infix}-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none; // Reset earlier grid tiers
      }

      @for $i from 1 through $columns {
        .col#{$infix}-#{$i} {
          @include _column($i, $columns);
        }
      }

      .order#{$infix}-first {
        order: -1;
      }

      .order#{$infix}-last {
        order: $columns + 1;
      }

      @for $i from 0 through $columns {
        .order#{$infix}-#{$i} {
          order: $i;
        }
      }

      // `$columns - 1` because offsetting by the width of an entire row isn"t possible
      @for $i from 0 through ($columns - 1) {
        @if not($infix == "" and $i == 0) {
          // Avoid emitting useless .offset-0
          .offset#{$infix}-#{$i} {
            @include _column-offset($i, $columns);
          }
        }
      }
    }
  }
}

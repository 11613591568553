@mixin ln-menu($options: ()) {
  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .menu {
    &__divider {
      border-bottom: 1px solid sh-color('black-30');
    }
    &__item {
      > .icon-arrow-up,
      > .icon-down {
        &::before {
          position: relative;
          top: 2px;
          line-height: 1px;
        }
      }
      &__equipment-list {
        @media only screen and (min-width: 0em) {
          white-space: normal !important;
          min-width: 10em !important;
        }
        @media only screen and (min-width: 23em) {
          white-space: normal !important;
          min-width: 13.4em !important;
        }
        @media only screen and (min-width: 36em) {
          white-space: nowrap !important;
          min-width: 0 !important;
        }
        @media only screen and (min-width: 75em) {
          white-space: normal !important;
          min-width: 13.5em !important;
        }
        @media only screen and (min-width: 107.625em) {
          white-space: nowrap !important;
          min-width: 0 !important;
        }
      }
    }

    &.is-open {
      display: block;
    }
  }
}

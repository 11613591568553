@mixin ln-pagination($options: ()) {
  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .pagination {
    &__calendar {
      margin: 0 auto;

      @include media-query('lg') {
        margin-left: 0;
      }

      .pagination__item {
        @include sh-color('black-90');
      }

      span.pagination__item:not([href]) {
        background-color: transparent;
        font-weight: normal;
        margin-right: 1rem;
        margin-left: 1rem;
        width: auto;
      }
    }
  }

  .pagination__item:last-child {
    margin-right: 0;
  }

  .pagination-label {
    text-align: center;
    margin-top: -1.5rem;
  }
}

@mixin ln-multiline-input($options: ()) {

  .#{$prefix}multiline-input {
    outline: none;

    &.is-disabled {
      opacity: 0.6;
    }
  }
}

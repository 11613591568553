/// Component: Navigation Bar
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Navigation Bar
/// sh-textfield($options: (
///
/// ));
///
@mixin sh-navigation-bar($options: ()) {
  $_defaults: (
    x-spacer: to-rem(20),
    y-spacer: to-rem(10),
    font-size: to-rem(16),
    toggle-button-width: 2.1111rem,
  );
  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}navigation-bar ";

  #{$_component} {
    @include sh-background-color("black-7");
    box-shadow: 0 4px 0.4rem rgba(27, 27, 27, 0.2);

    &__container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 auto;
      max-width: 100%;
      height: 50px;
      padding-right: 0.833rem;
      padding-left: 0.833rem;

      @include media-query("sm") {
        width: 30em;
      }

      @include media-query("md") {
        width: 40em;
      }

      @include media-query("lg") {
        width: 53.333em;
      }

      @include media-query("xl") {
        width: 80em;
      }
    }

    &__panel {
      @include sh-background-color("black-7");
    }

    &__wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__toggle-button {
      @include sh-color("black-60");
      width: map-get($_config, "toggle-button-width");
      height: 44px;
      margin-right: map-get($_config, "x-spacer");

      [class^="icon"]::before {
        font-size: to-rem(30);
        position: relative;
        top: 3px;
      }

      &:hover {
        @include sh-color("black-90");
      }

      @include media-query("md") {
        display: none;
      }
    }

    &__app-identifier {
      @include sh-font-family("headline");
      @include sh-font-size("small");
      @include sh-color("black-90");

      @include media-query("md") {
        display: none;
      }
    }

    &__list {
      display: none;
      list-style: none;
      @include sh-background-color("black-7");

      @include media-query("md") {
        display: flex; // makes list horizontal
        white-space: nowrap;
        margin: 0 map-get($_config, "x-spacer") * -1;
        padding: 0;
      }
    }

    &__additional-functions {
      flex: none;
      margin-left: auto;
    }

    &__item {
      display: block;
      margin: 0;
      padding: 0;
    }

    &__link {
      @include sh-color("black-60");
      @include sh-font-family("headline-small");
      display: block;
      position: relative;
      margin: 0;
      padding: map-get($_config, "y-spacer") map-get($_config, "x-spacer") map-get($_config, "y-spacer");
      font-size: map-get($_config, "font-size");
      line-height: to-rem(30);
      font-weight: 600;
      cursor: pointer;
      user-select: none;
      outline: 0;
      box-shadow: none;
      white-space: nowrap;

      @include focus {
        color: sh-color("black-60");
        outline: none; // suppress default focus
        box-shadow: none; // suppress default focus

        &::after {
          // mark current item on mobile menu
          content: "";
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          border-left: 2px solid rgba(sh-color("orange"), 0.5);

          @include media-query("md") {
            content: "";
            bottom: 0;
            border-left: none;
            border-bottom: 2px solid rgba(sh-color("orange"), 0.5);
          }
        }
      }

      @include hover-active {
        @include sh-color("black-90");

        &::after {
          // mark current item on mobile menu
          border-left: none;

          @include media-query("md") {
            border-bottom: none;
          }
        }
      }

      &.is-active {
        @include sh-color("black-90");

        @include media-query("md") {
          &::after {
            // mark current item
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            border-bottom: 2px solid sh-color("orange");
          }
        }
      }
    }

    &__dropdown-panel {
      margin-top: 0;
    }

    &__dropdown-item {
      &.is-active {
        @include sh-background-color("black-7");
      }
      @include focus {
        @include sh-color("black");
        @include sh-background-color("black-10");
        outline: none;
        box-shadow: none;
      }

      @include hover-active {
        @include sh-background-color("black-7");
      }
    }

    @include media-query("md-down") {
      &.is-open {
        #{$_component} {
          &__container {
            position: fixed;
            @include sh-z-index("navigation");
            @include sh-background-color("black-7");
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

          &__panel {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            box-shadow: 0 4px 0.4rem rgba(27, 27, 27, 0.2);
            z-index: sh-z-index("navigation") + 1;
          }

          &__wrapper {
            margin: 0 auto;
            max-width: 100%;
            padding-right: 0.833rem;
            padding-left: 0.833rem;

            @include media-query("sm") {
              width: 30em;
            }

            @include media-query("md") {
              width: 40em;
            }

            @include media-query("lg") {
              width: 53.333em;
            }

            @include media-query("xl") {
              width: 80em;
            }
          }

          &__toggle-button {
            ::before {
              content: "\e817";
            }
          }

          &__list {
            position: absolute;
            top: 44px;
            left: 0;
            width: 100%;
            bottom: 0;
            display: block;
            padding-top: to-rem(15px);
          }

          &__item {
            display: block;
            margin: 0 auto;
            max-width: 100%;
            padding-right: sh-grid("gutter-width") * 0.5;
            padding-left: sh-grid("gutter-width") * 0.5 + map-get($_config, "toggle-button-width") -
              map-get($_config, "x-spacer");

            @include media-query("sm") {
              width: 30em;
            }

            @include media-query("md") {
              width: 40em;
            }

            @include media-query("lg") {
              width: 53.333em;
            }

            @include media-query("xl") {
              width: 80em;
            }
          }

          &__link {
            @include sh-font-size("small");
            &.is-active {
              // @include sh-color("black-7");
              border: none;
            }
          }

          &__additional-functions {
            display: none;
          }
        }
      }
    }

    &--dark {
      @include sh-background-color("black-90");

      #{$_component} {
        &__panel {
          @include sh-background-color("black-90");
        }

        &__list {
          @include sh-background-color("black-90");
        }

        &__link {
          @include sh-color("black-40");
          @include hover-active {
            @include sh-color("black-10");
          }
          &.is-active {
            @include sh-color("black-10");
          }
        }

        &__toggle-button {
          @include sh-color("black-40");
          &:hover {
            @include sh-color("black-20");
          }
        }

        &__app-identifier {
          @include sh-color("black-40");
        }
      }
    }

    @content;
  }
}

// fundamental icon reset

@mixin sh-icon-reset($font-family: sh-font-family('icon')) {
  font-family: $font-family;
  font-weight: normal !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  vertical-align: baseline;
  @content;
}

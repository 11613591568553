///
/// @access public
///
///
@mixin sh-u-positionings($options: ()) {
  $_defaults: (
    directions: (
      "top",
      "right",
      "bottom",
      "left",
    ),
    max-items: 5,
    positioning: (
      "static",
      "relative",
      "absolute",
      "fixed",
      "sticky",
    ),
    media-queries: (),
  );

  $_config: map-merge($_defaults, $options);

  $_directions: map-get($_config, "directions");
  $_positionings: map-get($_config, "positioning");
  $_max-items: map-get($_config, "max-items");
  $_media-queries: map-get($_config, "media-queries");

  @each $_position in $_positionings {
    .position-#{$_position} {
      position: #{$_position} !important;
    }
  }

  @each $_direction in $_directions {
    @include _util-layout-direction-helper($_direction, $_max-items);
  }

  @each $_media-query in $_media-queries {
    @include media-query($_media-query) {
      @each $_direction in $_directions {
        @include _util-layout-direction-helper($_direction, $_max-items, $_media-query);
      }
    }
  }
}

@mixin _util-layout-direction-helper($_direction, $_max-items, $_media-query: false) {
  @if ($_media-query != "xs") {
    $_prefix: "";

    @if ($_media-query != false) {
      $_prefix: "-#{$_media-query}";
    }

    @for $i from 0 to $_max-items {
      .#{$_direction}#{$_prefix}-#{$i} {
        position: relative;
        #{$_direction}: $i * 1.75rem !important;
      }
    }
  }
}

img {
  // By default, `<img>`s are `inline-block`. This assumes that, and vertically
  // centers them. This won"t apply should you reset them to `block` level.
  vertical-align: middle;
  // Note: `<img>`s are deliberately not made responsive by default.
  // For the rationale behind this, see the comments on the `.img-fluid` class.
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
  display: inline-block;
  user-select: none;
  // For Lazy Loading
  opacity: 1;
  transition: opacity 0.3s;
}

img[data-src] {
  opacity: 0;
}

/// Indicator is a round state indicator with an icon to put on form fields
/// @access public
/// @author Michael Schultze (ms@click-solutions.de)
/// @param {string} "icon" - Icon 
/// @param {string} "bgColor" - Fill with this color
///
@mixin sh-indicator($options: ()) {
  
  $_default: (
    "icon": "warning",
    "bgColor": "black-20"
  );

  $_config: map-merge($_default, $options);
  $_icon: map-get($_config, "icon");
  $_bgColor: map-get($_config, "bgColor");

  @include sh-icon-reset();
  @include sh-icon-char($_icon);
  @include sh-background-color($_bgColor);
  @if luminance(sh-color($_bgColor)) > 0.3 { // ensure 3:1 contrast ratio
    @include sh-color("black");
  } @else {
    @include sh-color("white");
  }
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  width: to-rem(30);
  height: to-rem(30);
  line-height: 1.7;
  border-radius: 50%;
  @content
}

@mixin ln-file-list($options: ()) {
  $_defaults: (
  );

  $config: map-merge($_defaults, $options);

  .file-list {

    margin-bottom: 1rem;

    &__item{
      padding: 0.7rem 0;
    }

    &__file {

      display: flex;
      justify-content: space-between;

      &-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 25em;
        min-width: 0;
        @include media-query('md-down') {
          max-width: 27em;
        }
        @include media-query('sm-down') {
          max-width: 13em;
        }
      }

      &-size {
        flex: none;
      }
    }
  }
}

@mixin ln-nested-selectbox($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .nested-selectbox {
    &.is-active {
      .multi-selectbox__label {
        display: block;
      }
    }

    &__option {
      padding: 0.666rem 0.8885rem;

      & span {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-no-padding {
        padding: 0;

        & span {
          width: calc(100% - 25px);
        }
      }

      &-selected, &-selected:hover {
        background-color: #e5e5e5;
      }

      &-only-expandable {
        & span {
          width: calc(100% - 25px);
        }
      }

      &-only-expandable &__arrow {
        right: 0.8885rem;
        position: absolute;
      }

      &-only-expandable:hover &__arrow {
        @include sh-color('orange');
      }

      &__selectable-area {
        padding: 0.666rem 0 0.666rem 0.8885rem;
      }

      &__italic-font-text {
        font-style: italic;
      }

      &__expandable-area {
        padding: 0.666rem 0.8885rem 0.666rem 10px;

        &:hover {
          @include sh-color('orange');
        }
      }

      &-search {
        &:hover {
          background-color: inherit;
        }

        & .search-field {
          margin: 0;
          background-color: inherit;
          border-bottom: none;
        }
      }
    }

    &__children-margin {
      margin-left: 1.1rem;
    }
  }
}

@mixin ln-modal-dialog($options: ()) {
  $_default: ();
  $config: map-merge($_default, $options);

  .md-container {
    display: none;
  }

  .#{$prefix}modal-dialog {
    .checkbox, .radio {
      display: none;
      position: initial;
    }
    &__container {
      overflow: unset !important;
    }
    &.modal-dialog--small {
      .#{$prefix}modal-dialog__container {
        @include media-query('md') {
          display: block;
          width: 400px;
          max-width: 100%;
        }
      }
    }
    
    &.modal-dialog--wide {
      .#{$prefix}modal-dialog__container {
        @include media-query('lg') {
          width: 62em;
        }
      }
    }
  }

  .modal-dialog__footer .button {
    margin-bottom: 0;
  }
  
  .reduce-max-body-height {
    max-height: calc(95vh - 9rem);
  }

  .make-full-height {
    min-height: 95%;
    height: 95%;
  }
}

@mixin ln-service-metrics($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .service-metric-details-drill-down-chart {
    .ngx-charts {
      .y {
        text {
          cursor: pointer;
        }
      }
    }
  }
}

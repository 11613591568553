@mixin ln-external-links($options: ()) {
  
  $_defaults: (
    font-name: "SH Icon",
    border-color: sh-color('black-30'),
    color: sh-color('orange'),
    content: '\e81d',
    distance_x: 0.35rem
  );
  $config: map-merge($_defaults, $options);

  .external-link {
    &:before {
      font-family: map-get($config, 'font-name') !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      font-size: 0.8em;
      text-transform: none !important;
      speak: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      vertical-align: baseline;
      content: map-get($config, 'content');
      margin-right: map-get($config, 'distance_x')
    }
  }
}

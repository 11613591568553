/// Component: Social List
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Social List
/// sh-social-list($options: (
///
/// ));
///
@mixin sh-social-list($options: ()) {
  $_defaults: ();
  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}social-list";

  $_defaults: (
    x-spacing: to-rem(10),
    y-spacing: to-rem(20)
  );

  $_config: map-merge($_defaults, $options);

  $social-icon-size: 1.5em;

  #{$_component} {
    align-items: baseline;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: $social-icon-size;
    list-style-type: none;
    margin: 0 map-get($_config, 'x-spacing')*-1 map-get($_config, 'y-spacing')*-1 0;
    padding-left: 0;
    font-size: $social-icon-size;

    &__item {
      flex: 0 0 auto;
      margin: 0 map-get($_config, 'x-spacing') map-get($_config, 'y-spacing') 0;
    }

    @content;
  }
}

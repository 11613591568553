/// Component: Button
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Button
/// sh-textfield($options: (
///
/// ));
///

@mixin sh-button($options: ()) {
  $_defaults: (
    "font-size": 1rem,
    "padding": to-em(10px) to-em(20px) to-em(10px),
  );

  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}button";

  $_font-size: map-get($_config, "font-size");
  $_padding: map-get($_config, "padding");
  $_transition: sh-transition("fade");
  $_font-size: map-get($_config, "font-size");
  $_padding: map-get($_config, "padding");

  #{$_component} {
    @include sh-background-color("white");
    @include sh-color("black-90");
    border-color: sh-color("black-30");
    border-radius: 100px;
    border-style: solid;
    border-width: 1px;
    display: inline-block;
    font-family: inherit;
    font-size: $_font-size;
    font-weight: sh-font-weight("semibold");
    line-height: 1;
    margin: 0;
    min-width: to-em(112px);
    padding: $_padding;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: background-color map-get(sh-transition("fade"), "duration")
      map-get(sh-transition("fade"), "easing");
    -webkit-appearance: none;

    // States
    @include sh-state("disabled");
    @include sh-state(
      "hover",
      (
        "bgColor": "white",
      )
    ) {
      color: sh-color("black-90");
    }
    @include sh-state("focus");
    @include sh-state(
      "active",
      (
        "bgColor": "white",
      )
    ) {
      box-shadow: none;
    }

    &::before,
    i::before {
      @include sh-color("orange-dark");
      margin-right: 0.25rem;
      margin-left: -0.3em; // fine tune left padding
    }

    // Dark theme

    [class*="bg-color-gray-shade"] &,
    .bg-color-black &,
    :host([theme="dark"]) & {
      @include sh-color("black-10");
      @include sh-border-color("black-70");
      @include sh-background-color("black-90");

      // States
      @include sh-state("disabled");
      @include sh-state(
        "hover",
        (
          "bgColor": "black-90",
        )
      );
      @include sh-state("focus");
      @include sh-state(
        "active",
        (
          "bgColor": "black-90",
        )
      );

      &::before,
      i::before {
        @include sh-color("black-10");
      }
    }

    &--text {
      border: 0;
      min-width: 0; // setting to 'initial' applies wide buttons in IE11
      min-width: 0; // setting to "initial" applies wide buttons in IE11
      background: transparent;
      cursor: pointer;

      @include sh-hover(("bgColor": "black-5"));
      @include sh-focus();

      &::before,
      i::before {
        margin-right: 0.25rem;
        @include sh-color("orange-dark");
      }

      [class*="bg-color-gray-tint"] &,
      :host([theme="gray"]) & {
        @include sh-hover(("bgColor": "black-7"));
      }

      [class*="bg-color-gray-shade"] &,
      .bg-color-black &,
      :host([theme="dark"]) & {
        background: transparent;

        @include sh-hover(("bgColor": "black-90"));

        @include focus() {
          @include sh-color("black-10");

          &::before,
          i::before {
            @include sh-color("black-10");
          }
        }

        &:active {
          @include sh-hover(("bgColor": "black"));
        }
      }
    }

    //
    //
    //
    &--primary {
      @include _button-primary();

      [class*="bg-color-gray-shade"] &,
      .bg-color-black &,
      :host([theme="dark"]) & {
        @include _button-primary();
      }

      &::before,
      i::before {
        @include sh-color("white");
      }
    }

    //
    //
    //
    &--control {
      @include sh-color("orange");
      min-width: inherit;
      padding: 0;
      width: to-rem(38);
      height: to-rem(38);
      overflow: hidden;
      font-size: 0; // hide caption text
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      i {
        width: 100%;
      }

      &::before,
      i::before {
        margin-right: 0;
        margin-left: 0;
        font-size: $_font-size;
      }

      @include hover-focus() {
        @include sh-color("orange");
      }

      &.button--small {
        padding: 0;
        width: to-rem(32);
        height: to-rem(32);
        font-size: 0;

        &::before,
        & i::before {
          @include sh-font-size("xsmall");
        }
      }
    }

    &--small {
      @include sh-font-size("xsmall");
      padding: to-rem(7px) to-rem(12px);
      min-width: inherit;
    }

    @content;
  }
}

@mixin _button-primary() {
  @include sh-color("white");
  @include sh-background-color("orange-dark");
  @include sh-border-color("orange-dark");

  @include sh-state(
    "hover",
    (
      "bgColor": "orange-dark",
      "borderColor": "orange-dark",
    )
  ) {
    @include sh-color("white");
  }
  @include sh-state("focus") {
    @include sh-color("white");
  }
  @include sh-state(
    "active",
    (
      "bgColor": "orange-dark",
      "borderColor": "orange-dark",
    )
  ) {
    @include sh-color("black-10");
  }
}

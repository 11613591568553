@mixin ln-search-multi-selectbox($options: ()) {
  .search-multi-selectbox {

    &__option-list {
      top: 55px;
    }

    &__text-field > div.textfield--with-prefix {
      width: 100%;
    }

    &__text-field-mobile {
      @include media-query('md-down') {
        width: 100%;
      }
    }

    &__mobile-view-backround {
      @include media-query('md-down') {
        background-color: gray;
      }
    }

    &__mobile-view {
      @include media-query('md-down') {
        display: flex;
        z-index: sh-z-index('dropdown') + 1;
        position: absolute;
        margin-top: 5.5rem;
        width: 100%;
      }
    }
  }
}

@mixin ln-toggle-button($options: ()) {
    
    
    $_default: (

    );

    $config: map-merge($_default, $options);

    $_transition: sh-transition('fade');
    $_tag-transition: all map-get($_transition, 'duration') map-get($_transition, 'easing');
    
    .toggle-button {
        
        border: 1px solid;
        border-radius: 3px;
        @include _tag-color__toggle(
          sh-color('black-80'),
          sh-color('white'),
          sh-color('black-30'),
          sh-color('black-80'),
          sh-color('black-7'),
          sh-color('black-30'),
          sh-color('black-80'),
          sh-color('white'),
          sh-color('black-30'),
          sh-color('black-80'),
          sh-color('black-10'),
          sh-color('black-30')
        );

        cursor: pointer;
        display: inline-block;
        font-family: inherit;
        line-height: 1;
        margin: 0 0 1.25em;
        @include sh-font-size('xsmall');
        min-width: auto;
        padding: to-rem(7) to-rem(8) to-rem(7) to-rem(8);
        position: relative;
        text-decoration: none;
        text-align: center;
        transition: $_tag-transition;
        -webkit-appearance: none;
    
        &::-moz-focus-inner {
            border: 0;
            padding: 0;
        }

        & i {
            &:before {
                @include sh-color('black-60');
            }
        }
    

        &.is-active {
            @include _tag-color__toggle(
              sh-color('black-80'),
              sh-color('black-10'),
              sh-color('black-30'),
              sh-color('black-80'),
              sh-color('black-20'),
              sh-color('black-30'),
              sh-color('black-80'),
              sh-color('black-10'),
              sh-color('black-30'),
              sh-color('black-80'),
              sh-color('black-30'),
              sh-color('black-30')
            );
        }

      &__icon-size {
        font-size: 25px;
      }
      
    }
}

@mixin _tag-color__toggle(
  $color, 
  $background, 
  $border, 
  $hover-color, 
  $hover-bg, 
  $hover-border,
  $focus-color, 
  $focus-bg, 
  $focus-border,
  $active-color, 
  $active-bg, 
  $active-border) {
    
    color: $color;
    background-color: $background;
    border-color: $border;

    @include hover() {
      color: $hover-color;
      background-color: $hover-bg;
      border-color: $hover-border;
      outline: none;
    }

    @include focus() {
      color: $focus-color;
      background-color: $focus-bg;
      border-color: $focus-border;
      outline: none;
      box-shadow: inset 0 0 0 1px sh-color('orange-50');
    }

    @include active() {
      color: $active-color;
      background-color: $active-bg;
      border-color: $active-border;
      outline: none;
    }
}

@mixin active {
  &:active {
    @content;
  }
}

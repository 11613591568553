/// Media Object pattern for displaying an item, usually an image, alongside some content, usually text.
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @link http://philipwalton.github.io/solved-by-flexbox/demos/media-object/
/// @example scss - Media Object
/// sh-media-object($options: (
///   gutter-width: 1rem
/// ));
@mixin sh-media-object($options: ()) {
  $_defaults: (
    gutter-width: sh-grid("gutter-width"),
  );
  $_config: map-merge($_defaults, $options);

  .#{$prefix}media-object {
    display: flex;
    align-items: flex-start;
    width: 100%;

    &__image {
      margin-right: to-rem(20);
      flex: 0 9 auto;
      display: flex; // to support nesting an aspect-ratio component
    }

    &__body {
      flex: 1 1 6em;
      margin-top: to-rem(-5);
      @include respect-inner-padding();
    }

    &--right {
      .#{$prefix}media-object__image {
        margin-right: 0;
        margin-left: to-rem(20);
        order: 2;
      }
    }

    &--stacked-xs {
      @include media-query("sm-down") {
        @include _media-object-stack;
      }
    }

    &--stacked-sm {
      @include media-query("md-down") {
        @include _media-object-stack;
      }
    }

    &--stacked-md {
      @include media-query("lg-down") {
        @include _media-object-stack;
      }
    }

    &--small-spacing &__image {
      margin-right: to-rem(10);
    }
    $media-block: &; // save parent selector into a variable to use it inline the next selector
      &--small-spacing#{$media-block}--right &__image {
      margin-left: to-rem(10);
    }
    
    @content;
  }
}

//
//
//
@mixin _media-object-stack {
  flex-direction: column;

  .media-object__image {
    // width: 100%; // width should be controlled by the image
    margin-right: auto;
    margin-bottom: 0.8125rem;
    margin-left: auto;
    order: 0;
  }

  .media-object__body {
    flex-basis: auto;
  }
}

/// Component: Back-To-Top
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Back-To-Top
/// sh-accordion($options: (
///
/// ));
///
@mixin sh-back-to-top($options: ()) {
  $_default: ();
  $_config: map-merge($_default, $options);
  $_component: ".#{$prefix}back-to-top";

  #{$_component} {
    @include sh-z-index("navigation");
    @include sh-shadow("modal");
    opacity: 0;
    pointer-events: none;
    position: fixed;
    right: 1rem;
    bottom: 1rem;

    &.is-visible {
      opacity: 1;
      pointer-events: inherit;
    }

    &:hover {
      @include sh-shadow("modal");
    }

    &:active {
      box-shadow: none;
    }
    @content;
  }
}

@mixin ln-alert($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .alert__text {
    p {
      margin-bottom: 0;
    }
    word-break: normal;
    a {
      i {
        margin-left: 5px;
      }
    }
  }
  
  .alert__heading {
    word-break: normal;
  }

  .alert__margin {
    margin-bottom: 28px;
  }
  
  .alert__body {
    @extend .is-open;
  }

  .alert {
    @extend .is-open;

    .bg-color-black-7 & {
      @include sh-background-color('black-20');
    }
  }

  .alert__body > *:last-child {
    margin-bottom: 0;
    margin-top: auto;
  }

}

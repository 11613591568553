@mixin ln-equipment-documents($options: ()) {
  $_defaults: ();
  $config: map-merge($_defaults, $options);

  .equipment-documents-cards {
    & .card {
      cursor: pointer;
    }
  }
  
  .equipment-documents-collapsible-item-id-icon {
    vertical-align: text-bottom;
    line-height: 16px;
  }

  .equipment-documents-collapsible-item-button::before {
    top: 35%;
  }
  
  .equipment-documents-node-detail-bottom-line {
    margin: 0 0 -1px;
    border-bottom: 1px solid #e0e0e0;
  }
}

/// Component: Figure
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Figure
/// sh-textfield($options: (
///
/// ));
///
@mixin sh-figure($options: ()) {

  $_defaults: ();

  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}figure";

  #{$_component} {
    
    &__image-container {
      display: block;
    }

    // &__image {}
    // &__caption {}

    @content;
  }
}

@mixin ln-tabs($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .tabs {
    &--my-institutions {
      padding-left: 0.833rem;
      padding-right: 0.833rem;
    }

    &__list {
      margin-left: 0;
    }

    &__item {
      padding: .666rem;
    }

    &__subtab-padding {
      padding: .666rem;
    }

    &__navigation-menu {
      display: flex;
      position: relative;
      white-space: nowrap;
      margin-left: 0;
    }
    
    &__mobile-navigation-menu {
      display: grid;
      padding-left: 1.58rem;
    }

    .hide-subtab-content {
      display: none;
    }
  }
}

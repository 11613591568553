/// Component: List
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - List
/// sh-textfield($options: (
///
/// ));
///
@mixin sh-list($options: ()) {
  $_defaults: (
    "bullet-size": 6px,
    "indent": 16px,
    "icon-indent": 22px,
    "bottom-margin": 1.666em,
    "leading": 0.611rem,
  );
  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}list";

  #{$_component} {
    margin-left: 0.888rem;
    padding-left: 0;
    // @include sh-color("black-90"); // inherit color

    &--inline {
      @include _inline-list($_component, $_config);
    }

    // list with colored bullet points
    &--bullet {
      @include _custom-bullets-list($_component, $_config);
    }

    // list with custom numbered list style
    &--numbered {
      @include _custom-numbered-list($_component, $_config);
    }

    // vertical block list useful for items in dropdowns
    &--block {
      @include _block-list($_component, $_config);
    }

    // list with iconized links
    &--link {
      @include _link-list($_component, $_config);
    }

    @content;
  }
}

@mixin _inline-list($_component, $options) {
  margin-left: 0;

  #{$_component}__item {
    display: inline-block;
  }
}

@mixin _block-list($_component, $options) {
  list-style: none;
  margin: 0;

  #{$_component}__item {
    margin: 0;
    position: relative;
  }

  #{$_component}__link {
    display: block;
    margin: 0;
    padding: map-get($options, "leading") * 0.5 0;
  }
}

@mixin _link-list($_component, $options) {
  padding-left: 0;
  list-style-type: none;
  margin-left: 0;

  #{$_component}__item {
    list-style: none;
    margin-left: 0;

    &::before {
      display: none;
    }
  }

  #{$_component}__item--no-link {
    &::before {
      display: block;
      left: 0.5rem;
    }
  }

  #{$_component}__link {
    @include sh-color("black-60"); // anchor styles should be applied
    display: inline-block;
    padding: 0 0 0 to-rem(30);

    &::before {
      position: absolute;
      left: 0;
      top: -0.25em;
      padding: 0.25em 0;
      line-height: inherit; // ensures baseline-alignment for absolute positioned icon
    }

    @include hover-active() {
      @include sh-color("black-90"); // anchor styles should be applied
      background: transparent;
    }
  }
}

@mixin _custom-bullets-list($_component, $options) {
  list-style-type: none;
  padding-left: map-get($options, "indent");
  margin-left: 0;

  & > li,
  #{$_component}__item {
    list-style: none;

    &:before {
      content: " ";
      display: block;
      width: map-get($options, "bullet-size");
      height: map-get($options, "bullet-size");
      border-radius: 50%;
      @include sh-background-color("black-90");
      position: absolute;
      left: -(map-get($options, "indent") * 0.9); // 1px bullet indent
      top: to-em(12);
    }
  }
}

@mixin _custom-numbered-list($_component, $options) {
  padding-left: to-rem(30);
  margin-left: 0;
  counter-reset: item;
  list-style-type: none;

  & > li,
  #{$_component}__item {
    &::before {
      @include sh-font-size("xsmall");
      @include sh-font-weight("normal");
      content: counter(item);
      counter-increment: item;
      display: inline;
      position: absolute;
      left: to-rem(-30);
      top: to-em(1); // vertical alignment for Webkit
    }

    @for $i from 1 through 9 {
      &:nth-child(#{$i}) {
        &::before {
          content: "0#{$i}";
        }
      }
    }
  }
}

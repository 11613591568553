@use 'sass:math';

/* 
 Component: Accordion
 @access public
 @author Robert John (rj@click-solutions.de)
 @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
 @example scss - Accordion Reverse
 ln-accordion-reverse($options: (
   
 ));
*/
@mixin ln-accordion-reverse($options: ()) {
  $_defaults: (
    padding: math.div(sh-grid('gutter-width'), 2),
    active-bg-color: sh-color('black-5'),
    active-color: sh-color('orange')
  );

  $_config: map-merge($_defaults, $options);

  $_padding: map-get($_config, 'padding');
  $_active-bg-color: map-get($_config, 'active-bg-color');
  $_active-color: map-get($_config, 'active-color');

  .accordion {
    &.overview-list__items {
      border-right: 1px solid sh-color('black-7');
    }

    &--word-break-all {
      .accordion__panel {
        word-break: break-all;
      }
    }

    &--small-top-margin {
      margin-top: -30px;
    }
  }

  .accordion__title {
    &__content {
      @include respect-inner-padding;
      flex: 1 1;
    }

    &__additional-content {
      flex: none;
      padding-left: 1rem;
      display: flex;
      align-items: center;

      .button--control {
        margin-left: math.div($_padding, 2);

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .label {
      margin-left: .4rem;
    }
  }

  .#{$prefix}accordion__reverse {
    .accordion__title {
      padding: $_padding $_padding $_padding $_padding*3;

      &:before {
        right: auto;
        left: $_padding;
      }
    }

    .accordion__panel {
      padding-left: $_padding*3;
    }
  }
}

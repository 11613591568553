/// Component: Label
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Label
/// sh-label($options: (
///
/// ));
///
@mixin sh-label($options: ()) {
  $_default: ();
  $_config: map-merge($_default, $options);
  $_component: ".#{$prefix}label";

  #{$_component} {
    @include sh-background-color("black-7");
    @include sh-color("black-90");
    @include sh-font-family("text");
    @include sh-font-weight("semibold");
    @include sh-font-size("xxsmall");
    border-radius: 3px;
    display: inline-block;
    line-height: 1;
    margin: 0;
    padding: to-rem(4) to-rem(6);
    vertical-align: text-bottom;
    white-space: nowrap;

    &--attached {
      margin-left: sh-grid("gutter-width") * 0.25;
      margin-right: sh-grid("gutter-width") * 0.25;
    }

    &--superscript {
      font-size: to-rem(10);
      min-width: 1.33em;
      padding: 0.2rem;
      text-align: center;
      transform: translateY(-50%);
    }

    // Gray theme

    [class*="bg-color-gray-tint"] &,
    :host([theme="gray"]) & {
      @include sh-background-color("white");
    }

    // Dark theme

    [class*="bg-color-gray-shade"] &,
    .bg-color-black &,
    :host([theme="dark"]) & {
      @include sh-background-color("black-80");
      @include sh-color("white");
    }
    
    @content;
  }

}

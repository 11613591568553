@mixin ln-progress($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .progress {
    [aria-valuenow = '100'] {
      background-color: sh-color('green');
    }
    
    [aria-invalid = 'true'] {
      background-color: sh-color('red');
    }
    
    margin-bottom: 20px;
    &--center {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

@mixin ln-spr-guardian-overview($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);


  .custom-flex-18 {
    flex: 0 0 18%;
    max-width: 18%;
  }

  .custom-flex-57 {
    flex: 0 0 57%;
    max-width: 57%;
  }

  .custom-flex-30 {
    flex: 0 0 30%;
    max-width: 30%;
  }
}

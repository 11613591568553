@mixin ln-accordion($options: ()) {
  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .accordion {
    & + & {
      border-top: none;
    }

    .accordion__title {
      overflow: visible;
      cursor: pointer;
      padding-right: 2.5rem;
      font-size-adjust: none;
    }

    .accordion__title:hover {
      .accordion--header-button {
        color: sh-color('black');
      }
    }

    .accordion__title--no-action {
      cursor: default;

      &:hover {
        color: initial;
      }
    }

    .label {
      margin-left: .4rem;
    }
  }
}

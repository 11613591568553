@mixin ln-detail-information-panel($options: ()) {
    
    $_defaults: ();
    $_config: map-merge($_defaults, $options);

    .detail-information-panel {

        &__action {
            margin: 0;
        }
    }
}

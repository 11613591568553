/// Component: Meta Menu
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Meta Menu
/// sh-textfield($options: (
///
/// ));
///
@mixin sh-meta-menu($options: ()) {
  $_defaults: ();
  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}meta-menu";

  #{$_component} {
    display: flex;
    align-items: center;
    margin: 0 0 0 0.55555rem;

    &__list {
      overflow: hidden;
      white-space: nowrap;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-end;
    }

    &__item {
      flex: none;
      margin: 3px; // do not hide focus rings of the items
      // margin-right: 0.75rem; save some space
      white-space: nowrap;
    }
    &__link {
            @include sh-color("black-60");
            @include sh-font-size("small");
            @include sh-font-family("text");
            display: flex;
            align-items: center;
            padding: 0 0.55555rem;
            padding: to-rem(5) to-rem(7) to-rem(4);
            transition: color map-get(sh-transition('fade'), 'duration') map-get(sh-transition('fade'), 'easing');

      i::before {
                @include sh-color("black-60");
                font-size: 1.6em;
                position: relative;
                top: 2px;
                margin: 0;
                transition: color map-get(sh-transition('fade'), 'duration') map-get(sh-transition('fade'), 'easing');
      }
    
      @include sh-focus();

      &:hover {
        @include sh-color("black-90");

        i::before {
          @include sh-color("black-90");
        }
      }
    }

    &__title {
      margin-left: 0.25rem;
      display: none;

      @include media-query("lg") {
        display: inline-block;
      }

      .meta-menu__hidden-list & {
        display: inline-block;
      }
    }

    &__toggle-button {
      flex: none;
      margin: 0;
      display: none;

      &.is-visible {
        display: block;
      }
    }
    
    @content;
  }

}

@mixin ln-toast-message($options: ()) {
  $_defaults: (
    duration: 300ms
  );
  $config: map-merge($_defaults, $options);
  $duration: map-get($map: $config, $key: 'duration');

  .toast-message {
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5000;
    animation-duration: $duration;
    animation-name: slidein;
    &__close {
      position: absolute;
      right: 0.6rem;
      top: 0.3rem;
      font-size: 1.222rem;
    }
  }
  
  @keyframes slidein {
    from {
      transform: translate3d(0, -100%, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }
}
  

///
/// @access public
///
///
@mixin sh-u-background-colors($options: ()) {

  $_defaults: (
    "colors": sh-color-list()
  );

  $_config: map-merge($_defaults, $options);
  
  $_colors: map-get($_config, "colors");
  $_deprecated-colors: (
    "gray-shade-4":   "black-90",
    "gray-shade-3":   "black-80",
    "gray-shade-2":   "black-75",
    "gray-shade-1":   "black-70",
    "gray":           "black-60",
    "gray-tint-1":    "black-40",
    "black-30":    "black-30",
    "gray-tint-3":    "black-20",
    "gray-tint-4":    "black-10",
    "gray-tint-5":    "black-7",
    "gray-tint-6":    "black-5",
    "orange-shade-1": "orange-dark",
    "orange-tint-2":  "orange-50",
    "orange-tint-3":  "orange-25",
    "petrol-shade-1": "petrol-dark",
    "petrol-tint-2":  "petrol-50",
    "petrol-tint-3":  "petrol-25",
    "red-shade-1":    "red-dark",
    "red-tint-2":     "red-50",
    "red-tint-3":     "red-25",
    "green-shade-1":  "green-dark",
    "green-tint-2":   "green-50",
    "green-tint-3":   "green-25",
    "yellow-tint-2":  "yellow-50",
    "yellow-tint-3":  "yellow-25",
    "berry-tint-2":   "berry-50",
    "berry-tint-3":   "berry-25",
    "blue-tint-2":    "blue-50",
    "blue-tint-3":    "blue-25",
    "cyan-tint-2":    "cyan-50",
    "cyan-tint-3":    "cyan-25",
  );
  
  @each $_name in $_colors {
    .bg-color-#{$_name} {
      background-color: sh-color($_name) !important;
      @if luminance(sh-color($_name)) < 0.2 { // orange-dark is luminance 0.184 | black-50 is luminance 0.2159
        color: sh-color("black-10") !important;
      }
    }
  }

  @each $_name, $_value in $_deprecated-colors {
    .bg-color-#{$_name} {
      background-color: sh-color($_value) !important;
      @if luminance(sh-color($_value)) < 0.2 { // orange-dark is luminance 0.184 | black-50 is luminance 0.2159
        color: sh-color("black-10") !important;
      }
    }
  }
}

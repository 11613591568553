@mixin sh-diagram($options: ()) {
  $_defaults: (
  );

  $config: map-merge($_defaults, $options);

  .#{$prefix}diagram {

    display: flex;
    flex-direction: column;

    @include media-query('md') {
      flex-direction: row;
    }

    &--half {
      width: 50%
    }

    &--no-legend {
      @include media-query('md') {
        flex-direction: column;
      }
    }

    &--default-cursor {
      & .arc {
        cursor: default !important;
      }
    }

    &__canvas {
      position: relative;
      @include sh-background-color('white');
      @include media-query('md') {
        width: 50%;
      }
      @include media-query('xl') {
        width: 100%;
      }

      &--full {
        @include media-query('md') {
          width: 100%;
          transform: translate(0%, 0%);
        }
        @include media-query('xl') {
          width: 100%;
          transform: translate(0%, 0%);
        }

        .min-height {
          min-height: 250px;
        }
      }

      &--full-empty {
        @include media-query('md') {
          width: 25%;
          transform: translate(150%, 0%);
        }
        @include media-query('xl') {
          transform: translate(150%, 50%);
        }
      }

      &--empty {
        @include media-query('md') {
          width: 50%;
          transform: translate(50%, 0%);
        }
        @include media-query('xl') {
          width: 100%;
          transform: translate(0%, 0%);
        }
      }

      &--no-legend {
        @include media-query('md') {
          width: 100%;
        }
      }
    }

    &__total-amount {
      position: absolute;
      top: 52%;
      left: 50%;
      transform: translate(-50%, -41%);
      text-align: center;
      @include sh-font-size('xsmall');

      &--half {
        top: 0;
        left: 0;
        transform: translate(0%, 0%);
        width: 100%;
        height: 100%;
        @include media-query('md') {
          transform: translate(0%, 25%);
        }
      }
    }

    &__spr-widget {
      position: relative;
      display: flex;
      flex-direction: column;
    }

    &__spr-widget_half_size {
      position: relative;
      display: flex;
      flex-direction: column;
      max-width: 50%;
    }

    &__value {
      word-break: break-word;
      @extend .clickable;
    }

    &__cursor_auto {
      cursor: auto!important;
    }

    &__text_long_size {
      height: 60px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      @extend .clickable;
      word-break: break-all;

      &--dont-break-words {
        word-break: keep-all !important;
      }
    }

    &__double-number-spacing {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      text-align: center;
      height: 100%;
    }

    &__total-number {
      /* line-height: 1; */
      font-size: 2.889rem;
      @include media-query('sm') {
        font-size: 2rem;
      }
      @include media-query('md') {
        font-size: 2.5rem;
      }
      @include media-query('lg') {
        font-size: 3rem;
      }
      @include media-query('xl') {
        font-size: 4rem;
      }

      &-100 {
        font-size: 2rem;
        @include media-query('sm') {
          font-size: 1.889rem;
        }
        @include media-query('md') {
          font-size: 2rem;
        }
        @include media-query('lg') {
          font-size: 2.5rem;
        }
        @include media-query('xl') {
          font-size: 2.889rem;
        }
      }

      &-1000 {
        font-size: 1.5rem;
        @include media-query('sm') {
          font-size: 1.177rem;
        }
        @include media-query('md') {
          font-size: 1.5rem;
        }
        @include media-query('lg') {
          font-size: 1.8rem;
        }
        @include media-query('xl') {
          font-size: 2rem;
        }
      }

      &-10000 {
        font-size: 1.117rem;
        @include media-query('sm') {
          font-size: 0.889rem;
        }
        @include media-query('md') {
          font-size: 1.117rem;
        }
        @include media-query('lg') {
          font-size: 1.566rem;
        }
        @include media-query('xl') {
          font-size: 1.700rem;
        }
      }
    }

    &__text-size {
      font-size: calc(0.1rem + 8vw);
      @include media-query('sm') {
        font-size: calc(0.1rem + 8vw);
      }
      @include media-query('md') {
        font-size: 1.2rem;
      }
      @include media-query('lg') {
        font-size: 1.4rem;
      }
      @include media-query('xl') {
        font-size: calc(0.1rem + 1.4vw);
      }
    }

    &__widget-value-size {
      font-size: calc(0.1rem + 4vw);
      @include media-query('sm') {
        font-size: calc(0.1rem + 4vw);
      }
      @include media-query('md') {
        font-size: 0.8rem;
      }
      @include media-query('lg') {
        font-size: 1.0rem;
      }
      @include media-query('xl') {
        font-size: calc(0.1rem + 1.2vw);
      }
    }

    &__widget-font-size {
      font-size: 21px;
    }

    &__icon-size {
      font-size: 5rem;
    }

    &__legend {

      @include media-query('md') {
        width: 50%;
        align-self: center;
      }

      @include media-query('xl') {
        display: none;
      }

      &-item {
        border: none;
      }

      &-label {
        vertical-align: middle;
      }
    }

    .transparent-bar {
      .bar {
        opacity: 0;
      }
      .bar:hover {
        opacity: 0;
      }
      .bar.active {
        opacity: 0;
      }
    }
  }

  .diagram--double-axis {
    .diagram__canvas {
      height: 500px;
      width: 100%;

      @include media-query('md') {
        width: 100%;
      }
    }

    .vertical-diagram__canvas {
      position: absolute;
      height: 500px;
      width: 90%;
      padding-left: 2rem;

      @include media-query('md') {
        width: 90%;
      }

      @include media-query('sm-down') {
        width: 80%;
      }
    }

    .line-highlight {
      display: none;

      &.active {
        display: block;
      }
    }

    .gridline-path {
      stroke: #ddd;
      stroke-width: 1;
      fill: none;
    }

    .line-series,
    .line-series-range {
      &.inactive {
        transition: opacity 100ms ease-in-out;
        opacity: 0.2;
      }
    }
  }

  .diagram--combo {
    .diagram__canvas {
      height: 500px;
      width: 100%;

      @include media-query('md') {
        width: 100%;
      }
    }

    .gridline-path {
      stroke: #ddd;
      stroke-width: 1;
      fill: none;
    }

    .area {
      opacity: 0 !important;
    }
  }

  .clickable {
    cursor: pointer;
  }

  .diagram--timeline, .diagram--legend {
    .chart-legend {
      display: inline-block;
      padding: 0;
      width: 100% !important;

      .legend-title {
        white-space: nowrap;
        overflow: hidden;
        margin-left: 10px;
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: bold;
      }

      ul, li {
        padding: 0;
        margin: 0;
        list-style: none;
      }

      .horizontal-legend {
        li {
          display: inline-block;
        }
      }

      .legend-wrap {
        width: calc(100% - 10px);
      }

      .legend-labels {
        line-height: 85%;
        list-style: none;
        text-align: left;
        float: left;
        width: 100%;
        border-radius: 3px;
        overflow-y: auto;
        overflow-x: hidden;
        white-space: nowrap;
        background: rgba(0, 0, 0, 0.05);
      }

      .legend-label {
        cursor: pointer;
        font-size: 90%;
        margin: 8px;
        color: #AFB7C8;

        &:hover {
          color: #000;
          -webkit-transition: 0.2s;
          -moz-transition: 0.2s;
          transition: 0.2s;
        }

        .active {
          .legend-label-text {
            color: #000;
          }
        }
      }

      .legend-label-color {
        display: inline-block;
        height: 15px;
        width: 15px;
        margin-right: 5px;
        color: #5b646b;
        border-radius: 3px;
      }

      .legend-label-text {
        display: inline-block;
        vertical-align: top;
        line-height: 15px;
        font-size: 12px;
        width: calc(100% - 20px);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .legend-title-text {
        vertical-align: bottom;
        display: inline-block;
        line-height: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .diagram--timeline {
    height: 300px;

    .ngx-charts {
      float: left;
      overflow: visible;

      .circle,
      .bar,
      .arc {
        cursor: pointer;
      }

      .bar,
      .cell,
      .arc,
      .card {
        &.active,
        &:hover {
          opacity: 0.8;
          transition: opacity 100ms ease-in-out;
        }

        &:focus {
          outline: none;
        }

        &.hidden {
          display: none;
        }
      }

      g {
        &:focus {
          outline: none;
        }
      }

      .line-series,
      .line-series-range,
      .polar-series-path,
      .polar-series-area,
      .area-series {
        &.inactive {
          transition: opacity 100ms ease-in-out;
          opacity: .2;
        }
      }

      .line-highlight {
        display: none;

        &.active {
          display: block;
        }
      }

      .area {
        opacity: 0.6;
      }

      .circle {
        &:hover {
          cursor: pointer;
        }
      }

      .label {
        font-size: 12px;
        font-weight: normal;
      }

      .tooltip-anchor {
        fill: rgb(0, 0, 0);
      }

      .gridline-path {
        stroke: #ddd;
        stroke-width: 1;
        fill: none;
      }

      .refline-path {
        stroke: #a8b2c7;
        stroke-width: 1;
        stroke-dasharray: 5;
        stroke-dashoffset: 5;
      }

      .refline-label {
        font-size: 9px;
      }

      .reference-area {
        fill-opacity: 0.05;
        fill: #000;
      }

      .gridline-path-dotted {
        stroke: #ddd;
        stroke-width: 1;
        fill: none;
        stroke-dasharray: 1, 20;
        stroke-dashoffset: 3;
      }

      .grid-panel {
        rect {
          fill: none;
        }

        &.odd {
          rect {
            fill: rgba(0, 0, 0, 0.05);
          }
        }
      }
    }

    .chart-legend {
      width: auto !important;

      .legend-labels {
        background: none;
      }

      .legend-label-color {
        border-radius: 50%;
      }
    }
  }

  .spr-donut-diagram .diagram__total-number {
    font-size: 1.117rem;
    @include media-query('sm') {
      font-size: 0.889rem;
    }
    @include media-query('md') {
      font-size: 1.117rem;
    }
    @include media-query('lg') {
      font-size: 1.566rem;
    }
    @include media-query('xl') {
      font-size: 1.889rem;
    }
  }

  .diagram--digital_compass {

    @include media-query('md-down') {
      padding-bottom: 0.5em;
    }

    .legend-below .chart-legend {
        display: unset;
    }

    .chart-legend .legend-labels {
      background: none;
      font-size: 14px;
      font-weight: bold;
      display: flex;
      flex-wrap: wrap;
    }

    .chart-legend .legend-label {
      padding-bottom: 5px;
      cursor: default !important;
      @media only screen and (min-width: 70.8125em) { 
        width: 100%;
      }
      @media only screen and (min-width: 75em) {
        width: auto;
      }
      @media only screen and (min-width: 107.625em) { 
        width: 100%;
      }
    }

    .legend-label-text {
      color: #000;
      white-space: normal !important;
    }

    .legend-title-text {
      @include sh-color('black');
      white-space: normal !important;
      padding-right: 10px;
    }

    .ngx-charts .bar {
      cursor: default !important;
    }

    [ngx-charts-bar-label] .textDataLabel {
      font-size: 12px;
      font-weight: bold;
    }
  }
}

@mixin ln-digital-compass($options: ()) {
  $_defaults: ();
  $config: map-merge($_defaults, $options);

  .digital-compass {

    .card__body {
      margin: 0;
      padding: calc(2rem * 2/3);
    }

    &__card-slider {
      .card__body > h4 {
        word-break: break-word;
      }

      &__tag {
        padding: 5px 10px;
        border-radius: 5px;
        background-color: #b3b3b3;
        @include sh-color('white');

        @include media-query('sm-down') {
          font-size: 0.8rem;
        }
        
        &--in-image {
          position: absolute;
          margin-top: -3.4rem;
          margin-left: calc(2rem * 2/3);
          margin-right: calc(2rem * 2/3);
          @include sh-background-color('petrol');

          @include media-query('sm-down') {
            margin-top: -2.8rem;
            margin-left: calc(2rem * 1/3);
            margin-right: calc(2rem * 1/3);
          }
        }
      }

      .button {
        display: block;
      }

      .label {
        margin-left: 0;
        margin-bottom: 0.75rem;
        display: table;
        width: auto;

        &--black {
          background-color: sh-color('black-70');
          color: sh-color('white');
        }
      }

      &__body-text {
        word-break: break-word;
      }
      
      &__body-info-text {
        word-break: break-word;
        @include media-query('sm-down') {
          font-size: 0.6rem;
        }
        line-height: 1.4em;
        font-size: 0.7rem;
      }

      &__fakelink {
        @include link-color;
        @include hover-focus {
          &::before {
            border-color: sh-color('orange');
          }
        }     
        &::before {
          color: sh-color('orange');
        }
      }

      &__link {
        &::before {
          vertical-align: middle;
        }

        @include media-query('sm-down') {
          font-size: 0.8rem;
        }
      }
    }
    
    &__graph__logo__disclaimer {
      word-break: break-word;
      text-align: right;
      font-size: 0.7rem;
      padding-right: 15%;
      line-height: 1rem;
      @include media-query('sm-down') {
        font-size: 0.6rem;
        padding-left: 10%;
        padding-right: 10%;
      }
    }

    &__option-details {
      padding: 0 2rem 1rem !important;
      bottom: 150px !important;

      @include media-query('sm') {
        bottom: 60px !important;
      }

      .label {
        &--black {
          background-color: sh-color('black-70');
          color: sh-color('white');
        }
      }

      &__list-row {
        margin-bottom: 1rem;
        font-size: 1.2rem;

        & div:first-child {
          width: 2rem;
          text-align: left;
          float: left;
          font-weight: bold;
          @include sh-color('petrol');

          & > i {
            vertical-align: middle;
          }
        }

        & div:last-child {
          margin-left: 2rem;
        }
      }

      &__author-section {
        margin: 1rem -2em 0;
        padding: 1rem 2rem;
        @include sh-background-color('black-10');

        &__photo {
          &--wrapper {
            min-width: 100px;

            @include media-query('md-up') {
              @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                max-width: 100px;
              }
            }
          }
        }

        &__text {
          max-width: calc(100% - 100px);
        }

        &__testimonial {
          font-size: 1.3rem;
          font-style: italic;
        }

        &__name {
          font-size: 0.9rem;
          font-weight: bold;
        }

        &__title {
          font-size: 0.9rem;
        }

        @include media-query('md-down') {
          &__photo {
            max-width: 30%;
          }

          &__text {
            text-align: center;
            max-width: 100%;
          }
        }
      }
      
      & .collection {
        .list-item__title {
          top: 50%;
          position: relative;
          transform: translate(0, -50%);
        }
      }

      &__button-row {
        margin-bottom: 2rem;

        button {
          @include media-query('xl-down') {
            padding: to-rem(8px) to-rem(12px);
            min-width: inherit;
            @include sh-font-size('xsmall');
          }
        }

        @media only screen and (max-width: 400px) {
          button {
            display: block;
            margin: 0 auto !important;
          }

          button:first-child {
            margin-bottom: 1rem !important;
          }
        }
      }
    }

    &__options-list-popup .list-item {
      cursor: default !important;
    }

    &__footnote {
      font-style: italic;
      margin-top: 1.3888rem;
      margin-bottom: 4.4rem;
    }
  }

  .card {
    &--clickable {
      .card__body {
        cursor: pointer;
        .cursor-default {
          cursor: default;
        }
      }

      .card__image {
        cursor: pointer;
      }
    }
  }
}

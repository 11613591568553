@mixin ln-multi-selectbox($options: ()) {
  
  .bg-color-black-7 .multi-selectbox {
    @include sh-background-color("white");
  }
  
  .multi-selectbox {
    @include sh-background-color("black-5");
    
    &__label {
      display: none;
      -webkit-font-smoothing: antialiased;
      font-size: 1rem;
      font-weight: 400;
      letter-spacing: .00937em;
      text-decoration: inherit;
      text-transform: inherit;
      position: absolute;
      bottom: 8px;
      line-height: 1.15rem;
      cursor: text;
      margin-bottom: 0;
      left: 16px;
      right: auto;
      width: calc(100% - 48px);
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      white-space: nowrap;
      pointer-events: none;
      overflow: hidden;
    }
    &__option-list {
      position: absolute;
      top: 56px;
      left: 0;
      min-width: 100%;
      max-width: 320px;
      z-index: sh-z-index('dropdown');
      overflow: hidden;
      overflow-y: auto;
      max-height: 200px;
      display: none;

      @include media-query('md-down') {
        position: fixed;
        max-height: 100%;
        top: 50px !important;
        bottom: 0px !important;
        margin-bottom: 0;
        @include sh-background-color('black-7');
      } 
      
      @media not all and (min-resolution:.001dpcm) { 
        @supports (-webkit-appearance:none) {
          bottom: 3.75rem !important;
        }
      }
    }
    
    &__button-wrapper {
      display: none;
      position: fixed;
      height: 50px;
      top: 0;
      left: 0;
      width: 100%;
      @include sh-background-color('black-7');
      box-shadow: 0 6px 0.4rem -4px rgba(27, 27, 27, 0.2);
      z-index: 1;
      
      .button {
        flex-grow: 0;
        margin: 0 1rem;
      }
    }
    
    &__option {
      margin: 0;
      padding: 0.666rem 0.8885rem;
      text-overflow: ellipsis;
      display: flex;
      white-space: nowrap;
      text-align: left;
      overflow: hidden;
      .checkbox + label {
        margin-bottom: 0;
        width: calc(100% - 0.8885rem);
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          display: block;
        }
      }

      @include media-query('md-down') {
        margin: 1rem;
        @include sh-background-color('white');
        &:hover {
          @include sh-background-color('white');
        }
      }
    }

    &__hint--validation {
      color: #e7001d;
      margin-top: -1.333rem;
    }
    
    &.is-open {
      .multi-selectbox__option-list {
        display: block;

        &.service-metric-margin {
          @include media-query('md-down') {
            padding-top: 5rem;
          }
        }
      }
      .multi-selectbox__button-wrapper {
        @include media-query('md-down') {
          display: flex;
          z-index: sh-z-index('dropdown') + 1;
        }

        &.search-mobile {
          margin-top: 2.5rem;
        }
      }
    }
    &.is-active {
      .selectbox__label {
        width: 100%;
      }
      .multi-selectbox__label {
          display: block;
      }
    }
    &.dropup {
      .multi-selectbox__option-list {
        @include ln-only-ie() {
          top: auto;
        }
        top: initial;
        bottom: 56px;
        margin-bottom: 0;
      }
    }
  }
}

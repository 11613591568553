@mixin ln-calendar-sheet($options: ()) {
  $_defaults: ();
  $config: map-merge($_defaults, $options);

  .calendar-sheet {
    font-size: 2.5rem;
    margin-right: 0.3rem;
    padding-bottom: 1rem;

    &__content {
      line-height: 1;
      height: 1.666666666666667em;
      width: 2.2222em;
      text-align: center;
      flex-direction: column;
    }

    &__day {
      @include sh-background-color('black-10');
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: inline-block;
      font-size: 1.5rem;
      padding-top: 0.6rem;
    }

    &__month {
      font-size: 0.5em;
      font-weight: 600;
      padding-top: .5rem;
    }
  }
}

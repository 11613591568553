/// Component: Textfield
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Textfield
/// sh-textfield($options: (
///
/// ));
///
@mixin sh-textfield($options: ()) {
  $_defaults: (
    bottom-margin: to-rem(30),
    x-padding: to-rem(15),
    small-font-factor: 0.775,
    width: to-rem(220),
  );
  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}textfield";

  :host {
    display: inline-block;
    width: map-get($_config, "width"); // configured width can be overridden by utility class
  }

  #{$_component} {
    @include sh-background-color("black-7");
    display: inline-block;
    position: relative;
    height: to-rem(56);
    width: 100%;
    margin-bottom: map-get($_config, "bottom-margin");
    border-bottom: 1px solid sh-color("black-60");
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &__input {
      @include sh-font-size("medium");
      @include sh-color("black-90");
      @include sh-font-family("text");
      appearance: none;
      align-self: flex-end;
      width: 100%;
      height: 100%;
      padding: 20px map-get($_config, "x-padding") 0;
      border: none;
      background-color: transparent;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &::-ms-clear {
        display: none;
      }

      &::placeholder {
        @include sh-color("black-60");
      }

      &:focus {
        outline: none;
      }
    }

    &__label {
      @include sh-font-size("medium");
      @include sh-color("black-60");
      @include sh-font-family("text");
      left: map-get($_config, "x-padding");
      right: auto;
      position: absolute;
      bottom: 17px;
      width: calc(100% - 34px);
      margin-bottom: 0;
      font-weight: 400;
      letter-spacing: 0.00937em;
      line-height: 1.15rem;
      cursor: text;
      text-decoration: inherit;
      text-transform: inherit;
      text-overflow: ellipsis;
      white-space: nowrap;
      pointer-events: none;
      overflow: hidden;
      will-change: transform;
      -webkit-font-smoothing: antialiased;
      transform-origin: left top;
      transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1),
        color 0.15s cubic-bezier(0.4, 0, 0.2, 1),
        width 0.15s cubic-bezier(0.4, 0, 0.2, 1);
      user-select: none;
    }

    &__hint {
      color: sh-color("black-60");
      display: block;
      margin-bottom: map-get($_config, "bottom-margin");
      padding: to-rem(7) map-get($_config, "x-padding") 0;
      font-size: #{map-get($_config, "small-font-factor")}rem;
      @include sh-line-height("xsmall");
    }

    &--with-prefix {
      #{$_component}__prefix {
        @include sh-color("black-60");
        display: block;
      }

      #{$_component}__label {
        width: calc(100% - 65px);
        left: 48px;
      }

      #{$_component}__input {
        padding-left: 48px;
      }
    }

    &--with-postfix {
      #{$_component}__postfix {
        display: block;
      }

      #{$_component}__label {
        width: calc(100% - 65px);
      }

      #{$_component}__input {
        padding-right: 48px;
      }
    }

    &--with-prefix#{$_component}--with-postfix {
      #{$_component}__label {
        width: calc(100% - 96px);
      }

      #{$_component}__input {
        padding-left: 48px;
        padding-right: 48px;
      }
    }

    &--large {
      width: 100%;
    }

    &__prefix {
      left: 15px;
      right: auto;
      position: absolute;
      bottom: 14px;
      cursor: default;
      pointer-events: none;
      display: none;
    }

    &__postfix {
      left: auto;
      right: 15px;
      cursor: default;
      pointer-events: none;
      position: absolute;
      bottom: 14px;
      cursor: pointer;
      display: none;
    }

    &::after { // ? which element
      @include sh-indicator() {
        content: "\e836";
      }
      content: none;
      position: absolute;
      left: auto;
      right: map-get($_config, "x-padding");
      bottom: to-rem(12);
      background: transparent;
      color: sh-color("black-20");
      cursor: pointer;
    }

    &.is-disabled {
      @include sh-opacity("disabled");
      pointer-events: none;
    }

    &.is-active {
      #{$_component}__label {
        transform: translateY(-40%)
          scale(map-get($_config, "small-font-factor"));
      }
    }
    &.is-valid {
      border-color: sh-color("green");

      #{$_component}__label {
        width: calc(100% - 77px);
      }

      &#{$_component}--with-prefix {
        #{$_component}__label {
          width: calc(100% - 108px);
        }
      }

      #{$_component}__input {
        padding-right: 60px;
      }

      // #{$_component}__hint {
      //   @include sh-color("green");
      // }

      &::after {
        // content: sh-icon("check");
        content: "\e818";
        background-color: sh-color("green");
        color: sh-color("white");
      }
    }

    &.is-invalid {
      border-color: sh-color("red");

      #{$_component}__label {
        width: calc(100% - 77px);
      }

      &#{$_component}--with-prefix {
        #{$_component}__label {
          width: calc(100% - 108px);
        }
      }

      #{$_component}__input {
        padding-right: 48px;
      }

      // #{$_component}__hint {
      //   @include sh-color("red");
      // }

      &::after {
        // content: sh-icon("close");
        content: "\e817";
        background-color: sh-color("red");
        color: sh-color("white");
      }
    }

    &:focus,
    &:focus-within,
    &.has-focus {
      @include sh-border-color("black-90");

      #{$_component}__prefix {
        @include sh-color("black-90");
      }
    }

    // Gray theme

    .bg-color-black-7 &,
    :host([theme="gray"]) & {
      @include sh-background-color("white");
    }

    // Dark theme

    [class*="bg-color-gray-shade"] &,
    .bg-color-black &,
    :host([theme="dark"]) & {
      @include sh-background-color("black-90");
      border-bottom: 1px solid sh-color("black-40");

      &__label {
        @include sh-color("black-40");
      }

      &__input {
        &::placeholder {
          @include sh-color("black-40");
        }

        @include sh-color("black-10");
      }

      &__hint {
        color: sh-color("black-40");
      }

      &:focus,
      &:focus-within,
      &.has-focus {
        @include sh-border-color("black-10");

        #{$_component}__prefix {
          @include sh-color("white");
        }
      }
    }
    
    @content;
  }
}

/// Component: Breadcrumbs
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Breadcrumbs
/// sh-textfield($options: (
///
/// ));
///
@mixin sh-breadcrumbs($options: ()) {
  $_defaults: (
    "separator": "arrow-right",
  );
  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}breadcrumbs";

  $_separator: map-get($_config, "separator");

  #{$_component} {
    @include sh-font-size("xsmall");
    @include sh-line-height("xsmall");
    margin-bottom: sh-line-height("medium");
    list-style: none;

    &__item {
      display: inline-block;
      color: sh-color("black-90");
      font-family: sh-font-family("text");
      margin-top: 0;
      margin-bottom: 0;

      &::after {
        @include sh-icon-reset() {
          content: "\e81c";
        }
        color: sh-color("black-60");
        display: inline-block;
        padding-right: to-rem(5);
        padding-left: to-rem(5);
        text-align: center;
        vertical-align: baseline;
        font-size: 75%;

        [class*="bg-color-black"] &,
        .bg-color-black &,
        :host([theme="dark"]) &,
        .dark &{
          background: transparent;
          @include sh-color("black-40");
        }
      }

      &:last-child::after {
        display: none;
      }

      [class*="bg-color-black"] &,
      .bg-color-black &,
      :host([theme="dark"]) &,
      .dark &{
        background: transparent;
        @include sh-color("black-10");
        @include focus(); 
      }
    }

    &__link {
      color: sh-color("black-60");
      font-family: sh-font-family("text");

      @include focus();
     
      [class*="bg-color-black"] &,
      .bg-color-black &,
      :host([theme="dark"]) &,
      .dark &{
        background: transparent;
        @include sh-color("black-40");
        @include focus();
        &:hover {
          @include sh-color("black-10"); 
        }
      }
    }

    @content;
  }
  
}

@mixin ln-button-group($options: ()) {
    
    
    $_default: (

    );

    $config: map-merge($_default, $options);

    .button-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
      
        & .button {
            margin-bottom: 0;
            flex-grow: 1;
        }

        & .button:not(:first-child) {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            margin-top: -1px;
        }
        
        & .button:not(:last-child) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        @include media-query('sm') {

            flex-direction: row;

            & .button:not(:first-child) {
                border-top-left-radius: 0;
                border-top-right-radius: 3px;
                border-bottom-left-radius: 0;
                margin-top: 0;
                margin-left: -1px;
            }
            
            & .button:not(:last-child) {
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
                border-bottom-left-radius: 3px;
            }

            &--wrapped {
                flex-wrap: wrap;
                row-gap: 0.2rem;
            }
        }

        &--activities {
            
            flex-direction: row;
            margin-top: 1rem;
            margin-bottom: 0rem;
            
            @include media-query('md') {
                margin-top: 0;
            }

            @include media-query('lg-down') {
                width: 100%;
            }

            & .button:not(:first-child) {
                border-top-left-radius: 0;
                border-top-right-radius: 3px;
                border-bottom-left-radius: 0;
                margin-top: 0;
                margin-left: -1px;
            }
            
            & .button:not(:last-child) {
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
                border-bottom-left-radius: 3px;
            }
        }

        &--large {
            width: 100%;
        }
      
        &--spr-button-size {
          height: 50px;
        }
    }
}

/// Icon
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Icon
/// sh-icon($options: ();
///
@mixin sh-icon($options: ()) {
  $_defaults: (
    font-name: "SH Icon",
    size-adjust: 100%
  );

  $config: map-merge($_defaults, $options);

  @if (map-get($config, "font-name") == "default") {
    $_font-name: sh-font-family("icon");
  } @else {
    $_font-name: map-get($config, "font-name");
  }
  $_icon-size-adjust: map-get($config, "size-adjust");


  [class*="icon"] {
    &::before {
      @include sh-icon-reset(map-get($config, "font-name"));
      font-size: $_icon-size-adjust; // adjusts the icon size to inline text
    }
  }
  @content;
}

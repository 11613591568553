///
/// @access public
///
///
@mixin sh-u-dimensions($options: ()) {

  .width-auto {
    width: auto !important;
  }

  .width-100 {
    width: 100% !important;
  }

  .height-100 {
    height: 100% !important;
  }
}

/// Component: Modal Dialog
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Modal Dialog
/// sh-textfield($options: (
///
/// ));
///
@mixin sh-modal-dialog($options: ()) {
  $_defaults: (
    "padding": to-rem(20),
  );
  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}modal-dialog";

  $_padding: map-get($_config, "padding");

  #{$_component} {
    @include sh-fog("overlay");
    display: none;
    z-index: sh-z-index("dialog");
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: 0;

    &.is-open {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__container {
      position: relative;
      @include sh-background-color("white");
      @include sh-color("black-90");
      @include border-radius();
      @include sh-shadow("modal");

      display: flex;
      flex-direction: column;

      @include media-query("md-down") {
        height: 100%;
        width: 100%;
      }

      @include media-query("md") {
        display: block;
        width: 600px;
        max-width: 100%;
      }
    }

    &__header {
      padding: $_padding;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: none;
    }

    &__indicator {
      display: none;
      margin-right: to-rem(10);
    }

    &__title {
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: calc(100% - 2rem);
    }

    &__close {
      @include sh-font-size("xlarge");
      flex: none;

      i::before {
        @include sh-color("black-60");
        margin: 0;
      }
      
      &:hover {
        i::before {
          @include sh-color("black-90");
        }
      }
    }

    &__body {
      padding: $_padding;
      @include respect-inner-padding();
      overflow: hidden;
      overflow-y: auto;
      max-height: calc(100vh - 9rem);
    }

    &__footer {
      padding: $_padding;
      display: flex;
      justify-content: flex-end;
      flex: none;
      margin-top: auto; // always sit on bottom

      > * {
        margin: 0 0 0 to-rem(10);
      }
    }

    &--success {
      #{$_component}__indicator {
        display: inline-block;

        &::after {
          @include sh-indicator(
            (
              "icon": "check",
              "bgColor": "green",
            )
          ) {
            content: "\e818";
          };
        }
      }
    }

    &--warning {
      #{$_component}__indicator {
        display: inline-block;

        &::after {
          @include sh-indicator(
            (
              "icon": "warning",
              "bgColor": "yellow",
            )
          ) {
            content: "\e836";
          };
          color: sh-color("black");
        }
      }
    }

    &--error {
      #{$_component}__indicator {
        display: inline-block;

        &::after {
          @include sh-indicator(
            (
              "icon": "close",
              "bgColor": "red",
            )
          ) {
            content: "\e817";
          };
        }
      }
    }


    // Dark theme
    [class*="bg-color-gray-shade"] &,
    .bg-color-black &,
    :host([theme="dark"]) & {
      
      &__container {
        @include sh-background-color("black-90");
        @include sh-color("black-10");
        @include sh-shadow("modal-dark");
      }
      
      &__close {
        i::before {
          @include sh-color("black-40");
          margin: 0;
        }

        &:hover {
          i::before {
            @include sh-color("black-10");
          }
        }
      }
    }

    @content;
  }
}

/// Component: Hero Block
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Hero Block
/// sh-textfield($options: (
///
/// ));
///
@mixin sh-hero-block($options: ()) {
  $_defaults: (
    "background": sh-color("black-7"),
    "shift": -4em,
  );
  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}hero-block";

  $_shift: map-get($_config, "shift");
  $_background: map-get($_config, "background");

  #{$_component} {
    width: 100%;
    @include sh-background-color("black");

    &__image {
      @media screen and (max-width: map-get(sh-grid("max-widths"), "sm")) {
        width: 100vw;
        margin-left: -0.5 * sh-grid("gutter-width");
      }

      @include media-query("md") {
        height: 100%;
      }
    }

    &__pulse {
      width: 100%;
      margin: sh-grid("gutter-width") 0;
      display: none;

      @include media-query("sm") {
        display: block;
      }

      @include media-query("lg") {
        margin: sh-grid("gutter-width") * 2 0;
      }
    }

    &__panel {
      @include sh-background-color('black');
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      // height: 100%;
    }

    &__content {
      height: 100%;
      justify-content: center;
      display: flex;
      flex-direction: column;

      @include media-query("lg") {
        & > *:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__title {
      margin-top: to-rem(15);
      margin-bottom: to-rem(20);
      @include sh-color("orange");

      @include media-query("lg") {
        margin-top: 0;
      }
    }

    &__subtitle {
      @include sh-color("white");
      margin-top: 0;
      margin-bottom: 0;
      display: block;
    }

    &__text {
      @include sh-background-color("white");
      padding-top: to-rem(20);
    }

    &--white {
      @include sh-background-color("white");

      #{$_component} {
        &__pulse {
          display: none;
        }

        &__subtitle {
          @include sh-color("black-90");
        }

        &__panel {
          @include sh-background-color("white");
        }

        &__text {
          padding-top: 0;
          
          @include media-query("lg") {
            padding-top: to-rem(20);
          }
        }
      }
    }

    @content;
  }
}

@mixin ln-nested-multi-selectbox($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .nested-multi-selectbox {
    &__option {
      border-bottom: 1px solid sh-color('black-10');

      .checkbox + label {
        width: unset !important;
        max-width: calc(100% - 0.8885rem);
        vertical-align: middle;
      }
    }

    &__children-margin {
      margin-left: 1.1rem;
    }
    
    &__selectable-area {
      width: 100%;
      padding: 0.666rem 0 0.666rem 0.8885rem;
      max-width: calc(100% - 2.4rem);
    }

    &__arrow, &__non-clickable-area {
      cursor: pointer;
      width: 100%;
      padding: 0.666rem 0.8885rem 0.666rem 0;

      &:not(&--disabled):hover {
        color: sh-color('orange');
      }
    }
    
    &__arrow--disabled {
      opacity: 0.6;
    }

    &__non-clickable-area {
      cursor: default;
    }
  }
}

@charset "UTF-8";

/* Settings */

@import "@shs/ui-marcom/src/scss/settings/application";
@import "@shs/ui-marcom/src/scss/settings/aspect-ratios";
@import "@shs/ui-marcom/src/scss/settings/border";
@import "@shs/ui-marcom/src/scss/settings/breakpoints";
@import "@shs/ui-marcom/src/scss/settings/colors";
@import "@shs/ui-marcom/src/scss/settings/fogs";
@import "@shs/ui-marcom/src/scss/settings/font-families";
@import "@shs/ui-marcom/src/scss/settings/font-sizes";
@import "@shs/ui-marcom/src/scss/settings/font-weights";
@import "@shs/ui-marcom/src/scss/settings/grid";
@import "@shs/ui-marcom/src/scss/settings/icon-chars";
@import "@shs/ui-marcom/src/scss/settings/line-heights";
@import "@shs/ui-marcom/src/scss/settings/opacities";
@import "@shs/ui-marcom/src/scss/settings/shadow";
@import "@shs/ui-marcom/src/scss/settings/transitions";
@import "@shs/ui-marcom/src/scss/settings/z-index";

/* FUNCTIONS */

@import "@shs/ui-marcom/src/scss/tools/functions/utilities/math";
@import "@shs/ui-marcom/src/scss/tools/functions/utilities/ratio";
@import "@shs/ui-marcom/src/scss/tools/functions/utilities/shift-darkness";
@import "@shs/ui-marcom/src/scss/tools/functions/utilities/str-replace";
@import "@shs/ui-marcom/src/scss/tools/functions/utilities/strip-unit";
@import "@shs/ui-marcom/src/scss/tools/functions/utilities/to-rem";
@import "@shs/ui-marcom/src/scss/tools/functions/utilities/to-em";

@import "@shs/ui-marcom/src/scss/tools/functions/breakpoint";
@import "@shs/ui-marcom/src/scss/tools/functions/breakpoint-list";
@import "@shs/ui-marcom/src/scss/tools/functions/color";
@import "@shs/ui-marcom/src/scss/tools/functions/color-list";
@import "@shs/ui-marcom/src/scss/tools/functions/fog";
@import "@shs/ui-marcom/src/scss/tools/functions/font-size";
@import "@shs/ui-marcom/src/scss/tools/functions/font-family";
@import "@shs/ui-marcom/src/scss/tools/functions/font-weight";
@import "@shs/ui-marcom/src/scss/tools/functions/grid";
@import "@shs/ui-marcom/src/scss/tools/functions/icon-char";
@import "@shs/ui-marcom/src/scss/tools/functions/icon-list";
@import "@shs/ui-marcom/src/scss/tools/functions/line-height";
@import "@shs/ui-marcom/src/scss/tools/functions/opacity";
@import "@shs/ui-marcom/src/scss/tools/functions/shadow";
@import "@shs/ui-marcom/src/scss/tools/functions/transition";
@import "@shs/ui-marcom/src/scss/tools/functions/z-index";

/* @import "tools/grid"; */

/* MIXINS */

@import "@shs/ui-marcom/src/scss/tools/mixins/background-color";
@import "@shs/ui-marcom/src/scss/tools/mixins/background-hover";
@import "@shs/ui-marcom/src/scss/tools/mixins/border-color";
@import "@shs/ui-marcom/src/scss/tools/mixins/border-radius";
@import "@shs/ui-marcom/src/scss/tools/mixins/color";
@import "@shs/ui-marcom/src/scss/tools/mixins/fog";
@import "@shs/ui-marcom/src/scss/tools/mixins/font-size";
@import "@shs/ui-marcom/src/scss/tools/mixins/font-family";
@import "@shs/ui-marcom/src/scss/tools/mixins/font-weight";
@import "@shs/ui-marcom/src/scss/tools/mixins/icon-char";
@import "@shs/ui-marcom/src/scss/tools/mixins/icon-reset";
@import "@shs/ui-marcom/src/scss/tools/mixins/indicator";
@import "@shs/ui-marcom/src/scss/tools/mixins/line-height";
@import "@shs/ui-marcom/src/scss/tools/mixins/opacity";
@import "@shs/ui-marcom/src/scss/tools/mixins/shadow";
@import "@shs/ui-marcom/src/scss/tools/mixins/states";
@import "@shs/ui-marcom/src/scss/tools/mixins/z-index";

@import "@shs/ui-marcom/src/scss/tools/mixins/utilities/media-query";
@import "@shs/ui-marcom/src/scss/tools/mixins/utilities/clearfix";
@import "@shs/ui-marcom/src/scss/tools/mixins/utilities/hover-focus-active";
@import "@shs/ui-marcom/src/scss/tools/mixins/utilities/image-fluid";
@import "@shs/ui-marcom/src/scss/tools/mixins/utilities/image-retina";
@import "@shs/ui-marcom/src/scss/tools/mixins/utilities/outline";
@import "@shs/ui-marcom/src/scss/tools/mixins/utilities/respect-inner-padding";
@import "@shs/ui-marcom/src/scss/tools/mixins/utilities/text-truncate";
@import "@shs/ui-marcom/src/scss/tools/mixins/utilities/transforms";
@import "@shs/ui-marcom/src/scss/tools/mixins/utilities/screen-reader";

/* GENERIC */

@import "@shs/ui-marcom/src/scss/generic/box-sizing";
@import "@shs/ui-marcom/src/scss/generic/suppress-focus-outline";
@import "@shs/ui-marcom/src/scss/generic/touch-support";

/* ELEMENTS */

@import "@shs/ui-marcom/src/scss/elements/html";
@import "@shs/ui-marcom/src/scss/elements/body";

@import "@shs/ui-marcom/src/scss/elements/abbr";
@import "@shs/ui-marcom/src/scss/elements/adress";
@import "@shs/ui-marcom/src/scss/elements/anchor";
@import "@shs/ui-marcom/src/scss/elements/blockquote";
@import "@shs/ui-marcom/src/scss/elements/button";
@import "@shs/ui-marcom/src/scss/elements/fieldset";
@import "@shs/ui-marcom/src/scss/elements/figures";
@import "@shs/ui-marcom/src/scss/elements/headings";
@import "@shs/ui-marcom/src/scss/elements/hgroup";
@import "@shs/ui-marcom/src/scss/elements/horizontal-rule";
@import "@shs/ui-marcom/src/scss/elements/image";
@import "@shs/ui-marcom/src/scss/elements/inline-elements";
@import "@shs/ui-marcom/src/scss/elements/inputs";
@import "@shs/ui-marcom/src/scss/elements/label";
@import "@shs/ui-marcom/src/scss/elements/lists";
@import "@shs/ui-marcom/src/scss/elements/paragraph";
@import "@shs/ui-marcom/src/scss/elements/pre";
@import "@shs/ui-marcom/src/scss/elements/select";
@import "@shs/ui-marcom/src/scss/elements/table";
@import "@shs/ui-marcom/src/scss/elements/textarea";

/* ⇧ Include into main sass file of Gravity  */
/* ⇩ Include into concrete projects */

/* OBJECTS */

@import "@shs/ui-marcom/src/scss/objects/aspect-ratio";
@import "@shs/ui-marcom/src/scss/objects/bare-list";
@import "@shs/ui-marcom/src/scss/objects/block-grid";
@import "@shs/ui-marcom/src/scss/objects/card";
@import "@shs/ui-marcom/src/scss/objects/collection";
@import "@shs/ui-marcom/src/scss/objects/collection-item";
@import "@shs/ui-marcom/src/scss/objects/grid";
@import "@shs/ui-marcom/src/scss/objects/icon";
@import "@shs/ui-marcom/src/scss/objects/media-object";
@import "@shs/ui-marcom/src/scss/objects/multi-column";

/* COMPONENTS */

/* @import "../slick-carousel/slick/slick.scss"; */
@import "@shs/ui-marcom/src/scss/components/accordion";
@import "@shs/ui-marcom/src/scss/components/application-bar";
@import "@shs/ui-marcom/src/scss/components/alert";
@import "@shs/ui-marcom/src/scss/components/back-to-top";
@import "@shs/ui-marcom/src/scss/components/breadcrumbs";
@import "@shs/ui-marcom/src/scss/components/button";
@import "@shs/ui-marcom/src/scss/components/card-slider";
@import "@shs/ui-marcom/src/scss/components/checkbox";
/* @import "components/carousel"; */
@import "@shs/ui-marcom/src/scss/components/comparison";
@import "@shs/ui-marcom/src/scss/components/date-time-picker";
@import "@shs/ui-marcom/src/scss/components/disclaimer";
@import "@shs/ui-marcom/src/scss/components/dropdown";
@import "@shs/ui-marcom/src/scss/components/feedback";
@import "@shs/ui-marcom/src/scss/components/figure";
@import "@shs/ui-marcom/src/scss/components/footer";
@import "@shs/ui-marcom/src/scss/components/hero-block";
@import "@shs/ui-marcom/src/scss/components/hotspots";
@import "@shs/ui-marcom/src/scss/components/inpage-navigation";
@import "@shs/ui-marcom/src/scss/components/label";
@import "@shs/ui-marcom/src/scss/components/legal-links";
@import "@shs/ui-marcom/src/scss/components/list";
@import "@shs/ui-marcom/src/scss/components/logo";
@import "@shs/ui-marcom/src/scss/components/loading-animation";
@import "@shs/ui-marcom/src/scss/components/meta-menu";
@import "@shs/ui-marcom/src/scss/components/menu";
@import "@shs/ui-marcom/src/scss/components/modal-dialog";
@import "@shs/ui-marcom/src/scss/components/multimedia-container";
@import "@shs/ui-marcom/src/scss/components/navigation-bar";
@import "@shs/ui-marcom/src/scss/components/pagination";
@import "@shs/ui-marcom/src/scss/components/progress";
@import "@shs/ui-marcom/src/scss/components/overlay";
@import "@shs/ui-marcom/src/scss/components/quotation";
@import "@shs/ui-marcom/src/scss/components/radio";
@import "@shs/ui-marcom/src/scss/components/search-field";
@import "@shs/ui-marcom/src/scss/components/search-results";
@import "@shs/ui-marcom/src/scss/components/selectbox";
@import "@shs/ui-marcom/src/scss/components/sitemap";
@import "@shs/ui-marcom/src/scss/components/social-list";
@import "@shs/ui-marcom/src/scss/components/stage";
@import "@shs/ui-marcom/src/scss/components/steps-bar";
@import "@shs/ui-marcom/src/scss/components/table";
@import "@shs/ui-marcom/src/scss/components/tabs";
@import "@shs/ui-marcom/src/scss/components/tag";
@import "@shs/ui-marcom/src/scss/components/textfield";
@import "@shs/ui-marcom/src/scss/components/toggle-switch";
@import "@shs/ui-marcom/src/scss/components/tooltip";
@import "@shs/ui-marcom/src/scss/components/user-identifier";

/* UTILITIES */

@import "@shs/ui-marcom/src/scss/utilities/alignment";
@import "@shs/ui-marcom/src/scss/utilities/border";
@import "@shs/ui-marcom/src/scss/utilities/border-radius";
@import "@shs/ui-marcom/src/scss/utilities/background-color";
@import "@shs/ui-marcom/src/scss/utilities/background-image";
@import "@shs/ui-marcom/src/scss/utilities/color";
@import "@shs/ui-marcom/src/scss/utilities/dimension";
@import "@shs/ui-marcom/src/scss/utilities/display";
@import "@shs/ui-marcom/src/scss/utilities/flex.scss";
@import "@shs/ui-marcom/src/scss/utilities/floating.scss";
@import "@shs/ui-marcom/src/scss/utilities/font-family";
@import "@shs/ui-marcom/src/scss/utilities/font-size";
@import "@shs/ui-marcom/src/scss/utilities/font-style";
@import "@shs/ui-marcom/src/scss/utilities/font-weight";
@import "@shs/ui-marcom/src/scss/utilities/image-replacement";
@import "@shs/ui-marcom/src/scss/utilities/image-size";
@import "@shs/ui-marcom/src/scss/utilities/margin";
@import "@shs/ui-marcom/src/scss/utilities/padding";
@import "@shs/ui-marcom/src/scss/utilities/positioning";
@import "@shs/ui-marcom/src/scss/utilities/text-decoration";
@import "@shs/ui-marcom/src/scss/utilities/visibility";

blockquote {
  margin: 0 0 to-rem(30);
  padding: 0;
  border: none;

  &,
  & p {
    @include sh-font-family("text");
    @include sh-font-size("xxlarge");
    @include sh-font-weight("light");
    @include sh-line-height("xxlarge");
    font-style: italic;
    text-align: center;

    @include media-query("md") {
        text-align: left;
    }
  }
}

cite {
  @include sh-font-family("text");
  @include sh-font-size("xsmall");
  @include sh-line-height("xsmall");
  @include sh-color("black-30");
  display: block;
  font-style: normal;
  margin-top: to-rem(15);
  text-align: center;

  @include media-query("md") {
    text-align: left;
  }
}


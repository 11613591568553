@mixin ln-checkout-steps($options: ()) {
  $_defaults: ();
  $config: map-merge($_defaults, $options);

  .checkout-steps {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;

    &__item {
      -webkit-box-flex: 1;
      -ms-flex: 1 1 0%; /*percentage character must be kept because of IE  */
      flex: 1 1 0%; /*percentage character must be kept because of IE  */
      position: relative;

      &::before,
      &::after {
        border: 1px dotted;
        @include sh-border-color('black-10');
        display: block;
        content: "";
        position: absolute;
        top: calc(1rem + 20px);
      }

      &::after {
        left: calc(50% + 30px);
        right: 0;
      }

      &::before {
        right: calc(50% + 30px);
        left: 1px;
      }

      &:first-child::before,
      &:last-child::after {
        display: none;
      }
    }

    &__number {
      width: 40px;
      height: 40px;
      border: 1px solid;
      @include sh-border-color('black-30');
      border-radius: 40px;
      font-weight: 600;
      @include sh-color('black-60');
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin: 1rem auto;
      opacity: 0.4;
    }

    &__title, &__text {
      @include sh-color('black-60');
      text-align: center;
      font-size: 0.8889rem;
      line-height: 20px;
      opacity: 0.4;
    }

    &__title {
      font-weight: 600;
      line-height: 30px;
    }

    &__item.is-completed &__number {
      @include sh-border-color('black-10');
      @include sh-background-color('black-10');
      @include sh-color('black-60');
      opacity: 1;
    }

    &__item.is-completed &__title,
    &__item.is-completed &__text {
      @include sh-color('black-60');
      opacity: 1;
    }

    &__item.is-current &__number {
      @include sh-color('white');
      @include sh-border-color('orange');
      @include sh-background-color('orange');
      opacity: 1;
    }

    &__item.is-current &__title,
    &__item.is-current &__text {
      @include sh-color('black-60');
      opacity: 1;
    }

    &.is-disabled &__item.is-current &__number {
      @include sh-border-color('black-60');
      @include sh-background-color('black-60');
    }
  }
}

/// Strip list-like appearance from lists by removing their bullets and any indentation.
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @example scss - Bare List
/// sh-bare-list();
///
@mixin sh-bare-list() {
  .bare-list {
    list-style: none;
    margin-left: 0;
    margin-bottom: 0;
    padding-left: 0;

    &__item {
      margin-left: 0;
    }
  }
}

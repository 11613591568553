/* == Planned Activities */

.calendar-view {
  .calendar {
    .accordion {
      &__panel {
        background-color: inherit !important;
      }
    }

    .current-month {
      @include media-query('lg') {
        padding: 10px;
      }
      .pagination {
        justify-content: center;
        @include media-query('lg') {
          justify-content: flex-start;
        }
      }
      h1 {
        margin: 0;
        font-weight: bold;
        line-height:26px;
      }
    }
    table {
      width: 100%;
      border-collapse: collapse;
      table-layout: fixed;
      th {
        width: calc(100% / 7);
        padding: 5px 10px;
        font-weight: normal;
      }
      td {
        width: calc(100% / 7);
        vertical-align: top;
        padding: 0;
      }
      tr:last-child {
        border-bottom: none;
      }
    }

    .weekday {
      text-align: center;
      padding: 1rem;
    }

    .week {
      height: 130px;
    }

    .day {
      .day-number {
        padding: 5px 10px;
        font-weight: bold
      }
      &.other {
      }
      &.default {
        .day-number {
        }
      }
      &.disabled {
        opacity: .3;
      }
    }
    .event {
      cursor: pointer;
      font-size: 12px;
      padding: 5px 10px;
      line-height: 1.44;

      .ticket-nr {
        display: block;
      }
      .title {
        font-weight: bold;
      }
      .time {
        font-weight: bold;
        white-space: nowrap;
      }
      .status-red{
        @include sh-color('red');
      }
      .status-yellow{
        @include sh-color('yellow');
      }
      .status-green{
        @include sh-color('green');
      }
      .status-blue{
        color: rgb(58,191,237);
      }
      .device {
        display: block;
      }
      &:hover {
        @include sh-background-color('black-7');
      }
    }
  }
}

.cursor-pointer{
  cursor: pointer;
}

@mixin ln-hero-block($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .hero-block {
    display: flex;
    flex-direction: column-reverse;

    &__title {
      @include sh-color('orange');
      margin-bottom: 0.25rem;
      order: 1;

      @include media-query('md') {
        margin-bottom: 1rem;
      }
    }

    &__description {
      margin-bottom: 0;
      order: 3;

      @include media-query('md') {
        order: 2;
      }
    }

    &__action-panel {
      order: 2;

      .button {
        width: 100%;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      @include media-query('md') {
        order: 3;

        .button {
          margin-bottom: 0;
          width: inherit;

          &:last-of-type {
            margin-left: 0.8rem;
          }
        }
      }
    }

    &__seperator-line {
      @include media-query('md') {
        display: none;
      }
    }

    &__image {
      background-size: auto 100%;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      display: block;
    }
    
    &--white .hero-block__panel {
      background: #f1f1f1;
    }
    
    &__panel {
      position: relative;
      z-index: 100;
      margin-top: -4em;
      padding: 1.666rem;
      margin-left: 0;
      margin-right: 0;

      &>*:first-child {
        margin-top: 0;
      }
    }
  }
}

@use 'sass:math';

/* 
Component: Table
 @access public
 @author Robert John (rj@click-solutions.de)
 @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
 @example scss - Table
 ln-table($options: (
   
 ));
*/
@mixin ln-table($options: ()) {
  $_defaults: (
    padding: math.div(sh-grid('gutter-width'), 2),
  );
  
  $_config: map-merge($_defaults, $options);

  $_padding: map-get($_config, 'padding');

  .#{$prefix}table {
    &--small-paddings {
      th, td {
        padding: 0 math.div($_padding, 2) math.div($_padding, 2) math.div($_padding, 2);
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
    &--no-borders {
      tr {
        border: none;
        th {
          border: none;
        }
      }
    }
    &--scrollable {
      display:block;
      overflow:auto;
      max-height:340px;
      width:100%;
      td {
        padding-right: 2rem;
      }
    }

    &--checkbox {
      top: -18px;
      left: 6px;
    }

    &--padding-0 {
      td {
        padding: 0;
      }
    }

    .table_background_white{
      background-color: white !important;
    }

    &--checksheet {
      th:last-child {
        text-align: center;
      }
      th, td {
        padding: math.div($_padding, 2);
        &:last-child {
          padding-right: 0;
        }
        .radio {
          display: none;
          & + label {
            padding: 0;
            margin: 0;
          }
          & + label::before {
            top: -.7rem;
            left: 0;
          }
        }
      }
      th {
        vertical-align: bottom;
      }
    }
  }
}

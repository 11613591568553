@mixin ln-textfield($options: ()) {

  $_default: ();
  $config: map-merge($_default, $options);

  $_component: ".textfield";

  #{$_component} {
    @include sh-background-color("black-5");
    
    &__label {
      background-color: inherit;
    }

    &__prefix.icon-search {
      bottom: 9px;
    }

    &__postfix--clickable {
      pointer-events: inherit;
    }

    &__input {
      &--copy {
        padding: 5px 45px 5px 27.5px;
        position: relative;
        border: none;
        background-color: unset;
      }

      &--icon {
        cursor: pointer;
        right: 7.5px;
        position: absolute;
        z-index: 9999;
        top: 50%;
        transform: translateY(-50%);

        & > .icon-copy::before {
          font-size: 1rem !important;
        }
      }
    }

    .is-required {
      border-color: sh-color('red');
    }

    .bg-color-black-7 & {
      @include sh-background-color("white");
    }
    
    &--copy {
      display: flex;
      align-items: center;
    }
    
    &--no-label {
      height: to-rem(38);
      margin-bottom: 0;

      #{$_component}__input {
        padding-top: to-rem(2px);
      }
    }
  }

  .icon-world::before {
    font-size: 1.05em;
  }
}

/// Multi Column System
/// @access public
/// @author Sebastian Liebner (sl@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Multi Column System
/// sh-multi-column($options: (
///   breakpoints: ()
/// ));
///
@mixin sh-multi-column($options: ()) {
  $_defaults: (
    max-columns: 12,
    media-queries: sh-breakpoint-list(),
    gutter-width: sh-grid("gutter-width"),
  );
  $_config: map-merge($_defaults, $options);

  $_media-queries: map-get($_config, "media-queries");
  $_max-columns: map-get($_config, "max-columns");
  $_gutter-width: map-get($_config, "gutter-width");

  .#{$prefix}multi-column {
    column-gap: $_gutter-width;
    column-count: 1;
    margin: 0;
  }

  // [1] respect element margins at the top af a new column
  .#{$prefix}multi-column__item {
    display: inline-block; // [1]
    width: 100%;
  }

  .#{$prefix}multi-column__block {
    display: table;
    margin: 0;
    break-inside: avoid;
  }

  @include _multi-column-amount("", $_max-columns);

  @each $_media-query in $_media-queries {
    @include media-query($_media-query) {
      @include _multi-column-amount($_media-query, $_max-columns);
    }
  }
}

@mixin _multi-column-amount($mq, $amount) {
  $infix: "";

  @if $mq != "" {
    $infix: "-#{$mq}";
  }

  @for $i from 1 to $amount + 1 {
    .#{$prefix}multi-column#{$infix}-#{$i} {
      column-count: $i;
    }
  }
}

/* 
 Component: Textarea
 @access public
 @author Denis Kurochkin (dk@click-solutions.de)
 @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
 @example scss - Textarea
 ln-textarea($options: (
   
 ));
*/
@mixin ln-textarea($options: ()) {
  $_base-mobile-width: 105px;

  .#{$prefix}textarea {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    display: inline-flex;
    position: relative;
    margin-bottom: 16px;
    border-bottom: 1px solid sh-color('black-30');
    width: 200px;
    @include sh-background-color('black-5');

    &:before, &:after {
      @include sh-background-color('black-5');
      display: block;
      position: absolute;
      left: 0;
      right: 15px;
      content: '';
    }

    &:before {
      top: 0;
      height: 35px;
    }

    &:after {
      bottom: 0;
      height: 16px;
    }

    &__area {
      width: 100%;
      height: 100%;
      border: none;
      padding: 40px 16px 16px;
      outline: none;
      overflow: auto;
      background-color: transparent;
      @include sh-font-size('medium');
      @include sh-font-family('text');

      &::placeholder {
        @include sh-color('black-60');
      }
    }

    &__label {
      left: 17px;
      right: 17px;
      position: absolute;
      top: 19px;
      margin-bottom: 0;
      @include sh-font-size('medium');
      @include sh-color('black-60');
      @include sh-font-family('text');
      font-weight: 400;
      letter-spacing: 0.00937em;
      line-height: 1.15rem;
      cursor: text;
      text-decoration: inherit;
      text-transform: inherit;
      text-overflow: ellipsis;
      pointer-events: none;
      overflow: hidden;
      will-change: transform;
      -webkit-font-smoothing: antialiased;
      transform-origin: left top;
      transition: transform .15s cubic-bezier(.4, 0, .2, 1), color .15s cubic-bezier(.4, 0, .2, 1);
    }

    &.is-disabled {
      opacity: 0.6;
    }

    &.is-active {
      .#{$prefix}textarea__label {
        transform: translateY(-25%) scale(0.775);
        white-space: nowrap;
      }
    }

    &.has-focus {
      @include sh-border-color('orange');

      .#{$prefix}textarea__area {
        &::placeholder {
          @include sh-color('black-90');
        }
      }
    }

    &--large {
      width: 100%;
    }

    &.is-valid {
      border-color: sh-color('green');

      .#{$prefix}textarea__label {
        width: calc(100% - 60px);
      }

      .#{$prefix}textarea__input {
        padding-right: 60px;
      }

      &::after {
        display: flex;
        background-color: sh-color('green');
        color: sh-color('white');
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        left: auto;
        right: 15px;
        bottom: 14px;
        position: absolute;
        cursor: pointer;
        @include sh-font-family('icon');
        @include sh-font-weight('normal');
        font-style: normal !important;
        font-variant: normal !important;
        text-transform: none !important;
        speak: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 0.625em;
        width: 2.3em;
        height: 2.3em;
        line-height: 2.3;
        content: sh-icon-char('check');
      }
    }

    &.is-invalid {
      border-color: sh-color('red');

      .#{$prefix}textarea__label {
        width: calc(100% - 60px);
      }

      .#{$prefix}textarea__input {
        padding-right: 60px;
      }

      &::after {
        display: flex;
        background-color: sh-color('red');
        color: sh-color('white');
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        left: auto;
        right: 15px;
        bottom: 14px;
        position: absolute;
        cursor: pointer;
        @include sh-font-family('icon');
        @include sh-font-weight('normal');
        font-style: normal !important;
        font-variant: normal !important;
        text-transform: none !important;
        speak: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        @include sh-indicator((
          icon : 'close',
          bgColor: 'red'
        ));
      }
    }

    &__spr-input {
      border: none;
      resize: none;
      overflow: hidden;
      margin: 0;
      vertical-align: middle;
      max-width: 175px;
      outline: none;
      background: transparent;
      font: inherit;
      padding: 0 3px;
      line-height: 20px;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        width: auto !important;
      }

      @media only screen and (max-width: 1200px) {
        min-width: $_base-mobile-width !important;
        max-width: $_base-mobile-width !important;
      }
    }
  }

  .bg-color-black-7 {
    .#{$prefix}textarea {
      @include sh-background-color('white');

      &:before, &:after {
        @include sh-background-color('white');
      }
    }
  }

  .bg-color-black-90 {
    .#{$prefix}textarea {
      @include sh-background-color('black-75');

      &:before, &:after {
        @include sh-background-color('black-75');
      }

      &__label {
        @include sh-color('black-20');
      }

      &__area {
        &::placeholder {
          @include sh-color('black-20');
        }

        @include sh-color('white');
      }

      &.has-focus {
        .#{$prefix}textarea__area {
          &::placeholder {
            @include sh-color('white');
          }
        }
      }
    }
  }
}

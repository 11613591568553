@mixin sh-legend($options: ()) {
    $_defaults: (
    );
  
    $config: map-merge($_defaults, $options);
  
    .#{$prefix}legend {

        margin-bottom: 0;

        &__item {
            border: none;
        }

        &__value,
        &__label {
          padding: 0;
          border-bottom: 0;
          font-weight: normal;
          vertical-align: middle;
        }

        &__label {
          padding: 0 0 0 1rem;
          @include sh-font-size('small');
          line-height: 1.3rem;
        }
    }

    .legend--combo {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      &-bottom {
        margin-top: .8rem;
      }

      &-top {
        margin-bottom: .8rem;
      }

      .legend__item {
        display: inline-block;
        margin-bottom: 0;
        margin-right: .8rem;
        @include sh-color('black-60');
        @include sh-font-size('xsmall');
      }

      .legend__value {
        border-radius: 50%;
      }
      .legend__label {
        padding-left: .8rem;
      }
    }
}

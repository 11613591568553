figure {
  // Normalize adds `margin` to `figure`s as browsers apply it inconsistently.
  // We reset that to create a better flow in-page.
  margin: 0 0 1rem;
  position: relative;
  margin: 0 0 1.666rem;
  background-color: transparent;
  border: 0 solid sh-color("black-10");
}


figcaption {
  @include sh-font-size("xsmall");
  @include sh-line-height("xsmall");
  @include respect-inner-padding;
  color: sh-color("black-60");
  padding: to-rem(5) 0;
  text-align: left;
  line-height: 1.3;

  @include media-query("sm") {
    padding: to-rem(10) 0;
  }

}

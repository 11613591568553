@mixin ln-equipment-redesign($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .general-information-2-sections {
    width: 30em;
    max-width: fit-content;
  }

  .general-information-3-sections {
    width: 23em;
    max-width: fit-content;
  }

  .general-information-break-word {
    padding-left: 20px;
    word-break: break-word;
  }

  .general-information-srs-info {
    position: absolute;
    right: 2em;

    &--padding-left {
      padding-left: 2em;
    }
  }
}

@charset "UTF-8";

@font-face {
  font-family: "sh-icons-extended";
  src: url("fonts/sh-icons-extended.eot");
  src: url("fonts/sh-icons-extended.eot?#iefix") format("embedded-opentype"),
  url("fonts/sh-icons-extended.woff") format("woff"),
  url("fonts/sh-icons-extended.ttf") format("truetype"),
  url("fonts/sh-icons-extended.svg#sh-icons-extended") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "sh-icons-specific";
  src: url("fonts/specific/siag-lifenet.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "sh-spr-icons";
  src: url("fonts/spr/service-metrics-icons.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

[data-icon]::before {
  font-family: "sh-icons-extended", serif !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.equipment-icon {
  font-size: 30px !important;
}

.extended-icon::before {
  font-family: "sh-icons-extended", serif !important;
}

.icon-slider::before {
  content: "\e260";
}

.icon-search-toolbar::before {
  content: "\e80f";
}

.icon-product-area::before {
  content: "\53";
}

.icon-menu-1::before,
.icon-burger::before {
  content: "\e805";
}

.icon-down::before {
  content: "\5e";
}

.icon-arrow-up::before {
  content: "\e005";
}

.icon-line-graph::before {
  content: "\2a";
  font-family: "sh-spr-icons", serif !important;
}

.icon-bar-graph {
  display: inline-block;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.icon-bar-graph::before {
  content: "\62";
  font-family: "sh-spr-icons", serif !important;
}

.icon-number-graph::before {
  content: "\6e";
  font-family: "sh-spr-icons", serif !important;
}

.icon-donut-graph::before {
  content: "\71";
  font-family: "sh-spr-icons", serif !important;
}

.icon-drag-and-drop::before {
  content: "\64";
  font-family: "sh-spr-icons", serif !important;
}

.icon-duplicate-step::before {
  content: "\72";
  font-family: "sh-spr-icons", serif !important;
}

.icon-copy::before {
  content: "\4d";
}

.equipment-icon-overview {
  font-size: 50px !important;
}

.icon-department-extended::before {
  content: "\bf";
}

.icon-support-extended::before {
  content: "\be";
}

.icon-layout-list::before {
  content: "\c0";
  font-family: "sh-icons-extended", serif !important;
}

.icon-scanner-systems::before {
  content: "\c1";
  font-family: "sh-icons-extended", serif !important;
}

.icon-angiography::before {
  content: "\e4";
}

.icon-mr-scanner::before {
  content: "\e5";
}

.icon-ct-scanner::before {
  content: "\e6";
}

.icon-mammography::before {
  content: "\e7";
}

.icon-urology::before {
  content: "\e8";
}

.icon-syngo::before {
  content: "\e9";
}

.icon-radiography-ceiling::before {
  content: "\ea";
}

.icon-radiography-mobile::before {
  content: "\eb";
}

.icon-ultrasound::before {
  content: "\ec";
}

.icon-spect-symbia::before {
  content: "\ed";
}

.icon-pet-ct-biograph::before {
  content: "\ee";
}

.icon-globe::before {
  content: "\ef";
}

.icon-blood::before {
  content: "\f0";
}

.icon-mi-scanner::before {
  content: "\f1";
}

.icon-laboratory::before {
  content: "\f2";
}

.icon-molecular::before {
  content: "\f3";
}

.icon-blood-glucose::before {
  content: "\f4";
}

.icon-monitor::before {
  content: "\f5";
}

.icon-var-radiation-therapy::before {
  content: "\f6";
}

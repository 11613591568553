/// Component: Stage
/// @access public
/// @author Sebastian Liebner (sl@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Stage
/// sh-stage($options: (
///
/// ));
///
@mixin sh-stage($options: ()) {
  $_default: ();
  $_config: map-merge($_default, $options);
  $_component: ".#{$prefix}stage";

  #{$_component} {
    width: 100%;
    &-slide {
      @include sh-background-color("black");
      position: relative;
      display: flex;
      flex-direction: column;

      &__content {
        @include media-query("lg") {
          position: absolute;
          bottom: sh-grid("gutter-width") * 2;
        }

        width: 100%;
      }

      &__title {
        @include sh-color("orange");

        margin-top: 0;
        margin-bottom: 1rem;
      }

      &__description {
        @include sh-color("white");

        margin-top: 0;
        margin-bottom: to-rem(30);
      }

      &__pulse {
        margin: sh-grid("gutter-width") 0;
        width: 100%;

        @include media-query("lg") {
          margin: sh-grid("gutter-width") * 2 0;
        }
      }

      &__image-container {
        width: 100%;
      }

      &__image {
        background-size: cover;
        background-position: 50% 50%;
        flex: 0 0 100%;

        @include media-query("lg") {
          height: 460px;
          padding: 0;
          flex: 0 0 50vw; // half viewport

          > img {
            display: none;
          }
        }

        @include media-query("xl") {
          height: 600px;
          flex: 0 0 calc(50vw + 8.3333334%); // half viewport width + 1 col size
          overflow: hidden;
        }
      }

      &--covered {
        .#{$prefix}stage-slide__title {
          @include sh-font-weight("normal");
          font-size: to-rem(34);
          line-height: to-rem(38);

          @include media-query("sm") {
            font-size: to-rem(40);
            line-height: to-rem(45);
          }

          @include media-query("lg") {
            font-size: to-rem(50);
            line-height: to-rem(62);
          }
        }

        .#{$prefix}stage-slide__button {
          margin-bottom: to-rem(30);

          @include media-query("lg") {
            margin-bottom: 0;
          }
        }

        .#{$prefix}stage-slide__image {
          height: 100%;
          object-fit: cover;
          -webkit-mask-image: linear-gradient(
            to top,
            rgba(26, 26, 26, 0.0001) 0%,
            rgba(0, 0, 0, 0.0156863) 6.93%,
            rgba(0, 0, 0, 0.054902) 13.3%,
            rgba(0, 0, 0, 0.117647) 19.22%,
            rgba(0, 0, 0, 0.2) 24.78%,
            rgba(0, 0, 0, 0.290196) 30.06%,
            rgba(0, 0, 0, 0.392157) 35.16%,
            rgba(0, 0, 0, 0.501961) 40.17%,
            rgba(0, 0, 0, 0.607843) 45.18%,
            rgba(0, 0, 0, 0.709804) 50.28%,
            rgba(0, 0, 0, 0.8) 55.56%,
            rgba(0, 0, 0, 0.882353) 61.11%,
            rgba(0, 0, 0, 0.945098) 67.03%,
            rgba(0, 0, 0, 0.984314) 73.41%,
            #000000 80.34%
          );

          @include media-query("lg") {
            min-height: to-rem(500);
            max-height: to-rem(500);
            -webkit-mask-image: linear-gradient(
              to right,
              rgba(26, 26, 26, 0.0001) 0%,
              rgba(0, 0, 0, 0.0156863) 6.93%,
              rgba(0, 0, 0, 0.054902) 13.3%,
              rgba(0, 0, 0, 0.117647) 19.22%,
              rgba(0, 0, 0, 0.2) 24.78%,
              rgba(0, 0, 0, 0.290196) 30.06%,
              rgba(0, 0, 0, 0.392157) 35.16%,
              rgba(0, 0, 0, 0.501961) 40.17%,
              rgba(0, 0, 0, 0.607843) 45.18%,
              rgba(0, 0, 0, 0.709804) 50.28%,
              rgba(0, 0, 0, 0.8) 55.56%,
              rgba(0, 0, 0, 0.882353) 61.11%,
              rgba(0, 0, 0, 0.945098) 67.03%,
              rgba(0, 0, 0, 0.984314) 73.41%,
              #000000 80.34%
            );
          }
        }
      }

      &--white {
        @include sh-background-color("white");

        &.#{$prefix}stage-slide--covered .#{$prefix}stage-slide__image {
          -webkit-mask-image: linear-gradient(
            to top,
            rgba(255, 255, 255, 0.0001) 0%,
            rgba(255, 255, 255, 0.0156863) 6.93%,
            rgba(255, 255, 255, 0.054902) 13.3%,
            rgba(255, 255, 255, 0.117647) 19.22%,
            rgba(255, 255, 255, 0.2) 24.78%,
            rgba(255, 255, 255, 0.290196) 30.06%,
            rgba(255, 255, 255, 0.392157) 35.16%,
            rgba(255, 255, 255, 0.501961) 40.17%,
            rgba(255, 255, 255, 0.607843) 45.18%,
            rgba(255, 255, 255, 0.709804) 50.28%,
            rgba(255, 255, 255, 0.8) 55.56%,
            rgba(255, 255, 255, 0.882353) 61.11%,
            rgba(255, 255, 255, 0.945098) 67.03%,
            rgba(255, 255, 255, 0.984314) 73.41%,
            #ffffff 80.34%
          );

          @include media-query("lg") {
            min-height: to-rem(500);
            max-height: to-rem(500);
            -webkit-mask-image: linear-gradient(
              to right,
              rgba(255, 255, 255, 0.0001) 0%,
              rgba(255, 255, 255, 0.0156863) 6.93%,
              rgba(255, 255, 255, 0.054902) 13.3%,
              rgba(255, 255, 255, 0.117647) 19.22%,
              rgba(255, 255, 255, 0.2) 24.78%,
              rgba(255, 255, 255, 0.290196) 30.06%,
              rgba(255, 255, 255, 0.392157) 35.16%,
              rgba(255, 255, 255, 0.501961) 40.17%,
              rgba(255, 255, 255, 0.607843) 45.18%,
              rgba(255, 255, 255, 0.709804) 50.28%,
              rgba(255, 255, 255, 0.8) 55.56%,
              rgba(255, 255, 255, 0.882353) 61.11%,
              rgba(255, 255, 255, 0.945098) 67.03%,
              rgba(255, 255, 255, 0.984314) 73.41%,
              #ffffff 80.34%
            );
          }
        }

        .#{$prefix}stage-slide__description {
          @include sh-color("black");
        }
      }
    }

    @content;
  }
}

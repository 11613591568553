/// Component: Sitemap
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Sitemap
/// sh-sitemaps($options: (
///
/// ));
///
@mixin sh-sitemap($options: ()) {
  $_defaults: ();
  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}sitemap";

  #{$_component} {
    &__link {
      @include link-color;
      @include sh-color("black-60");
      font-size: sh-font-size("xsmall");
      line-height: 1.2857;
      padding: to-rem(9) 0;
      
      @include hover {
        @include sh-color("black-90");
      }

      i:before {
        color: inherit;
      }
    }

    &__title {
      @include sh-color("black-90");
      font-size: sh-font-size("small");
      margin: to-rem(27) 0 to-rem(20);
    }

    @content;
  }

  // Dark theme

  [class*="bg-color-gray-shade"],
  .bg-color-black,
  :host([theme="dark"]) {
    #{$_component} {
      &__link {
        @include sh-color("black-40");
        
        @include hover {
          @include sh-color("black-10");
        }
      }

      &__title {
        margin: to-rem(27) 0 to-rem(20);
        @include sh-color("black-10");
      }
    }
  }
}

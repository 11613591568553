@mixin ln-search-field($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .#{$prefix}search-field.search-field--taller {
    .search-field__autocomplete {
        max-height: 240px;
      }
  }

  .search-field {
    .icon-search {
      height: auto !important;
    }

    &__disabled {
      opacity: .6;
    }

    &--with-postfix {
      ::-webkit-search-decoration, ::-webkit-search-cancel-button {
        appearance: none;
      }
    }
  }
}

fieldset {
  // Browsers set a default `min-width: min-content;` on fieldsets,
  // unlike e.g. `<div>`s, which have `min-width: 0;` by default.
  // So we reset that to ensure fieldsets behave more like a standard block element.
  // See https://github.com/twbs/bootstrap/issues/12359
  // and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
  min-width: 0;
  // Reset the default outline behavior of fieldsets so they don"t affect page layout.
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  // Reset the entire legend element to match the `fieldset`
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
}

///
/// @access public
///
///
@mixin sh-u-text-decorations($options: ()) {
  $_defaults: ();

  $_config: map-merge($_defaults, $options);

  .text-decoration-none {
    text-decoration: none !important;
  }

  .text-decoration-underline {
    text-decoration: underline !important;
  }

  .text-decoration-line-through {
    text-decoration: line-through !important;
  }
}

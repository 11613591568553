/// Collection
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Collection
/// sh-aspect-ratio($options: (
///   gutter-width: 1rem
/// ));
///
@mixin sh-collection-item($options: ()) {
  $_defaults: (
    border-width: 1px,
    border-color: sh-color("black-10"),
    y-spacing: 1.66rem,
    x-spacing: 1rem,
  );

  $_config: map-merge($_defaults, $options);

  $_border-width: map-get($_config, "border-width");
  $_border-color: map-get($_config, "border-color");

  $_y-spacing: map-get($_config, "y-spacing");
  $_x-spacing: map-get($_config, "x-spacing");
  $_padding: $_y-spacing $_x-spacing;

  .#{$prefix}collection {
    &-item {
      @include respect-inner-padding;
      border-top: $_border-width solid $_border-color;
      border-bottom: $_border-width solid $_border-color;
      color: inherit;
      display: flex;
      justify-content: space-between;
      margin: 0 0 $_border-width * -1;
      padding: $_y-spacing 0;
      text-decoration: none;

      &[href] {
        @include sh-hover(("bgColor": "white"));
        @include sh-focus();
        padding-left: to-rem(20);
        padding-right: to-rem(20);
      }
    

      &__content {
        @include respect-inner-padding;
        flex: 1 1;
      }

      &__additional-content {
        flex: none;
        padding-left: $_x-spacing;
        display: flex;
        align-items: center;
      }

      &--full-bordered {
        border-left: $_border-width solid $_border-color;
        border-right: $_border-width solid $_border-color;
        padding: $_y-spacing $_x-spacing;
      }

      &--compact {
          padding: $_y-spacing*0.5 0;

        &.#{$prefix}collection-item--full-bordered {
          padding: $_y-spacing*0.5 $_x-spacing*0.5;
        }
      }
    }
  }

  // Dark theme
  .bg-color-black-90,
  .bg-color-black,
  :host([theme="dark"]) {
    .#{$prefix}collection {
      &-item {
        border-color: sh-color("black-70");
        
        &__content {
          color: sh-color("black-10");
        }

        &[href] {
          @include sh-hover(("bgColor": "black")
          );
        }
      }
    }
  }

  ::slotted(*:first-child) {
    margin-top: 0 !important;
  }
  ::slotted(*:last-child) {
    margin-bottom: 0 !important;
  }
}

@mixin ln-my-equipment-switch($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .my-equipment-switch {

    &__label {
      margin-bottom: 0;
      @include sh-color('black-60');
    }

    &__icon {
      @include sh-font-size('xlarge');
    }

    // when checkbox is checked, then the my equipment star icon should be orange
    &__checkbox:checked + &__label {
      @include sh-color('orange');
    }

    &__description {
      white-space: nowrap;
      @include sh-color('black-80');

      @include media-query('lg-down') {
        @include sh-font-size('small');
        @include sh-color('black-60');
      }
    }
  }
}

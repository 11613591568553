html {
  // Prevent font scaling in landscape while allowing user zoom
  -webkit-text-size-adjust: 100%;
  // Reset the box-sizing
  //
  // Change from `box-sizing: content-box` to `border-box` so that when you add
  // `padding` or `border`s to an element, the overall declared `width` does not
  // change. For example, `width: 100px;` will always be `100px` despite the
  // `border: 10px solid red;` and `padding: 20px;`.
  //
  // Heads up! This reset may cause conflicts with some third-party widgets. For
  // recommendations on resolving such conflicts, see
  // https://getbootstrap.com/getting-started/#third-box-sizing.
  //
  // Credit: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
  box-sizing: border-box;
  // We assume no initial pixel `font-size` for accessibility reasons. This
  // allows web visitors to customize their browser default font-size, making
  // your project more inclusive and accessible to everyone.

  // As a side-effect of setting the @viewport above,
  // IE11 & Edge make the scrollbar overlap the content and automatically hide itself when not in use.
  // Unfortunately, the auto-showing of the scrollbar is sometimes too sensitive,
  // thus making it hard to click on stuff near the right edge of the page.
  // So we add this style to force IE11 & Edge to use a "normal", non-overlapping, non-auto-hiding scrollbar.
  // See https://github.com/twbs/bootstrap/issues/18543
  // and https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/7165383/
  -ms-overflow-style: scrollbar;

  // Changes the default tap highlight to be completely transparent in iOS.
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  // 
  font-size: $sh-base-font-size;
}

ul, ol {
  margin-top: 0;
  margin-bottom: to-rem(30);
  font-family: inherit;
  list-style-position: outside;
}

li {
  margin-bottom: to-rem(10);
  margin-top: to-rem(10);
  padding: 0;
  position: relative;
}

dl {
  margin-top: 0;
  margin-bottom: to-rem(30);
}

dt {
  font-weight: normal;
}

dd {
  margin-bottom: 0.5rem; // @todo align with baseline grid
  margin-left: 0;
}

// no margin on nested lists
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

@mixin ln-spr-widget($options: ()) {

  $_default: ();
  $config: map-merge($_default, $options);

  .spr-service-label-supported > span {
    white-space: pre-line;
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight: bold;
    font-family: "SH Headline", "Arial Black", "Calibri", sans-serif;
  }

  .spr-widget {
    height: 100%;
    width: 100%;

    .diagram__total-amount {
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &__simulated {
      border: 2px solid sh-color('yellow-50');
    }

    &__simulated__with_calculated_data {
      border: 2px solid sh-color('green');
    }

    &__old_widget__with_calculated_data {
      border: 2px solid sh-color('black-75');
      background-color: sh-color('black-10');

      .aspect-ratio {
        background-color: sh-color('black-10');
      }
    }

    & .widget__body {
      height: 80%;
      width: 100%;
      min-height: 250px !important;

      & .diagram--double-axis {
        height: 100%;
        width: 100%;

        @include media-query('md-down') {
          height: 250px;
        }
      }
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      & .widget__title > .row {
        display: block;

        & > div {
          & svg {
            height: 30px;
          }

          &:nth-child(1) {
            display: block;
            width: calc(100% - 66px);

            & > a {
              display: inherit;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }

          &:nth-child(2) {
            display: block;
            position: absolute;
            right: 0.83rem;
            top: 17px;

            & > div {
              display: inline-block;
              vertical-align: top;
            }
          }
        }

        & .search-multi-selectbox__mobile-view-backround > div:first-child {
          margin-top: 6rem;
          display: block;
        }
      }

      &-title__selectbox {
        float: none !important;
        display: block !important;
        width: auto !important;
      }

      & .widget__body {
        min-height: 200px !important;
      }
    }

    .spr-widget_title_checkbox {
      position: relative;
      top: -20px;
      display: inline-block;
    }
  }

  .spr-box {
    padding: 5px;

    min-height: 360px !important;
    height: 100% !important;

    @media (max-width: 47.9444444444em) {
      min-height: 400px !important;
    }

    @media screen and (max-width: 47.9444444444em) and (pointer: fine){
      min-height: 620px !important;
    }

    &-mobile .diagram__total-amount {
      padding-top: 20px;
      line-height: 13rem;
    }
  }

  .spr-widget-title {
    display: inline-block;
    width: 100%;

    &--cursor-pointer:hover {
      cursor: pointer
    }
  }

  .dots-controls {
    position: absolute;
    bottom: 25px;
    right: 25px;
    z-index: sh-z-index('dialog');
  }

  .dropup {
    position: relative;
    display: inline-block;
  }

  .dropup-content {
    bottom: 40px;
    top: auto;
  }

  .spr-plus {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    @include sh-font-size('xsmall');
  }

  .filter-reset-column {
    margin-top: 1.7rem;

    @include media-query('xl-down') {
      margin-top: 5px;
      margin-bottom: 3px;
    }
  }

  .filter-reset-button {
    @include media-query('xl-down') {
      margin-top: 5px;
      margin-bottom: 3px;
    }
  }

  .spr-disclaimer {
    line-height: 1rem;
    margin-top: auto;
    @include sh-font-size('xsmall');

    &__filter {
      position: absolute;
      top: 60px;
      bottom: unset;
    }

    &__disclaimer-text {
      margin: 15px;
      display: inline-block;
      align-self: flex-end;
    }

    @include media-query('xl-down') {
      position: inherit;
      font-size: small;


      &__disclaimer-text{
        margin-top: 3px;
      }
    }
  }
}

@mixin ln-filter-panel($options: ()) {
  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  $spacing: 0.833rem;

  .filter-panel {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    overflow-y: auto;

    .filter-panel__toolbar .collection-item {
      display: flex;
      justify-content: space-between;
      margin: 0 0 -1px;
      border-bottom: 1px solid #e0e0e0;
      padding: 1.66rem 0;
      text-decoration: none;
      color: inherit
    }

    @include media-query('xl') {
      width: 33.333333%;

      &--half-width {
        width: 50%;
      }

      &__filters {
        display: flex;
        flex-direction: column;

        &-spr {
          height: 100%;
        }
      }

      &__advanced-filters {
        height: inherit;

        &-spr {
          display: flex;
          flex-direction: column;
        }
      }

    }

    &__form {
      .row > div:nth-child(2n) {
        .multi-selectbox__option-list {
          left: auto;
          right: 0;
        }
      }

      .button-group {
        margin-bottom: to-rem(30);
      }
    }

    &__saved-item {
      font-size: to-rem(16);
    }

    &__toolbar {
      display: none;
      box-shadow: 0 6px 0.4rem -4px rgba(27, 27, 27, 0.2);
      position: relative;
      z-index: 1;
      @include sh-background-color('black-7');
    }

    &__tabs {
      display: block;
      padding-top: 0.25rem;
    }

    &__list {
      z-index: 1;
      height: 100%;
      overflow-y: auto;
    }

    &__show-results-panel {
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      right: 0;
      @include sh-background-color('white');

      @include media-query('xl-down') {
        &-filters-index-level {
          z-index: 5;
        }
      }
    }

    @include media-query('xl-down') {
      &__filters {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        transform: translate3d(-100%, 0, 0);
        transition: transform 0.3s ease-in;
        @include sh-background-color('black-7');
        z-index: 5;
      }

      &__advanced-filters {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        overflow: auto;
        margin-bottom: 3.5rem;
      }

      &__scrollable-filters {
        overflow-y: auto;
      }
    }

    @include media-query('xl') {
      &__toolbar {
        display: block;
      }

      &__show-results-panel {
        display: none;
      }
    }

    &.is-expanded {
      @include media-query('xl-down') {
        .filter-panel__filters {
          transform: translate3d(0, 0, 0);
        }

        .filter-panel__show-results-panel {
          transform: translate3d(0, 0, 0);
        }

        .filter-panel__show-results-panel {
          box-shadow: 0 4px 0.4rem 6px rgba(27, 27, 27, 0.2);
        }
      }
    }

    &--equipment {
      .filter-panel__toolbar {
        display: block;
      }

      @include media-query('xl') {
        .filter-panel__filters {
          flex: 0 0 auto;
          overflow: hidden;
          max-height: 0;
          /* temporarily disabled animation due to not proper behaviour in Chrome browser (v75) */
          /* (bug 4523: Equipment list not filled correctly after using filter) */
          /* transition: max-height 0.88s ease-in; */
          @include sh-background-color('black-7');
        }
      }

      &.is-expanded {
        @include media-query('xl') {
          .filter-panel__filters {
            overflow: visible;
            max-height: 99em;
          }
        }
      }
    }

    &--tabless {
      .filter-panel__advanced-filters {
        padding-top: 0.91666rem;
      }
    }

    .textfield {
      margin-bottom: 0.8rem !important;
    }

    .selectbox {
      margin-bottom: 0.3rem !important;
    }
  }
}

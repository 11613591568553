@mixin ln-drag-and-drop-widgets($options: ()) {

  $_default: ();
  $config: map-merge($_default, $options);

  .direction-arrows {
    width: 22px;
    fill: #929292;
    cursor: move;
  }

  .slider-drag-drop {
    width: 30px;
    height: 30px;
    fill: #929292;
    cursor: move;
    transform: rotate(90deg);
    
    &.icon-slider-drag-drop::before {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .metrics-box {
    width: 100%;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1);
  }

  .spr-box-wrapper {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;
  }

  .spr-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  .spr-drop-placeholder {
    opacity: 0;
  }

  .equipment-drag-and-drop {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: right;
  }
}

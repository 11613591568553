@mixin ln-month-picker($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .month-picker {
    position: fixed;
    @include sh-shadow('modal');
    @include sh-background-color('white');
    @include sh-z-index('dropdown');
    @include sh-font-size('small');
    margin-top: to-rem(4);
    opacity: 0;
    visibility: hidden;
    user-select: none;
    touch-action: manipulation;

    &.is-open {
      opacity: 1;
      visibility: visible;
    }

    &__header {
      display: flex;
      align-items: center;
      text-align: center;

      &__current-year {
        flex-grow: 1;
        @include sh-font-family('headline-small');
        @include sh-font-size('medium');
        @include sh-font-weight('bold');
      }

      &__prev-year,
      &__next-year {
        @include sh-color('black-60');
        width: to-rem(50);
        padding: to-rem(10);
        font-size: 100%;

        &:hover {
          @include sh-color('black-90');
        }
      }
    }

    &__months {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(3, 1fr);
      padding: to-rem(5);
      gap: to-rem(10);
    }

    &__month {
      display: flex;
      align-items: center;
      justify-content: center;
      @include sh-color('black-90');
      @include sh-font-size('medium');
      width: to-rem(40);
      height: to-rem(40);
      border-radius: 50%;
      cursor: pointer;

      &.is-selected {
        @include sh-color('white');
        @include sh-background-color('orange-dark');
      }

      &.is-disabled {
        @include sh-color('black-30');
        cursor: inherit;
      }

      &:not(.is-selected):not(.is-disabled):hover {
        @include sh-hover-background-color('white');
      }
    }
  }
}

/// Component: Radio
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Radio
/// sh-radio($options: (
///
/// ));
///
@use "sass:math";

@mixin sh-radio($options: ()) {
  $_defaults: ();
  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}radio";

  // Font Sizes
  $font-size: 1rem !default;
  $active-font-size: $font-size * 0.8 !default;
  $active-top: -$font-size !default;

  // Margin & Paddings
  $gap: $font-size !default;
  $spacer: $font-size * 0.125 !default;
  $fieldset-margin: 0 0 $gap * 2 !default;
  $checkbox-gap: $font-size * 2 !default;
  $checkbox-top: 0.3rem !default;
  $radio-margin: math.div($font-size, -4) !default;

  // Colors
  $input-color: sh-color("black-90") !default;
  $border-color: sh-color("black-20") !default;
  $label-color: sh-color("black-20") !default;
  $active-color: sh-color("black-30") !default;
  $focus-color: sh-color("orange") !default;
  $error-color: sh-color("red") !default;

  $icon-font-family: sh-font-family("icon");

  #{$_component} {
    opacity: 0;
    width: to-rem(20);
    height: to-rem(20);
    position: absolute;
  

    & + label {
      display: inline-block;
      cursor: pointer;
      position: relative;
      width: auto;
      padding-left: to-rem(15);
      margin: 0 to-rem(20) 0 to-rem(20);
      user-select: none;
      @include sh-font-family("text");

      &::before {
        line-height: 1;
        content: "";
        display: inline-block;
        width: to-rem(20);
        height: to-rem(20);
        position: absolute;
        left: to-rem(-20);
        background-color: sh-color("white");
        border: 1px solid sh-color("black-60");
        top: $checkbox-top;
        border-radius: 50%;
      }
    }

    @include hover {
      & + label::before {
        @include sh-hover-background-color("white");
      }
    }

    &:checked + label::before {
      border: 6px solid sh-color("orange");
    }

    @include hover {
      &:checked + label::before {
        @include sh-background-color("white");
        border-color: sh-shift-darkness(sh-color("orange"));
      }
    }

    @include focus {
      & + label::before {
        @include sh-outline();
      }
    }

    &:disabled + label {
      @include sh-opacity("disabled");
      pointer-events: none;
      cursor: default;
    }

    &.is-invalid + label::before {
      border-color: sh-color("red");
    }


    // Dark theme
    :host([theme="dark"]) & {
      & + label::before {
        background-color: sh-color("black-90");
        border-color: sh-color("black-40");
      }

      @include hover {
        & + label::before {
          @include sh-hover-background-color("black-90");
        }
      }
      
      &:checked + label::before {
        border-color: sh-color("orange");
        background-color: sh-color("black-10");
      }  
      @include hover {
        &:checked + label::before {
          border-color: sh-shift-darkness(sh-color("orange"));
        }
      }

      @include focus {
        & + label::before {
          @include sh-outline();
        }
      }
      
      &.is-invalid + label:before {
        border-color: sh-color("red");
      }
    }

    @content;
  }
}

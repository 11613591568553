/*  
 Grid Layout
 @access public
 @author Robert John (rj@click-solutions.de)
 @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
 @example scss - Grid Layout Example
 ln-grid-layout($options: (
   breakpoints: ()
 ));
*/
@mixin ln-grid-layout($options: ()) {
  $_defaults: ();

  $_config: map-merge($_defaults, $options);

  .#{$prefix}grid-layout {
    display: grid;
    @include ln-only-ie() {
      /* IE does not format the grid properly */
      display: block;
    }
    grid-gap: 0 1rem;
    p {
      margin-bottom: 0;
    }
  }

  .#{$prefix}grid-layout-template-columns {
    &--2-col {
      grid-template-columns: 1fr 1fr;
    }
    &--2-col-lg {
      grid-template-columns: 1fr;
      @include media-query('lg') {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
  .#{$prefix}grid-layout__align {
    &--center {
      .#{$prefix}grid-layout__item {
        align-self: center;
      }
    }
  }
}

/// Block-grid System
/// @access public
/// @author Sebastian Liebner (sl@click-solutions.de)
/// @example scss - Block Grid
/// sh-block-grid($options: (
///   max-columns: 12,
///   media-queries: sh-breakpoint-list()
/// ));
///
@use "sass:math";

@mixin sh-block-grid($options: ()) {
  $_defaults: (
    max-columns: 12,
    media-queries: sh-breakpoint-list(),
    gutter-width: sh-grid("gutter-width"),
  );

  $_config: map-merge($_defaults, $options);

  $_media-queries: map-get($_config, "media-queries");
  $_max-columns: map-get($_config, "max-columns");
  $_gutter-width: map-get($_config, "gutter-width");

  .#{$prefix}block-grid {
    display: flex;
    flex-wrap: wrap;
    margin-left: math.div($_gutter-width, -2);
    margin-right: math.div($_gutter-width, -2);

    .#{$prefix}block-grid__item {
      display: flex;
      background-clip: padding-box;
      margin: 0;
      width: 100%;
      padding: 0 $_gutter-width*0.5;
      margin-bottom: $_gutter-width;

      & > * {
        margin: 0;
        flex: 0 0 100%;
      }
    }
  }

  @include _block-grid-amount("", $_max-columns);

  @each $_media-query in $_media-queries {
    @include media-query($_media-query) {
      @include _block-grid-amount($_media-query, $_max-columns);
    }
  }
}

@mixin _block-grid-amount($mq, $amount) {
  $infix: "";

  @if $mq != "" {
    $infix: "-#{$mq}";
  }

  @for $i from 1 to $amount + 1 {
    .#{$prefix}block-grid#{$infix}-#{$i} {
      .#{$prefix}block-grid__item {
        flex: 0 0 math.div(100%, $i);
        max-width: math.div(100%, $i);
      }
    }
  }
}

/// Component: Dropdown
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Dropdown
/// sh-textfield($options: (
///
/// ));
///
@mixin sh-dropdown($options: ()) {
  $_defaults: (
    "background-color": sh-color("white"),
    // "border-color": sh-color("white"),
    "min-width": 100%,
    "box-shadow": sh-shadow("modal"),
  );
  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}dropdown";

  #{$_component} {
    display: inline-block;
    position: relative;

    &__toggle-button {
      display: block;
      cursor: pointer;

      & [class^="icon"] {
        color: sh-color("black-60");
      }

      &:hover [class^="icon"] {
        @include sh-color("black-90");
      }
    }

    &__panel {
      display: none;
      position: absolute;
      z-index: sh-z-index("dropdown");
      margin: 0;
      top: 100%;
      left: 0;
      min-width: map-get($_config, "min-width");
      background-color: map-get($_config, "background-color");
      box-shadow: map-get($_config, "box-shadow");
      // border: 1px solid map-get($_config, "border-color"); // no border - shadow is enough ;-)
    }

    &.is-open {
      #{$_component}__toggle [class^="icon"] {
        @include sh-color("black-90");
      }

      #{$_component}__panel {
        display: block;
      }
    }

    @content;
  }

  #{$_component}--right {
    #{$_component}__panel {
      left: inherit;
      right: 0;
    }
  }
}

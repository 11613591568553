///
/// @access public
///
///
@mixin sh-u-background-images($options: ()) {
  $_defaults: ();

  $_config: map-merge($_defaults, $options);

  .has-background-image-sm-up {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% top;
  }

  .has-background-image,
  .has-background-image-md-up {
    background-size: 0%;
  }

  @include media-query("md") {
    .has-background-image,
    .has-background-image-md-up {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }
    .has-background-image > img,
    .has-background-image-md-up > img {
      display: none;
    }
  }
}

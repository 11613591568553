///
/// @access public
///
///
@mixin sh-u-padding($options: ()) {
  $_defaults: (
    directions: (
      "top",
      "right",
      "bottom",
      "left",
    ),
    max-items: 5,
  );

  $_config: map-merge($_defaults, $options);

  @include _padding-helper(
    (
      max-items: map-get($_config, "max-items"),
    )
  );

  @each $direction in map-get($_config, "directions") {
    @include _padding-helper(
      (
        direction: $direction,
        max-items: map-get($_config, "max-items"),
      )
    );
  }
}

@mixin _padding-helper($options) {
  $direction: map-get($options, "direction");
  $max-items: map-get($options, "max-items");

  $prefix: "";

  @if ($direction) {
    $prefix: "-#{$direction}";
  }

  .padding#{$prefix}-auto {
    padding#{$prefix}: auto !important;
  }

  @for $i from 0 to $max-items {
    .padding#{$prefix}-#{$i} {
      padding#{$prefix}: $i * 1.666666666rem !important;
    }
  }
}

@use "sass:math";

@mixin _aspect-ratio($options) {
  $_query: map-get($options, "media-query");

  @if $_query != "xs" {
    @if map-has-key($options, "media-query") {
      $_query: $_query + "-";
    }

    .#{$prefix}aspect-ratio {
      @each $_aspect in map-get($options, "ratios") {
        $_width: nth($_aspect, 1);
        $_height: nth($_aspect, 2);

        &-#{$_query}#{$_width}x#{$_height} {
          padding-bottom: math.div($_height, $_width) * 100%;
        }
      }
    }
  }
}

/// Aspect Ratio pattern for displaying items, usually an image, inside a container with predefined dimensions.
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Aspect Ratio
/// sh-aspect-ratio($options: (
///   gutter-width: 1rem
/// ));
///
@mixin sh-aspect-ratio($options: ()) {
  $_defaults: (
    bg-color: sh-color("black-7"),
    ratios: $sh-aspect-ratios,
    media-queries: $sh-breakpoints,
  );

  $_configuration: map-merge($_defaults, $options);

  [class*="#{$prefix}aspect-ratio"] {
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-bottom: 100%;
    background-color: map-get($_configuration, "bg-color");

    & > img,
    & > picture,
    & ::slotted(img),
    & ::slotted(picture) {
      position: absolute;
      margin: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  .#{$prefix}aspect-ratio {
    &--cover {
      @supports (object-fit: cover) {
        & > img,
        & > picture {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }

        & ::slotted(img),
        & ::slotted(picture) {
          object-fit: cover !important;
          height: 100% !important;
          width: 100% !important;
        }
      }

      @supports not (object-fit: cover) {
        background-size: cover;
        background-position: center center;

        & > img,
        & > picture,
        & ::slotted(img),
        & ::slotted(picture) {
          opacity: 0;
        }
      }
    }

    &--contain {
      @supports (object-fit: contain) {
        & > img,
        & > picture {
          object-fit: contain;
          height: 100%;
          width: 100%;
        }

        & ::slotted(img)
        & ::slotted(picture) {
          object-fit: contain !important;
          height: 100% !important;
          width: 100% !important;
        }
      }

      @supports not (object-fit: contain) {
        background-size: contain;
        background-position: center center;

        & > img,
        & > picture,
        & ::slotted(img),
        & ::slotted(picture) {
          opacity: 0;
        }
      }
    }
  }

  @include _aspect-ratio(
    (
      ratios: map-get($_configuration, "ratios"),
    )
  );

  @each $_media-query in map-keys(map-get($_configuration, "media-queries")) {
    @include media-query($_media-query) {
      @include _aspect-ratio(
        (
          ratios: map-get($_configuration, "ratios"),
          media-query: $_media-query,
        )
      );
    }
  }
}

@mixin ln-messaging-center($options: ()) {
    $_defaults: ();
    $config: map-merge($_defaults, $options);

    .messaging-center {

        position: relative;

        &__notification-label {
            position: absolute;
            right: 0;
            top: 0;
            transform: translate(-25%, -25%);
            @include sh-background-color('orange');
            @include sh-color('white');
        }

        &__panel {
            display: none;
            z-index: sh-z-index('dropdown');
            position: fixed;
            top: 53px;
            left: 0;
            bottom: 0;
            right: 0;
            min-width: 500px;
            max-width: 700px;
            padding: 0;
            background-color: sh-color('white');
            box-shadow: 2px 2px 10px rgba(sh-color('black-90'), 0.1);
            border: 1px solid sh-color('black-10');
            list-style: none;
            overflow-y: auto;

            @include media-query('lg') {
                position: absolute;
                margin-top: to-rem(6px);
                margin-left: 0;
                top: inherit;
                left: inherit;
                bottom: inherit;
                max-height: 400px;
            }
            @include media-query('md') {
                position: absolute;
                margin-top: to-rem(6px);
                margin-left: 0;
                top: inherit;
                left: -480px;
                right: inherit;
                bottom: inherit;
                max-height: 400px;
            }
            @include media-query('xs') {
                min-width: unset;
            }
        }

        &__header,
        &__group-header {
            border-left: 5px solid sh-color('white');
            padding: 0.61111111rem;
        }

        &__header {
            display: flex;
            justify-content: space-between;
        }

        &__group-header {
            @include sh-background-color('black-7');
            @include sh-border-color('black-7');
        }

        &__new-event-label,
        &__group-header {
            @include sh-font-size('large');
            @include sh-font-weight('bold');
        }

        &__item {
            .collection__content {
                display: block;
              max-width: 100%;
              .overflow-text {
                max-width: 90vw;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }

            &:hover {
                @include sh-background-color('black-7');
            }
        }

        &.is-open {
            .messaging-center__panel {
                display: block;
            }
        }
    }
}

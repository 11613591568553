/// Component: User Identifier
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - User Identifier
/// sh-textfield($options: (
///
/// ));
///
@mixin sh-user-identifier($options: ()) {
  $_defaults: ();
  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}user-identifier";

  #{$_component} {
    @include sh-color("black-90");
    display: flex;
    position: relative;

    &__toggle-button {
      @include sh-font-family("text");
      @include sh-font-size("xsmall");
      @include sh-color("black-60");
      display: flex;
      align-items: center;
      text-align: left;
      transition: color map-get(sh-transition('fade'), 'duration') map-get(sh-transition('fade'), 'easing');

      @include sh-state("focus");

      @include hover {
        //dev
        &,
        & ::before {
          @include sh-color("black-90");
        }
        //new
        .user-identifier__name {
          @include sh-color("black-90");
        }
      }
    }

    &__avatar {
      display: flex;
      align-items: center;
      font-size: 14px;
      @include sh-font-weight("bold");

      & img {
        width: to-rem(40);
        height: to-rem(40);
        border-radius: 99rem;
        margin-right: 0.25rem;
        margin-left: 0.25rem;
      }

      i {
        display: inherit;
        &::before {
          font-size: to-rem(22);
          @include sh-color("black-60");
        }
      }
    }

    &__initials {
      display: flex;
      border-radius: 99rem;
      width: to-rem(40);
      height: to-rem(40);
      @include sh-background-color("black-7");
      justify-content: center;
      align-items: center;
      overflow: hidden;
      margin-right: 0.25rem;
      margin-left: 0.25rem;
    }

    &__svg {
      margin-right: 0.25rem;
      margin-left: 0.25rem;
    }

    &__details {
      display: none;
      font-size: sh-font-size("small");
      line-height: to-rem(25);
      margin-left: 0.25rem;

      @include media-query("md") {
        display: block;
      }
    }

    &__name,
    &__company {
      display: block;
      font-size: sh-font-size("xsmall");
      line-height: to-rem(20);
    }

    &__name {
      @include sh-font-weight("bold");
    }

    @content;
  }
}

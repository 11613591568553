@mixin ln-spr-table($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);
  $_base-width: 175px;
  $_base-mobile-width: 100px;

  .spr-table {

    th, td {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2),
      -1px 0 2px 1px rgba(0, 0, 0, 0.14),
      0 0px 0px 0px rgba(0, 0, 0, 0.12);
      vertical-align: middle;
      min-width: 50px;
      max-width: $_base-width;
    }

    thead tr {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        position: fixed;
        background-color: white;
        z-index: sh-z-index('dropdown') + 1;
        top: 220px;
        align-content: baseline;
        overflow-x: hidden;
      }
      border: none;
      border-bottom: 2px solid sh-color('black-90');

      th {
        top: 0;
        position: sticky; /* works for Chrome,FF,Safari */
        background-color: sh-color('white');
        border: none;
        box-shadow: 0 0;
        font-size: 1.1rem;

        padding: 0;
        @extend .padding-right-tiny-1;
        @extend .padding-left-tiny-1;

        @include media-query('sm') {
          box-shadow: 0 2px 0 0 sh-color('black-90'), -4px -2px 0 0 sh-color('white');
          z-index: sh-z-index('dropdown') + 1;
        }
        @include media-query('md') {
          box-shadow: 0 2px 0 0 sh-color('black-90'), -4px -2px 0 0 sh-color('white');
          z-index: sh-z-index('dropdown') + 1;
        }
      }

      th::before {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        display: block;
      }
    }

    td {
      height: 56px;
    }

    & td:not(&__disabled-cell) {
      &:hover > * > div::before,
      &:hover > div > * > *::before {
        @include sh-color('orange');
      }

      &:hover > input:not(:focus):not(:disabled) {
        outline: 1px solid sh-color('orange-50');
      }

      & > input:focus {
        outline: 1px solid sh-color('orange');
      }
    }

    &__column-size-type {
      &-wide {
        min-width: $_base-width + 40px !important;

        @media only screen and (max-width: 1200px) {
          min-width: $_base-mobile-width + 40px !important;
        }
      }
    }

    &__text-input {
      padding-top: 0;
      padding-bottom: 0;
      margin: 0;
      vertical-align: middle;

      input {
        max-width: $_base-width;
        outline: none;
        border: none;
        background: transparent;
        font: inherit;
        padding: 0 3px;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          width: auto !important;
        }
      }
    }

    &__selectbox {
      .selectbox {
        margin: 0;
        background: inherit;
        border: none;
        width: 100%;
        min-width: $_base-width;

        select {
          padding: 0 to-em(27) 0 0;
        }

        &__option-list {
          top: unset;
          left: unset;
          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            min-width: 200px;
          }
          max-width: max-content;
          width: auto;
        }

        &__option {
          height: 49px;
        }
      }

      &__text-left .selectbox select {
        text-align-last: left;
      }

      &__text-right .selectbox select {
        text-align-last: right;
      }

      &__text-center .selectbox select {
        text-align-last: center;
      }
      
      &__left-padding .selectbox select {
        padding-left: 0.888rem;
      }

      &--unlimited .menu {
        max-height: unset;
      }

      &--original-top {
        .selectbox__option-list {
          top: 100%;
        }
      }
    }

    &__external-content-cell {
      max-width: 250px;
    }

    &__time {
      div {
        margin: 0;
        background: transparent;
        height: 100%;
        border: 0;
        padding: 0 to-em(27) 0 0;
        cursor: pointer;

        &::before {
          @include sh-color('black-60');
          font-family: "sh-icons-extended" !important;
          font-style: normal !important;
          font-weight: 400 !important;
          font-variant: normal !important;
          font-size: 0.6em;
          text-transform: none !important;
          speak: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          vertical-align: baseline;
          content: "\e81f";
          position: absolute;
          right: 12px;
          top: 50%;
          transform: translateY(-30%);
          pointer-events: none;
        }

        i {
          display: none !important;
        }

        input {
          padding: 0 !important;
          cursor: pointer;
        }
      }

      &__text-left div input {
        text-align-last: left;
      }

      &__text-right div input {
        text-align-last: right;
      }

      &__text-center div input {
        text-align-last: center;
      }
    }

    &__time-picker {
      @extend .padding-right-tiny-1;
      @extend .padding-left-tiny-1;

      .flatpickr-calendar {
        width: 12em;
        margin-top: 35px;
        top: auto !important;
        @include media-query('sm') {
          left: auto !important;
        }
      }
    }

    &__date-picker {
      padding-right: .1rem;
      padding-bottom: .1rem;

      .textfield__input {
        min-width: 183px;
      }

      .flatpickr-calendar {
        top: auto !important;
        left: 45px !important;
        @include media-query('sm') {
          left: auto !important;
        }
      }
    }


    &__disabled-cell {
      color: sh-color('black-75');

      & * {
        pointer-events: none;
      }

      span, input, select {
        opacity: 0.6;
      }
    }

    &__hidden-cell {
      div, span, input, select {
        visibility: hidden;
      }
    }

    &__empty-row {
      background-color: sh-color('black-5');
    }

    &__delete {
      width: 70px;
      text-align: center;

      i {
        cursor: pointer;
      }

      i:not(.icon-disabled):hover {
        color: sh-color('orange');
      }

      i.icon-disabled {
        opacity: 0.5;
      }
    }

    &__invalid-cell {
      border: 2px solid sh-color('red');
    }

    .multi-selectbox {
      &.dropup {
        .multi-selectbox__option-list {
          top: 56px;
          bottom: unset;
          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            min-height: 200px;
          }
          @include media-query('md-down') {
            z-index: sh-z-index('dropdown') + 2;
          }
        }
      }
    }

    @media only screen and (max-width: 1200px) {
      thead tr th {
        z-index: 2;
        box-shadow: 0 2px 0 0 sh-color('black-90'), -4px -2px 4px 1px sh-color('white');
      }

      td {
        min-width: 50px;
        max-width: $_base-mobile-width + 5px;
      }

      th {
        min-width: $_base-mobile-width;
        max-width: $_base-mobile-width + 5px;
      }

      .padding-left-tiny-5 {
        padding-left: 0.5rem !important;
      }
      .padding-right-tiny-5 {
        padding-right: 0.2rem !important;
      }

      &__delete {
        width: 50px !important;
      }
      &__selectbox {
        .selectbox {
          min-width: 105px;
          max-width: 108px;
        }
      }

      &__text-input {
        input {
          min-width: 90px;
          max-width: 105px;
        }
      }
    }

    @media only screen and (max-width: 47.94444444em) and (orientation: portrait) {
      .multi-selectbox {
        .multi-selectbox__option-list {
          top: 10rem !important;
          padding-bottom: 4rem;
        }
      }
    }
  }

  .selectbox_ultimate {
    position: absolute;
    z-index: 2000;
    min-width: 200px;
  }
}

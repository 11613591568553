@mixin ln-button($options: ()) {
  $_defaults: (
  );

  $config: map-merge($_defaults, $options);

  .button {
    margin-bottom: 1.25em;
  }

  .button-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .button-no-pointer-change {
    pointer-events: none;
  }

  .button-def-cursor {
    cursor: default;
  }

  .button-row > button {
    margin-right: .25em
  }

  .button-with-icon {
    &::before {
      position: relative;
      top: 2px;
    }
  }

  .hide-border {
    border: none;
  }

  .arrow-multi-selectbox-button {
    &:focus {
      @include sh-color('orange');
    }

    &:active {
      @include sh-color('orange');
    }
  }

  .button--external-link {
    @extend .button--link;
    font-weight: 400;
    font-size: inherit;

  }

  .button--link .icon-arrow-right-medium::before {
    margin-right: 0;
  }

  .button--dropdown-toggle {
    margin-bottom: 0;

    i:before {
      margin-left: .2rem;
      margin-right: 0;
    }
  }

  .button--link, .button--external-link {
    padding: 0;
    margin: 0;
    border: 0;
    min-width: 0;
    border-radius: 0;
    background: transparent;
    transition: none;
    cursor: pointer;

    &__underlined {
      border-bottom: 1px solid currentColor;
    }
  }

  .button--quick-link {
    font-family: inherit;
    font-size: inherit;
    color: #666;
  }

  .three-dots-icon-style {
    i:before {
      font-size: 1.3rem;
      font-weight: 700 !important;
    }
  }

  .equipment-header-three-dots {
    bottom: 25px;
    right: 25px;
    z-index: sh-z-index('dialog');
    padding-left: 10px;
  }

  .button-remove {
    border: 0;
    margin-bottom: 0;
    @include sh-background-color('black-10');
  }

  .button-add {
    border: 0;
    margin-bottom: 0;
  }

}

@mixin ln-label-status($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .label-status {
    @extend .label;
    border-radius: 0.3em;
    text-align: center;
    margin-bottom: -.1rem;
    margin-right: 0.5rem;
    background-color: #bfbfbf;
    @include sh-color('black');
    font-size: 0.7777rem;

    &-{
      @extend .label-status;
    }

    &:hover {
      cursor: pointer
    }

    &-no-cursor {
      cursor: auto !important;
    }

    &-orange {
      @extend .label-status;
      @include sh-background-color('orange');
    }

    &-green {
      @extend .label-status;
      @include sh-background-color('green-dark');
      @include sh-color('white');
    }

    &-yellow {
      @extend .label-status;
      @include sh-background-color('yellow');
    }

    &-red {
      @extend .label-status;
      @include sh-background-color('red-dark');
      @include sh-color('white');
    }

    &-white {
      @extend .label-status;
      @include sh-background-color('white');
    }

    &-cyan {
      @extend .label-status;
      @include sh-background-color('cyan');
    }

    &--status-gray {
      @extend .label-status;
    }

    &-gray {
      @extend .label-status;
    }

    &-black-7 {
      @extend .label-status;
      @include sh-background-color('black-7');
    }

    &-none {
      @extend .label-status;
      border-left: none;
      margin-left: 5px;
    }

    //PBI change status https://dev.azure.com/SHS-eBusiness/LifeNet/_workitems/edit/30290
    &--colorfully--status {
      &-red {
        @extend .label-status;
        @include sh-background-color('red-dark');
        @include sh-color('white');
      }

      &-orange {
        @extend .label-status;
        @include sh-background-color('orange');
      }

      &-yellow {
        @extend .label-status;
        @include sh-background-color('yellow');
      }

      &-green {
        @extend .label-status;
        @include sh-color('white');
        @include sh-background-color('green-50');
      }

      &-gray {
        @extend .label-status;
        @include sh-color('white');
        @include sh-background-color('green');
      }

      &-none {
        border-left: none;
        margin-left: 5px;
      }
    }
  }
}

/// Component: Selectbox
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Selectbox
/// sh-textfield($options: (
///
/// ));
///
@mixin sh-selectbox($options: ()) {
  $_defaults: (
    background-color: sh-color("white"),
    border-color: sh-color("black-10"),
    box-shadow: sh-shadow("navigation"),
    bottom-margin: to-rem(30),
    x-padding: to-rem(15),
    small-font-factor: 0.775,
    width: to-rem(220),
  );
  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}selectbox";

  :host {
    display: inline-block;
    width: map-get($_config, "width"); // configured width can be overridden by utility class
  }

  #{$_component} {
    @include sh-font-size("medium");
    @include sh-background-color("black-7");
    -webkit-tap-highlight-color: rgba(0 0 0 / 0%);
    // will-change: transform,opacity;
    display: inline-block;
    position: relative;
    height: to-rem(56);
    width: 100%;
    margin-bottom: map-get($_config, "bottom-margin");
    border-bottom: 1px solid sh-color("black-60");
    cursor: pointer;

    &::before {
      @include sh-color("black-60");
      font-family: sh-font-family("icon");
      font-style: normal !important;
      font-weight: 400 !important;
      font-variant: normal !important;
      font-size: 0.6em;
      text-transform: none !important;
      // speak: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      vertical-align: baseline;
      content: "\e81f";
      position: absolute;
      right: map-get($_config, "x-padding");
      top: 50%;
      transform: translateY(-30%);
      pointer-events: none;
    }

    &:hover::before {
      @include sh-color("black-90");
    }

    &__native {
      @include sh-font-size("medium");
      width: 100%;
      height: 100%;
      padding: to-rem(20) map-get($_config, "x-padding") 0;
      line-height: 1.2; // min 1.2 to prevent text cropping
      max-width: calc(100% - 3em);
      border: none;
      white-space: nowrap;
      visibility: hidden;
      appearance: none;
    }

    &__select {
      @include sh-font-size("medium");
      @include sh-color("black-90");
      @include sh-font-family("text");
      appearance: none;
      align-self: flex-end;
      width: 100%;
      height: 100%;
      padding: to-rem(20) map-get($_config, "x-padding") 0;
      line-height: 1.2; // min 1.2 to prevent text cropping
      border: none;
      background: transparent;
      pointer-events: none;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 3em);
      white-space: nowrap;
      // this part of the iOS fix is added by the components stylesheet due to shared use of this styles in other components
      // position: absolute;
      // inset: 0;
      // box-sizing: border-box;

      &::-ms-value {
        @include sh-color("black-90");
        background: none; /* remove blue background on ie10/ie11 when selected */
      }

      &::-ms-expand {
        display: none; // remove select arrow on IE
      }

      &:focus {
        outline: none;
      }

      &:-moz-focusring {
        // hack for FF
        color: transparent;
        text-shadow: 0 0 0 sh-color("black-90");
      }
      
      &:-moz-focusring * {
        // hack for FF
        color: sh-color("black-90");
        text-shadow: none;
      }
    }
    
    &.has-label &__select {
      padding: to-rem(27) map-get($_config, "x-padding") 0;
    }

    &__label {
      @include sh-font-size("medium");
      @include sh-color("black-60");
      @include sh-font-family("text");
      bottom: to-rem(16);
      cursor: text;
      font-weight: sh-font-weight("normal");
      left: map-get($_config, "x-padding");
      letter-spacing: 0.00937em;
      line-height: 1.15rem;
      margin-bottom: 0;
      overflow: hidden;
      pointer-events: none;
      position: absolute;
      right: auto;
      text-decoration: inherit;
      text-overflow: ellipsis;
      text-transform: inherit;
      transform-origin: left top;
      transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1), color 0.15s cubic-bezier(0.4, 0, 0.2, 1),
        width .15s cubic-bezier(0.4, 0, 0.2, 1);
      white-space: nowrap;
      width: calc(100% - to-rem(40)); // reserve space for down-arrow
      will-change: transform;
      -webkit-font-smoothing: antialiased;
    }

    &__hint {
      color: sh-color("black-60");
      display: block;
      margin-bottom: map-get($_config, "bottom-margin");

      @include sh-line-height("xsmall");
      padding: to-rem(7) map-get($_config, "x-padding") 0;
      font-size: #{map-get($_config, "small-font-factor")}rem;
    }

    &__option-list {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: sh-z-index("dropdown");
      min-width: 100%;
      overflow: hidden;
      overflow-y: auto;
      max-height: to-rem(300);
      background-color: map-get($_config, "background-color");
      box-shadow: map-get($_config, "box-shadow");
      margin-top: 1px;
      border: 1px solid map-get($_config, "border-color");
    }

    &--large {
      width: 100%;
    }

    &.is-open {
      #{$_component}__option-list {
        display: block;
      }
    }

    &.is-disabled {
      @include sh-opacity("disabled");
    }

    &.is-active {
      #{$_component}__label {
        transform: translateY(-40%) scale(map-get($_config, "small-font-factor"));
        width: 100%;
      }
    }

    &.is-valid {
      border-color: sh-color("green");

      #{$_component}__label {
        width: calc(100% - to-rem(60));
      }

      &.is-active {
        #{$_component}__label {
          width: calc(100% - to-rem(60));
        }
      }

      #{$_component}__select {
        padding-right: to-rem(60); // reserve some space for the indicator
      }

      // #{$_component}__hint {
      //   @include sh-color("green");
      // }

      &::after {
        @include sh-indicator(
          (
            "icon": "check",
            "bgColor": "green",
          )
        ) {
          content: "\e818";
        };
        position: absolute;
        left: auto;
        right: to-rem(30);
        bottom: to-rem(12);
        cursor: pointer;
      }
    }

    &.is-invalid {
      border-color: sh-color("red");

      #{$_component}__label {
        width: calc(100% - to-rem(60));
      }

      &.is-active {
        #{$_component}__label {
          width: calc(100% - to-rem(60));
        }
      }

      #{$_component}__select {
        padding-right: to-rem(60); // reserve some space for the indicator
        padding-left: map-get($_config, "x-padding");
      }

      // #{$_component}__hint {
      //   @include sh-color("red");
      // }

      &::after {
        @include sh-indicator(
          (
            "icon": "close",
            "bgColor": "red",
          )
        ) {
          content: "\e817";
        };
        position: absolute;
        left: auto;
        right: to-rem(30);
        bottom: to-rem(12);
        cursor: pointer;
      }
    }

    &:focus,
    &:focus-within,
    &.has-focus {
      @include sh-border-color("black-90");
      outline: none;
      
      &::before {
        @include sh-color("black-90");
      }
    }


    // Gray theme

    .bg-color-black-7 &,
    :host([theme="gray"]) & {
      @include sh-background-color("white");
    }

    // Dark theme

    [class*="bg-color-gray-shade"] &,
    .bg-color-black &,
    :host([theme="dark"]) & {
      @include sh-background-color("black-90");

      &::before {
        @include sh-color("black-40");
      }
      
      &:hover::before {
        @include sh-color("black-10");
      }

      &__option-list {
        @include sh-background-color("black-80");
        @include sh-color("black-10");
        border-color: sh-color("black-75");
      }

      &__label {
        @include sh-color("black-20");
      }

      &__select {
        @include sh-color("black-10");
      }

      &__hint {
        color: sh-color("black-40");
      }

      &__option {
        @include sh-color("black-10");
        background: none;

        &:hover {
          @include sh-hover-background-color("black-80");
        }

        &.is-selected {
          @include sh-background-color("black-60");
        }
      }

      &.is-valid {
        border-color: sh-color("green");
      }

      &.is-invalid {
        border-color: sh-color("red");
      }

      &.has-focus {        
        &::before {
          @include sh-color("black-10");
        }
      
        @include sh-border-color("black-10");
      }
    }
    
    @content;
  }
}

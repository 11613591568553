/// Component: Progress
/// @access public
/// @author Sebastian Liebner (sl@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Progress
/// sh-progress($options: (
///   color: sh-color("orange")
/// ));
///
@mixin sh-progress($options: ()) {
  $_default: (
    "color": sh-color("orange"),
  );
  $_config: map-merge($_default, $options);
  $_component: ".#{$prefix}progress";

  $_color: map-get($_config, "color");

  #{$_component} {
    @include sh-font-family("text");

    &__status {
      @include sh-font-weight("bold");
      @include sh-font-size("large");
      @include sh-color("black-90");
      @include sh-line-height("large");
      width: 100%;
      text-align: center;
      letter-spacing: 0;
      line-height: to-rem(20px);
      margin-bottom: to-rem(10px);
    }

    &__bar-container {
      width: 100%;
      background-color: sh-color("black-10");
      border-radius: 5px;
    }

    &__bar {
      border-radius: 5px 0 0 5px;
      background-color: $_color;
      transition: width map-get(sh-transition("base"), "duration") linear;
      text-align: right;
      padding-right: 0.5em;
      color: inherit;
      white-space: nowrap;
      overflow: hidden;
      height: 10px;
    }

    &__additional-text {
      @include sh-font-size("xsmall");
      @include sh-color("black-60");
      @include sh-line-height("xsmall");
      letter-spacing: 0;
      margin-top: to-rem(10px);
    }

    &--tiny {
      font-size: 2px;
      color: $_color;
    }

    @content;
  }
}

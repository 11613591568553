@mixin ln-collection($options: ()) {
  
  $_defaults: ();
  $_config: map-merge($_defaults, $options);
  
  .collection {
    &--report {
      
      @include sh-background-color('white');
      
    }

    .collection-item {
      &--my-institutions {
        padding-left: 0.833rem;
        padding-right: 0.833rem;
      }
      
      &--no-border {
        border: none;
      }
    }

    .collection-item > *:first-child,
    .collection__content > *:first-child {
      margin-top: 0
    }

    .collection__content {
      flex: 1 1
    }

    .collection__content > *:last-child {
      margin-bottom: 0;
    }

    .collection__additional-content {
      flex: none;
      padding-left: 1rem;
      display: flex;
      align-items: center
    }

    &--compact .collection-item {
      padding: .83rem 0;

      &--padding-sides {
        padding-right: 0.61111111rem;
        padding-left: 0.61111111rem;
      }
    }
    
    &--reverse {

      .collection-item {
        flex-direction: row-reverse;
      }

      .collection__additional-content {
        padding-left: 0;
        padding-right: 1rem;
      }
    }
  }

  .collection > *:last-child {
    margin: 0; 
  }
}

@mixin ln-varian($options: ()) {
  
  $_default: ();
  $config: map-merge($_default, $options);

  .varian-widget__value {
    color: sh-color('petrol');
    font-style: normal;
    font-weight: 700;
    font-size: 100px;
    line-height: 72px;
    margin-top: 60px;
    margin-bottom: 50px;
  }

  .varian-widget__value--small {
    font-size: 48px;
    font-weight: 700;
  }

  .varian-datepicker {
    .textfield {
      background-color: #FFFFFF !important;
    }
  }

  .varian-legend-labels {
    list-style-type: none;
    padding: 0;
    margin-bottom: 15px;
  }

  .varian-label span {
    float: left;
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  .varian-calendar-label {
    display: block;
    margin-bottom: 5px;
    color: sh-color('white');
    width: 60%;
  }

  .varian-table {
    tr:last-child { 
      font-weight: bold;
    }
  }

  .varian-circle {
    flex: 1;
    margin: 1%;
    border-radius: 50%;
    background: sh-color('white');
    margin: 12px;

    &::before {
      content: "";
      display: inline-block;
      padding-top: 100%;
    }
  }

  .varian-circle-txt {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .varian-circle-txt-number {
    font-size: 50px;
    line-height: 48px;
    font-weight: bold;
  }

  .varian-duration-number {
    font-size: 24px;
    font-weight: bold;
  }
}

@mixin ln-u-border($options: ()) {
  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .border-top-0 {
    border-top: none;
  }

  .border-top-1 {
    border-top: 1px solid sh-color('black-10');
  }
}

///
/// @access public
///
///
@mixin sh-u-image-sizes($options: ()) {

  $_defaults: (

  );

  $_config: map-merge($_defaults, $options);

  // Use these image size helper with the media object
  // .image-size-small
  $_image-small-size: ( 
    "xs": 76px,
    "sm": 76px,
    "md": 76px,
    "lg": 76px,
    "xl": 76px);
  // .image-size-medium
  // @use: Quotation
  $_image-medium-size: ( 
    "xs": 140px,
    "sm": 140px,
    "md": 110px,
    "lg": 110px,
    "xl": 140px);
  // .image-size-large
  $_image-large-size: ( 
    "xs": 180px,
    "sm": 180px,
    "md": 180px,
    "lg": 180px,
    "xl": 180px);
  // .image-size-expand
  $_image-expand-size: ( 
    "xs": 100%,
    "sm": 100%,
    "md": 100%,
    "lg": 100%,
    "xl": 100%);

  @include _image-size("small", $_image-small-size);
  @include _image-size("medium", $_image-medium-size);
  @include _image-size("large", $_image-large-size);
  @include _image-size("expand", $_image-expand-size);
}

@mixin _image-size($variant, $sizes) {
  @each $mq in map-keys($sh-breakpoints) {
    @include media-query($mq) {
      .image-size-#{$variant} {
        width: map-get($sizes, $mq);
      }
    }  
  }
}

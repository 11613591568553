@use 'sass:math';

@mixin ln-list-item($options: ()) {
  $_defaults: (
    padding: math.div(sh-grid('gutter-width'), 2)
  );
  $_config: map-merge($_defaults, $options);

  $_padding: map-get($_config, 'padding');

  .list-item {

    padding: to-rem(16) to-rem(11);
    cursor: pointer;

    &--no-pointer {
      cursor: default;
    }

    &--actionless {
      cursor: auto;
    }

    &.is-active {
      @include sh-background-color('black-7');
    }

    &__title {
      margin-bottom: 0.5rem;
      @include sh-font-weight('bold');

      &--break-word {
        word-wrap: break-word;
      }

      &--break-all {
        word-break: break-all;
      }

      .label {
        margin-left: .4rem;
      }
    }

    &__subtitle {
      @include sh-font-size('small');
      @include sh-color('black-60');
      margin-bottom: 0;
    }

    &__image {
      margin-right: to-rem(16);
      min-width: 115px;
    }

    &__placeholder {
      background-image: url(../../images/list-item-placeholder.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    &__toolbar {
      align-items: flex-start !important;

      .button--control {
        margin-left: math.div($_padding, 2);
      }

      &__toolbar_alignment-center {
        align-items: center !important;
      }

      &__equipment_star_alignment {
        margin-top: -.5rem;
      }
    }


    &__star {
      @include sh-font-size('xlarge');
      padding-left: .4rem;
    }

    &--status-green {
      border-left-color: sh-color('green');
    }

    &--status-yellow {
      border-left-color: sh-color('yellow');
    }

    &--status-red {
      border-left-color: sh-color('red');
    }

    &--status-white {
      border-left-color: sh-color('white');
    }

    &--status-cyan {
      border-left-color: rgb(58, 191, 237);
    }

    &--status-none {
      border-left: none;
      margin-left: 5px;
    }

    &--cursor-default {
      cursor: default !important;
    }

    &--equipment {
      border-left-width: 7px;
      border-right: 1px solid sh-color('black-7');
    }

    &--customer-impersonation {
      border-left-color: sh-color('white');
      border-right: 1px solid sh-color('black-7');
    }
  }
}

@mixin ln-multi-column($options: ()) {
  $_defaults: (
    item-spacing-bottom: 0
  );

  $config: map-merge($_defaults, $options);

  .multi-column {
    @include media-query('md') {
      &--3col {
        column-count: 2;
      }
    }
    @include media-query('lg') {
      &--3col {
        column-count: 3;
      }
    }
  }
}

@mixin ln-bare-list($options: ()) {
  
  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .bare-list {
    &--top-aligned {
      .info-list__item {
        vertical-align: top;
      }
    }

    .dropdown__panel {
      .is-active {
        @include sh-background-color('black-7');
      }
    }

    .#{$prefix}dropdown--right {

      .#{$prefix}dropdown__panel {
        left: inherit;
        right: -60px;
      }
    }
  }
}

///
/// @access public
///
///
@mixin sh-u-font-families($options: ()) {

  $_defaults: (

  );

  $_config: map-merge($_defaults, $options);
  
  .font-family-headline {
    font-family: sh-font-family("headline") !important;
  }
  
  .font-family-headline-small {
    font-family: sh-font-family("headline-small") !important;
  }
  
  .font-family-text {
    font-family: sh-font-family("text") !important;
  }
}
.slider-container {
  .slide,
  .slide-text {
    display: none;
    margin: 20px 0;
    &.active {
      display: block;
    }
  }
  .slide-badges {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    position: relative;
    .badge {
      width: 10px;
      height: 10px;
      background: #686868;
      border-radius: 50%;
      margin: 0 5px;
      display: inline-block;
      &.active {
        background: #A84405;
      }
    }
  }
}

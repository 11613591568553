@mixin ln-main-container($options: ()) {
    $_defaults: ();
    $config: map-merge($_defaults, $options);

    .main-container {
        position: relative;
        top: 105px;
        height: calc(100vh - 105px);
        @include sh-background-color('black-5');
        overflow-x: hidden;
        display: block; /* Needed hack for IE 10 */

        @include media-query('md') {
            top: 134.72px;
            height: calc(100vh - 134.72px);
            overflow-x: inherit;
        }

        .welcome &,
        .not-authenticated &, .page-not-found &,
        .not-authorized &,
        :not(.hl-authorized) .overview &,
        :not(.hl-authorized) .support &,
        :not(.hl-authorized) .faq & {
          top: 0;
          @include sh-background-color('white');
        }


        .welcome &,
        .not-authenticated &,
        .page-not-found &,
        .not-authorized &,
        .dashboard &,
        .overview &,
        :not(.hl-authorized) .support &,
        :not(.hl-authorized) .faq & {
          height: inherit;
          position: fixed;
          left: 0;
          right: 0;
          bottom: 0;
          overflow-y: auto
        }

        .administration & {
          background-color: sh-color('white');
        }
    }
}

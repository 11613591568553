///
///
///
@mixin sh-application-bar($options: ()) {
  
  $_default: ();
  $_config: map-merge($_default, $options);
  $_component: ".#{$prefix}application-bar";

  #{$_component} {
    @include sh-background-color("white");
    border-bottom: 1px solid sh-color("black-7");

    %application-bar__column {
      flex: 0 0 auto;
    }

    &__container {
      display: flex;
      align-items: center;
      margin: 0 auto;
      max-width: 100%;
      padding-right: 0.833rem;
      padding-left: 0.833rem;

      @include media-query("sm") {
        width: 30em;
      }

      @include media-query("md") {
        width: 40em;
      }

      @include media-query("lg") {
        width: 53.333em;
      }

      @include media-query("xl") {
        width: 80em;
      }
    }

    &__logo {
      @extend %application-bar__column;
      padding: to-rem(15) to-rem(30) to-rem(15) 0;
      margin: 0;

      @include media-query("md") {
        padding: to-rem(24) to-rem(30) to-rem(24) 0;
      }

      @include media-query("lg") {
        padding-right: to-rem(50);
      }
    }

    &__app-identifier {
      @extend %application-bar__column;
      @include sh-font-family("headline");
      @include sh-font-size("small");
      @include sh-color("black-90");
      margin-right: auto; // for flexbox left alignment
      padding-right: to-rem(30);
      display: none;
      flex: 0 1 auto;
      text-overflow: ellipsis;
      overflow: hidden;
      text-decoration: none;
      @include sh-focus;

      @include media-query("md") {
        display: block;
      }
    }

    &__additional-functions {
      @extend %application-bar__column;
      margin-left: auto; // justify self end
      flex: 0 0 auto;
    }

    &__meta-menu {
      min-width: 0; // why?
    }

    &__user-identifier {
      @extend %application-bar__column;
      display: inline-flex;
      flex: 0 0 auto;
      margin-left: 0.55555rem;
    }

    &--compact {
      @include media-query("md") {
        #{$_component}__logo {
          padding: to-rem(19.5px) to-rem(30) to-rem(19.5px) 0; // logo padding box should be 64px height
        }
      }
    }
    @content;
  }
}

@mixin ln-info-list($options: ()) {
  
  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .info-list {

    font-size: sh-font-size('xsmall');
  
    &__item {
      
      padding-right: 0.5rem;
      margin-top: 0;
      margin-bottom: 0;
      display: inline-block;
      
      > i {
        font-size: sh-font-size('medium');
        color: sh-color('black-60');
      }
      i::before{
        font-size: 1.2em;
        position: relative;
        top: 2px;
        line-height: 1px;
      }
      
      &:last-child {
        padding-right: 0;
      }
    }

    &--medium {
      .info-list__item {
        padding-right: 2rem;
      }
    }
  
    &--large {
      .info-list__item {
        padding-right: 3rem;
      }
    }
  }
}

@mixin ln-overview-list($options: ()) {

    $_defaults: ();
    $_config: map-merge($_defaults, $options);
    
    .overview-list {

        &__toolbar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 0.5rem;
            width: 100%;
        }

        &--equipment {

            .overview-list__toolbar {
                padding: 0.5rem 0.833rem;
            }

            .overview-list__items {
                @include sh-background-color('white');
            }
        }
    }
}  

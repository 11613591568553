@mixin ln-textfield__hint($options: ()) {

  $_default: ();
  $config: map-merge($_default, $options);

  .textfield__hint--validation {
    color: #e7001d;
    margin-top: -0.888rem;
  }
  
  .checkbox .textfield__hint--validation {
    margin-top: 0px;
  }
}

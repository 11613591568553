@mixin ln-additional-text($options: ()) {
    
    $_defaults: ();
    $config: map-merge($_defaults, $options);
  
    .additional-text {
        margin-bottom: 0;
        @include sh-color('black-60');
        @include sh-font-size('xsmall');
    }
}  

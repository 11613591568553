h1,
.h1 {
  @include sh-font-family("headline");
  @include sh-font-weight("bold");
  font-size: to-rem(30); // 30px
  line-height: to-rem(35); // 35px      \
  margin-top: to-rem(75); // 75px        |––– 150px in total fits the baseline grid
  margin-bottom: to-rem(40); // 40px    /

  @include media-query("md") {
    font-size: to-rem(34); // 34px
    line-height: to-rem(40); // 40px      \
    margin-top: to-rem(70); // 70px        |––– 150px in total fits the baseline grid
    margin-bottom: to-rem(40); // 40px    /
  }

  @include media-query("xl") {
    font-size: to-rem(40); // 40px
    line-height: to-rem(45); // 45px      \
    margin-top: to-rem(95); // 95px        |––– 180px in total fits the baseline grid
    margin-bottom: to-rem(40); // 40px    /
  }
}

h2,
.h2 {
  @include sh-font-family("headline");
  @include sh-font-weight("bold");
  font-size: to-rem(28); // 28px
  line-height: to-rem(35); // 35px      \
  margin-top: to-rem(56); // 56px        |––– 120px in total fits the baseline grid
  margin-bottom: to-rem(29); // 29px    /

  @include media-query("md") {
    font-size: to-rem(31); // 31px
    line-height: to-rem(35); // 35px      \
    margin-top: to-rem(55); // 55px        |––– 120px in total fits the baseline grid
    margin-bottom: to-rem(30); // 30px    /
  }

  @include media-query("xl") {
    font-size: to-rem(35); // 35px
    line-height: to-rem(40); // 40px      \
    margin-top: to-rem(52); // 52px        |––– 120px in total fits the baseline grid
    margin-bottom: to-rem(28); // 28px    /
  }
}

h3,
.h3 {
  @include sh-font-family("headline");
  @include sh-font-weight("bold");
  font-size: to-rem(26); // 26px
  line-height: to-rem(30); // 30px      \
  margin-top: to-rem(59); // 59px        |––– 120px in total fits the baseline grid
  margin-bottom: to-rem(31); // 31px    /

  @include media-query("md") {
    font-size: to-rem(28); // 28px
    line-height: to-rem(30); // 30px      \
    margin-top: to-rem(59); // 59px        |––– 120px in total fits the baseline grid
    margin-bottom: to-rem(31); // 31px    /
  }

  @include media-query("xl") {
    font-size: to-rem(31); // 31px
    line-height: to-rem(35); // 35px      \
    margin-top: to-rem(55); // 55px        |––– 120px in total fits the baseline grid
    margin-bottom: to-rem(30); // 30px    /
  }
}

h4,
.h4 {
  @include sh-font-family("headline");
  @include sh-font-weight("bold");
  font-size: 1.33333rem; // 24px
  line-height: to-rem(30); // 30px      \
  margin-top: to-rem(42); // 42px        |––– 90px in total fits the baseline grid
  margin-bottom: to-rem(18); // 18px    /

  @include media-query("md") {
    font-size: to-rem(25); // 25px
    line-height: to-rem(30); // 30px      \
    margin-top: to-rem(42); // 42px        |––– 90px in total fits the baseline grid
    margin-bottom: to-rem(18); // 18px    /
  }

  @include media-query("xl") {
    font-size: to-rem(27); // 27px
    line-height: to-rem(30); // 30px      \
    margin-top: to-rem(42); // 42px        |––– 90px in total fits the baseline grid
    margin-bottom: to-rem(18); // 18px    /
  }
}

h5,
.h5 {
  @include sh-font-family("text");
  @include sh-font-weight("bold");
  font-size: 1.11111rem; // 20px
  line-height: to-rem(25); // 25px      \
  margin-top: to-rem(30); // 30px        |––– 60px in total fits the baseline grid
  margin-bottom: to-rem(5); // 5px    /

  @include media-query("md") {
    font-size: to-rem(20); // 20px
    line-height: to-rem(25); // 25px      \
    margin-top: to-rem(30); // 30px        |––– 60px in total fits the baseline grid
    margin-bottom: to-rem(5); // 5px    /
  }

  @include media-query("xl") {
    font-size: to-rem(21); // 21px
    line-height: to-rem(25); // 25px      \
    margin-top: to-rem(30); // 30px        |––– 60px in total fits the baseline grid
    margin-bottom: to-rem(5); // 5px    /
  }
}

h6,
.h6 {
  @include sh-font-family("text");
  @include sh-font-weight("bold");
  font-size: to-rem(18); // 18px
  line-height: to-rem(25); // 25px      \
  margin-top: to-rem(32); // 32px        |––– 60px in total fits the baseline grid
  margin-bottom: to-rem(3); // 3px    /

  @include media-query("md") {
    font-size: to-rem(18); // 18px
    line-height: to-rem(25); // 25px      \
    margin-top: to-rem(32); // 32px        |––– 60px in total fits the baseline grid
    margin-bottom: to-rem(5); // 3px      /
  }

  @include media-query("xl") {
    font-size: to-rem(18); // 18px
    line-height: to-rem(25); // 25px      \
    margin-top: to-rem(32); // 32px        |––– 60px in total fits the baseline grid
    margin-bottom: to-rem(3); // 3px    /
  }
}

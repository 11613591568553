@mixin ln-aspect-ratio($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .aspect-ratio {
    &--card-img-background {
      @include sh-background-color('black-20');
    }

    &--cover-unset-width {
      @supports (object-fit: cover) {
        & > img {
          width: unset;
        }
      }
    }
  }

}

@mixin ln-selectbox($options: ()) {
    
  $_defaults: ();
  $_config: map-merge($_defaults, $options);
  
  .selectbox {
    min-width: 100%;
    height: 56px;
    @include sh-background-color("black-5");
    
    @-moz-document url-prefix() {
      display: inline-block;
    }

    &::before {
      font-family: "sh-icons-extended" !important;
    }

    &.is-disabled {
      pointer-events: none;
      user-select: none;
    }

    .option-disabled {
      cursor: default;
      opacity: 0.6;
      user-select: none;
    }

    .bg-color-black-7 & {
      @include sh-background-color("white"); 
    }
  }

  .selectbox__option {
    overflow: hidden;
  }

  .selectbox__option:empty {
    height: 49px;
  }

  .selectbox__select-option {
    pointer-events: none;
    display: none
  }

  .selectbox-grouped {

    &__list {
      list-style: none;
      margin-left: 0.8885rem;
      margin-bottom: 0;
      padding-left: 0;

      &--first {
        margin-left: 0;
      }
    }

    &__optgroup, &__option {
      margin: 0;
      padding: 0.666rem 0.8885rem;
      text-overflow: ellipsis;
      display: block;
      white-space: nowrap;
      text-align: left;
      overflow: hidden;
    }

    &__optgroup {
      cursor: default;
    }

    &__option {
      &:hover {
        background-color: sh-color('black-5');
        color: inherit;
      }
    }
  }

  .selectbox-at-top {
    @include media-query('md-down') {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      top: 50px;
      transform: translate3d(0, 0, 0);
      z-index: 5;
    }
  }
}

@mixin ln-loading-animation($options: ()) {
  $_defaults: (
    "size": 2.222222rem
  );
  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}loading-animation";

  $_size: map-get($_config, "size");

  #{$_component} {
    display: block;
    position: relative;
    font-size: $_size;
    min-height: 1.5em;
    min-width: 1.5em;
    text-align: center;
    top: 50%;
    left: 50%;
    margin-top: -.125em;
    margin-left: -.6em;

    &--small {
      font-size: 1rem;
    }

    &--export {
      min-height: 1em;
      height: 20px;
      width: 20px;
      margin-right: 3.8em;

      &--no-label > button > span {
        margin-right: 1.8em;
        margin-top: -2.5em;
      }
    }
  }

  .equipment-system-information-srs-status-spinner > div {
    position: inherit;
    font-size: 0;
    height: 30px;
    width: 30px;
  }

  .equipment-general-information-srs-status-spinner > div {
    position: inherit;
    font-size: 0;
    height: 30px;
    width: 30px;
    margin-left: 7rem;
  }

  .spinner-top-none > div {
    top: auto;
  }

  .spinner-margin-top > div {
    margin-top: .875em;
  }

  .spinner-margin-top-auto > div {
    margin-top: auto;
  }

  .ln-loading-animation {
    &--start-page {
      position: fixed;
      top: 50%;
      left: 50%;
      @include sh-z-index('content-raised');
    }
  }
}

/// Component: Date Time Picker
/// @access public
/// @author Sebastian Liebner (sl@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Disclaimer
/// sh-datetimepicker($options: (
///   
/// ));
///
@mixin sh-date-time-picker($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}date-time-picker";


  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

  #{$_component} {
    &[data-inline="true"] {
      height: auto;
      border: 0;
      width: auto;

      #{$_component}__input,
      #{$_component}__label {
        display: none;
      }
    }

    @content;
  }
  
  .flatpickr-calendar {
    @include sh-shadow("modal");
    @include sh-background-color("white"); // need background color here for the popup calendar
    border: 0;
    direction: ltr;
    display: none;
    font-size: sh-font-size("small");
    margin-top: 2px;
    opacity: 0;
    outline: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    touch-action: manipulation;
    visibility: hidden;
    width: to-rem(350);
    z-index: 0; // generate new stacking context to make pseudo element background circle visible on selected day

    &.open,
    &.inline {
      opacity: 1;
      max-height: 640px;
      visibility: visible;
    }
      
    &.open {
      display: inline-block;
      z-index: sh-z-index("dropdown");
    }

    &.inline {
        display: block;
        position: relative;
        box-shadow: none;
        border: 1px solid sh-color("black-10");
        margin-top: 0;
    }

    &.arrowBottom {
      margin-top: -10px;
    }

    &.multiMonth {
      .flatpickr-days {
        .dayContainer:nth-child(n+1) {
          .flatpickr-day.inRange:nth-child(7n+7) {
              box-shadow: none !important;
          }
        }
      }
    }

    .flatpickr-months {
      display: flex;
  
      & .flatpickr-month {
        flex: 1;
        position: relative;
  
        & .flatpickr-current-month {
          font-family: sh-font-family("headline-small");
          font-size: sh-font-size("medium");
          font-weight: sh-font-weight("bold");
          
          height: 2.778em;
          padding: 0.556em 0.278em;
          // border-bottom: 1px solid sh-color("black-10");
  
          & .numInputWrapper {
            display: inline-block;
  
            & .numInput {
              pointer-events: none;
              width: 2.5em;
              font-family: sh-font-family("headline-small");
              font-size: sh-font-size("medium");
              font-weight: sh-font-weight("bold");
              line-height: inherit;
              border: 0;
              padding: 0;
              margin: 0;
            }
  
            & .arrowUp,
            & .arrowDown {
              display: none;
            }
          }
        }
      }
  
      & .flatpickr-prev-month,
      & .flatpickr-next-month {
        position: absolute;
        width: to-rem(50);
        padding: to-rem(10);
        cursor: pointer;
        z-index: sh-z-index("dropdown") + 1;
        color: sh-color("black-60");

        &:hover {
          color: sh-color("black-90");
        }
      }
  
      & .flatpickr-next-month {
        right: 0;
      }
    }
      
  
    .flatpickr-weekdays {
      font-size: sh-font-size("medium");
      color: sh-color("black-60");
      text-align: center;
      overflow: hidden;
      width: 100%;
      display: flex;
      align-items: center;
  
      & .flatpickr-weekdaycontainer {
        display: flex;
        flex: 1;
  
        & .flatpickr-weekday {
          cursor: default;
          margin: 0;
          text-align: center;
          display: block;
          flex: 1;
        }
      }
    }
    
    .flatpickr-days {
      font-size: sh-font-size("medium");
      @include sh-color("black-90");
  
      & .dayContainer {
        display: flex;    
        flex-wrap: wrap;
        justify-content: space-around;
  
        & .flatpickr-day {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: to-rem(50);
          height: to-rem(50);
          flex-basis: 14.286%;        
          border-radius: 50%;
          // padding: to-rem(10);
          position: relative;
  
          &::before {
            content: " ";
            display: block;
            position: absolute;
            width: to-rem(40);
            height: to-rem(40);
            z-index: -1;
            top: 50%;
            margin-top: to-rem(-20);
            left: 50%;
            margin-left: to-rem(-20);
            border-radius: 50%;
          }
          
          &.today {
            color: sh-color("orange-dark");
          }
          
          &.prevMonthDay,
          &.nextMonthDay {
            @include sh-color("black-40");
          }

          &:hover {
            &::before {
              @include sh-hover-background-color("white");
            }
          }
  
          &.selected {
            @include sh-color("white");
            
            &::before {
              @include sh-background-color("orange-dark");
            }
          }
  
          &.disabled {
            @include sh-color("black-30");
            cursor: inherit;
  
            &:hover {
              &:before {
                display: none;
              }
            }
          }
          
          &.startRange,
          &.endRange,
          &.inRange {
            &::after {
              content: " ";
              @include sh-background-color("black-10");
              display: block;
              position: absolute;
              width: 1.25 * to-rem(42);
              height: to-rem(40);
              z-index: -2;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
            }
          }
  
          &.startRange {
            &:not(.selected) {
              @include sh-color("white");

              &::before {
                content: " ";
                display: block;
                position: absolute;
                width: to-rem(40);
                height: to-rem(40);
                z-index: -1;
                // top: 50%;
                // left: 50%;
                // transform: translate(-50%, -50%);
                border-radius: 50%;
                @include sh-background-color("orange-dark");
              }
            }
  
            &::after {
              width: to-rem(26);
              left: 50%;
            }
          }
  
          &.endRange {
            &:not(.selected) {
              @include sh-color("white");

              &::before {
                content: " ";
                display: block;
                position: absolute;
                width: to-rem(40);
                height: to-rem(40);
                z-index: -1;
                // top: 50%;
                // left: 50%;
                // transform: translate(-50%, -50%);
                border-radius: 50%;
                @include sh-background-color("orange-dark");
              }
            }
  
            &::after {
              width: to-rem(25);
              left: 0;
            }
          }
        }
      }
    }

    .flatpickr-day {
      &:not(.inRange):not(.startRange) + .flatpickr-day.endRange,
      &.startRange.endRange {
        &::after {
          display: none;
        }
      }
    }

    &.showTimeInput .flatpickr-time {
      display: flex;
    }

    & .flatpickr-time {
      display: none;
      justify-content: center;
      padding: to-rem(50) 0;

      & .flatpickr-time-separator {
        display: flex;
        justify-content: center;
        align-items: center;
        width: to-rem(30);
        height: to-rem(30);
        flex-basis: to-rem(30);
        text-align: left;
      }

      & .flatpickr-am-pm {
        font-family: sh-font-family("text");
        @include sh-state("hover", ("bgColor": "white"));
        width: to-rem(30);
        height: to-rem(30);
        line-height: to-rem(31);
        flex-basis: to-rem(30);
        cursor: pointer;
        text-align: center;
        vertical-align: middle;
        margin-left: to-rem(30);
      }
      
      & .numInputWrapper {
        display: flex;
        width: to-rem(30);
        height: to-rem(30);
        flex-basis: to-rem(30);
        position: relative;

        & .numInput {
          @include sh-background-color("white");
          @include sh-color("black-90");
          font-family: sh-font-family("headline-small");
          font-size: sh-font-size("medium");
          font-weight: sh-font-weight("semibold");
          line-height: inherit;
          border: 0;
          padding: 0;
          margin: 0;
          width: 100%;
          height: 100%;
          text-align: center;
          vertical-align: middle;
        }

        & .arrowUp,
        & .arrowDown {
          @include sh-color("black-60");
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          transform: translateY(-100%);
          cursor: pointer;

          &::before {
            font-family: sh-font-family("icon") !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-variant: normal !important;
            font-size: 0.8em;
            text-transform: none !important;
            // speak: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            // vertical-align: baseline;
            content: "\e81d";
            display: block;
          }

          &:hover {
            @include sh-color("black-90");
          }
        }

        & .arrowDown {
          transform: translateY(100%);
          &::before {
            content: "\e81f";
          }
        }
      }
    }
  }
}

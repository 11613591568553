@mixin ln-evaluation($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);


  .evaluation {
    border-radius: 0.3em;
    text-align: center;
    @include sh-color('white');
    width: 6em;

    &--red {
      @include sh-background-color('red');
    }

    &--orange {
      @include sh-color('black');
      @include sh-background-color('orange');
    }

    &--yellow {
      @include sh-background-color('yellow');
      @include sh-color('black');
    }

    &--green {
      @include sh-background-color('green-50');
    }

    &--gray {
      @include sh-background-color('green'); // this on purpose
    }
  }
}

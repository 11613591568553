@mixin ln-calendar($options: ()) {
  $_defaults: ();
  $config: map-merge($_defaults, $options);
  
  .calendar-table {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    /* grid-template-rows: 1fr; */
    &__day {
      @include sh-color('black-40');
      
      &--events {
        @include sh-color('black');
      }

      &--disabled {
        @include sh-color('black-10');
      }

      &--clickable {
        cursor: pointer;
      }

      &-number {
        height: 40px;
        width: 40px;
        line-height: 37px;
        align-items: center;
        justify-items: center;
        text-align: center;
        &.is-active {
          font-weight: 600;
          border: 1px solid sh-color('black-40');
          border-radius: 100%;
        }
      }
    }

    &__content {
      align-items: center;
      justify-items: center;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 80%;
      display: grid;
      &.is-active {
        
      }
    }

    &__weekday, &__day {
      position: relative;
      width: 100%;
      &:before {
        content: "";
        height: 0;
        display: inline-block;
        padding-top: 100%;
        width: 1px;
        position: relative;
      }
    }
  }
}

table {
  // No longer part of Normalize since v4
  border-collapse: collapse;
  // Reset for nesting within parents with `background-color`.
  background-color: transparent;
  margin-bottom: 1.75rem;
  border-collapse: collapse;
  border: 0 solid sh-color("white");
  width: auto;
  min-width: 100%;
  table-layout: auto;

  tr {
    position: inherit;
    border-bottom: 1px solid sh-color("black-30");

    th,
    td {
      padding: to-rem(10) to-rem(15);
      text-align: left;
      vertical-align: top;
    }
  }

  thead {
    tr {
      th,
      td {
        @include sh-font-family("text");
        @include sh-font-weight("bold");
        font-size: to-rem(18); // 18px
        // line-height: to-rem(25); // 25px   
      }
    }
  } 
}

caption {
  @include sh-color("black-30");
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  text-align: left;
  caption-side: bottom;
}

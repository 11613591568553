///
/// @access public
///
///
@mixin sh-u-font-weights($options: ()) {
  $_defaults: ();

  $_config: map-merge($_defaults, $options);

  .font-weight-light {
    font-weight: sh-font-weight("light") !important;
  }

  .font-weight-normal {
    font-weight: sh-font-weight("normal") !important;
  }

  .font-weight-bold {
    font-weight: sh-font-weight("bold") !important;
  }
}

@mixin ln-overlay($options: ()) {

  $_defaults: (
      lib-prefix: ''
  );

  $_config: map-merge($_defaults, $options);

  $_lib-prefix: map-get($_config, 'lib-prefix');

  .#{$_lib-prefix}overlay {
      display: none;
      z-index: sh-z-index('dialog');
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      outline: 0;
      background-color: rgba(sh-color('black-90'), 0.5);

      overflow-x: hidden;
      overflow-y: auto;

      &.is-open {
          display: block;
      }

      &__container {
          position: relative;
          @include sh-background-color('white');
          @include border-radius();
          @include sh-shadow('modal-dark');

          display: flex;
          flex-direction: column;
          height: 100%;

          @include media-query('md') {
              height: inherit;
              max-width: 600px;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
          }
      }

      &__close {
          position: absolute;
          right: 0.6rem;
          top: 0.3rem;
          @include sh-font-size('large');
          z-index: sh-z-index('dialog') + 1;

          i::before {
              @include sh-color('black-60');
              margin: 0;
          }
          &:hover {
              i::before {
                  @include sh-color('black-90');
              }
          }
      }

      &__body {
          padding: 1rem 1.5rem;
          @include respect-inner-padding();
      }
  }

  @content
}


@mixin ln-detail-overlay($options: ()) {
    
  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .detail-overlay {

    display: block;
    pointer-events: none;
    background-color: rgba(0,0,0,0);
    transition: background-color 0.3s ease-in-out;
    
    &__container {
      position: absolute;
      min-width: 100vw;
      height: 100%;
      top: auto;
      left: auto;
      bottom: 0;
      right: 0;
      transform: translate3d(100%, 0, 0);
      box-shadow: 0 0 1rem rgba(27, 27, 27, 0);
      transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
      @include media-query('lg') {
        min-width: 50vw;
      }

      &.wide-view{
        @include media-query('lg') {
          min-width: 60vw;
        }
      }
    }
    
    &__body {
      position: relative;
      height: 100%;
      padding: 0;

      .scrollable-content {
        overflow: hidden;
        overflow-y: auto;
        padding: 0 1rem 2rem;
        top: 40px;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;

        &--with-pagination {
          bottom: 87px;
        }
      }
    }
  
    &.is-open {
  
      pointer-events: inherit;
      background-color: rgba(27, 27, 27, 0.5);
  
      .detail-overlay__container {
        transform: translate3d(0, 0, 0);
        box-shadow: 0 0 1rem rgba(27, 27, 27, 0.5);
      }
    }
  }    
}

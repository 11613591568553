/// Component: Alert
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Alert
/// sh-alert($options: (
///
/// ));
///
@mixin sh-alert($options: ()) {
  $_defaults: (
    "padding": to-rem(20),
  );

  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}alert";

  $_show-transition: sh-transition("show");
  $_hide-transition: sh-transition("hide");
  $_padding: map-get($_config, "padding");
  $_show-duration: map-get($_show-transition, "duration");
  $_show-easing: map-get($_show-transition, "easing");
  $_hide-duration: map-get($_hide-transition, "duration");
  $_hide-easing: map-get($_hide-transition, "easing");
  $_translation-x: 4rem;
  $_translation-y: 0.8rem;
  $_starting-position: 0;

  #{$_component} {
    @include sh-background-color("black-7");
    padding: to-rem(15);
    flex-direction: column;
    transition: all $_show-duration $_show-easing;
    overflow: visible;
    min-width: 1px;
    width: auto;
    margin: to-rem(10) to-rem(5);
    @include sh-color("black-90");
  

    // dark theme
    :host(.bg-color-black) &,
    :host(.bg-color-black-90) &,
    :host([theme="dark"]) & {
      @include sh-background-color("black-90");
      @include sh-color("black-10");

      &__close {
        i::before {
          @include sh-color("black-40");
          margin: 0;
        }
        &:hover {
          i::before {
            @include sh-color("black-30");
          }
        }
      }
    }

     // gray theme
    :host(.bg-color-black-7) &,
    :host(.bg-color-black-10) &,
    :host([theme="gray"]) & {
       @include sh-background-color("white");

       &__overlay {
        @include sh-background-color("black-7");
       }
     }

    &__border {
      border-left: to-rem(4) solid;
      @include sh-border-color("black-30");

      &--info {
        @include sh-border-color("black-30");
      }

      &--success {
        @include sh-border-color("green");
      }

      &--warning {
        @include sh-border-color("yellow");
      }

      &--error {
        @include sh-border-color("red");
      }
    }

    &__close {
      @include sh-font-size("xlarge");
      float: right; // prevents text overlapping
      margin-bottom: $_padding;
      margin-left: auto;
      min-width: auto;
      height: fit-content;
      line-height: 1;
      text-align: end;
      i::before {
        @include sh-color("black-60");
        margin: 0;
      }
      &:hover {
        i::before {
          @include sh-color("black-70");
        }
      }
    }

    &__header {
      display: flex;
      width: 100%;
    }

    &__body {
      align-items:flex-start
      & > *:first-child {
        margin-top: 0;
      }

      & > *:last-child {
        margin-bottom: 0;
      }
    }

    &__container {
      padding: 0;
      padding-right: to-rem(18.75);
    }

    &__title {
      @include sh-font-size("large");
      margin-top: 0;
      margin-bottom: 0;
    }

    &__indicator {
      display: none;
      margin-top: -0.15rem; // vertical alignment to the title
    }
    &__indicator-container {
      margin: 0 to-rem(10) 0 0;
    }

    &--info {
      #{$_component}__indicator {
        display: inline-block;
        &::after {
          @include sh-indicator(
            (
              "icon": "info",
              "bgColor": "black",
            )
          ) {
            content: "\e835";
          }
        }

        // dark theme
        :host(.bg-color-black) &,
        :host(.bg-color-black-90) &,
        :host([theme="dark"]) &,
        &.dark {
          &::after {
            @include sh-indicator(
              (
                "icon": "info",
                "bgColor": "white",
              )
            ) {
              content: "\e835";
            }
          }
        }
      }
      
    }

    &--success {
      #{$_component}__indicator {
        display: inline-block;
        &::after {
          @include sh-indicator(
            (
              "icon": "check",
              "bgColor": "green",
            )
          ) {
            content: "\e818";
          }
        }
      }
    }

    &--warning {
      .#{$prefix}alert__indicator {
        display: inline-block;

        &::after {
          @include sh-indicator(
            (
              "icon": "warning",
              "bgColor": "yellow",
            )
          ) {
            content: "\e836";
          }
          color: sh-color("black");
        }
      }
    }

    &--error {
      .#{$prefix}alert__indicator {
        display: inline-block;

        &::after {
          @include sh-indicator(
            (
              "icon": "close",
              "bgColor": "red",
            )
          ) {
            content: "\e817";
          }
        }
      }
    }

    &__overlay {
      @include sh-shadow("modal");
      @include sh-background-color("white");
      display: flex;
      height: fit-content;
      max-width: to-rem(375);
      z-index: sh-z-index("dialog");
      position: fixed;
      outline: 0;

      @include media-query('sm') {
        margin: to-rem(20);
        width: to-rem(375);
      }

      &--in-top-left {
        top: $_starting-position;
        left: $_starting-position;
        display: block;
        animation: fadeInTopLeft $_show-duration $_show-easing;

        @keyframes fadeInTopLeft {
          0% {
            left: -$_translation-x;
            opacity: 0;
          }
          50% {
            opacity: 1;
          }
          100% {
            left: $_starting-position;
          }
        }
      }

      &--in-top-right {
        top: $_starting-position;
        right: $_starting-position;
        display: block;
        animation: fadeInTopRight $_show-duration $_show-easing;

        @keyframes fadeInTopRight {
          0% {
            right: -$_translation-x;
            opacity: 0;
          }
          50% {
            opacity: 1;
          }
          100% {
            right: $_starting-position;
          }
        }
      }

      &--in-bottom-left {
        bottom: $_starting-position;
        left: $_starting-position;
        display: block;
        animation: fadeInBottomLeft $_show-duration $_show-easing;

        @keyframes fadeInBottomLeft {
          0% {
            left: -$_translation-x;
            opacity: 0;
          }
          50% {
            opacity: 1;
          }
          100% {
            left: $_starting-position;
          }
        }
      }

      &--in-bottom-right {
        bottom: $_starting-position;
        right: $_starting-position;
        display: block;
        animation: fadeInBottomRight $_show-duration $_show-easing;

        @keyframes fadeInBottomRight {
          0% {
            right: -$_translation-x;
            opacity: 0;
          }
          50% {
            opacity: 1;
          }
          100% {
            right: $_starting-position;
          }
        }
      }

      &--out-top-left {
        left: $_starting-position;
        animation: fadeOutTopLeft $_hide-duration $_hide-easing;

        @keyframes fadeOutTopLeft {
          0% {
            top: $_starting-position;
            opacity: 1;
          }
          100% {
            top: $_translation-y;
            opacity: 0;
          }
        }
      }

      &--out-top-right {
        right: $_starting-position;
        animation: fadeOutTopRight $_hide-duration $_hide-easing;

        @keyframes fadeOutTopRight {
          0% {
            top: $_starting-position;
            opacity: 1;
          }
          100% {
            top: $_translation-y;
            opacity: 0;
          }
        }
      }

      &--out-bottom-left {
        left: $_starting-position;
        animation: fadeOutBottomLeft $_hide-duration $_hide-easing;

        @keyframes fadeOutBottomLeft {
          0% {
            bottom: $_starting-position;
            opacity: 1;
          }
          100% {
            bottom: -$_translation-y;
            opacity: 0;
          }
        }
      }

      &--out-bottom-right {
        right: $_starting-position;

        animation: fadeOutBottomRight $_hide-duration $_hide-easing;

        @keyframes fadeOutBottomRight {
          0% {
            bottom: $_starting-position;
            opacity: 1;
          }
          100% {
            bottom: -$_translation-y;
            opacity: 0;
          }
        }
      }
    }

    &:not(.is-open) {
      opacity: 0;
      margin-bottom: -5rem;
      pointer-events: none;
      transform: translateY($_translation-y);
      transition: all $_hide-duration $_hide-easing;
    }

    &.is-open {
      
    }

    &.is-hidden {
      display: none;
    }

    @content;
  }
}

/// Component: Disclaimer
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Disclaimer
/// sh-disclaimer($options: (
///
/// ));
///
@mixin sh-disclaimer($options: ()) {
  $_defaults: (
    "color": sh-color("black-60"),
    "font-size": sh-font-size("xsmall"),
    "line-height": to-rem(20px),
    "padding-left": to-rem(20px),
  );
  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}disclaimer";

  $_color: map-get($_config, "color");
  $_font-size: map-get($_config, "font-size");
  $_line-height: map-get($_config, "line-height");
  $_padding-left: map-get($_config, "padding-left");

  #{$_component} {
    @include sh-font-family("text");
    font-size: $_font-size;
    color: $_color;
    line-height: $_line-height;
    letter-spacing: 0.3;
    position: relative;

    &__numbering + &__text {
      padding-left: $_padding-left;
    }

    &__numbering {
      font-size: $_font-size;
      position: absolute;
      top: 0;
      left: 0;
    }

    &__text {
      display: block;
    }

    @content;
  }
}

button {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0; // setting to "initial" applies wide buttons in IE11
  border-radius: 0;
  background: transparent;
  color: inherit;
  line-height: inherit;
  transition: none;
  cursor: pointer;

  @include focus() {
    outline: none;
  }

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

/// Component: Card Slider
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Card Slider
/// sh-card-slider($options: (
///
/// ));
///
@use "sass:math";

@mixin sh-card-slider($options: ()) {
  $_defaults: (
    "narrow-breakpoint": "lg",
    "shadow-width": 1rem,
    "duration": map-get(sh-transition("slide"), "duration"),
  );

  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}card-slider";

  $_narrow-breakpoint: map-get($_config, "narrow-breakpoint");
  $_shadow-width: map-get($_config, "shadow-width");
  $_duration: map-get($_config, "duration");
  $_gutter-width: sh-grid("gutter-width");
  $_easing: map-get(sh-transition("fade"), "easing");

  // item spacing
  #{$_component} {
    margin-left: math.div($_gutter-width, -2);

    &__item {
      margin: 0 $_gutter-width * 0.5 0.1rem;

      &.card {
        display: flex; // slick sets it to display block
      }
    }

    .slick-slide,
    .glide__slide {
      height: auto;

      &:focus {
        outline: none;
      }
    }

    // hide arrows responsively
    // [1] use important to override slick inline styles

    .slick-arrow,
    .glide__arrow {
      display: none !important; // [1]

      @include media-query($_narrow-breakpoint) {
        display: block !important; // [1]
      }
    }

    // shadow edges
    .slick-list,
    .glide__track {
      overflow: visible;
    }

    @include media-query($_narrow-breakpoint) {
      .slick-list,
      .glide__track {
        overflow: hidden;
        margin: -1em 0;
        padding: 1em 0;

        &::before {
          display: block;
          content: "";
          position: absolute;
          width: 2em;
          left: -2em;
          top: 0;
          bottom: 0;
          box-shadow: 6px 0px 12px -6px rgba(0, 0, 0, 0.2);
          background: sh-color("white");
          z-index: 1;
          transition: opacity $_duration $_easing;
          // transition-delay: $_duration; // only needed for slick.js
          opacity: 0;

          @at-root .card-slider.is-exceeding-left .slick-list::before {
            opacity: 1;
            transition-delay: 0s;
          }
          @at-root .card-slider.is-exceeding-left .glide__track::before {
            opacity: 1;
            transition-delay: 0s;
          }
        }

        &::after {
          display: block;
          content: "";
          position: absolute;
          width: 2em;
          right: -2em;
          top: 0;
          bottom: 0;
          box-shadow: -6px 0px 12px -6px rgba(0, 0, 0, 0.2);
          background: sh-color("white");
          transition: opacity $_duration $_easing;
          // transition-delay: $_duration; // only needed for slick.js
          opacity: 0;

          @at-root .card-slider.is-exceeding-right .slick-list::after {
            opacity: 1;
            transition-delay: 0s;
          }
          @at-root .card-slider.is-exceeding-right .glide__track::after {
            opacity: 1;
            transition-delay: 0s;
          }
        }
      }
    }
    
    @content;
  }
}

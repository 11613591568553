@mixin ln-card-slider($options: ()) {
  $_defaults: ();
  $config: map-merge($_defaults, $options);

  .card-slider-container {
    max-width: 100%;
    padding: 0;
  }

  .card-slider {

    .slick-track {
      display: flex;
      margin-left: 0;
    }

    .slick-list {
      overflow: hidden;
      margin: -1em 0;
      padding: 1em 0;
    }

    .slick-list, .slick-slider {
      position: relative;
      display: block;
      user-select: none;
      -webkit-user-select: none;
    }

    .no-shadow::before {
      box-shadow: none;
    }

    .no-shadow::after {
      box-shadow: none;
    }

    .slick-arrow.slick-hidden {
      display: none
    }

    .slick-arrow {
      display: none !important
    }

    @media only screen and (min-width: 62em) {
      .slick-arrow {
        display: block !important
      }
    }

    .slick-next,
    .slick-prev {
      position: absolute;
      display: block;
      line-height: 0;
      font-size: 0;
      cursor: pointer;
      top: 50%;
      transform: translateY(-50%);
      min-width: 0;
      padding: 0;
      border: none;
      z-index: 900;
      @include sh-color('orange');
    }

    @media only screen and (max-width: 62em) {
      .slick-prev-graph {
        left: -9% !important;
      }
      .slick-next-graph {
        right: -9% !important;
      }
    }

    .slick-next:focus:before,
    .slick-next:hover:before,
    .slick-prev:focus:before,
    .slick-prev:hover:before {
      opacity: 1;
      @include sh-border-color('orange');
    }

    .slick-disabled {
      opacity: .5;
      pointer-events: none
    }

    .slick-next:before,
    .slick-prev:before {
      font-family: SH Icon, "sh-icons-extended";
      font-size: 18px;
      font-size: 1rem;
      opacity: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      display: inline-block;
      padding: 0;
      width: 39.999px;
      width: 2.2222222222rem;
      height: 39.999px;
      height: 2.2222222222rem;
      line-height: 2.2222222222;
      text-align: center;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid;
      @include sh-border-color('black-30');
    }

    .ie .slick-next:before,
    .ie .slick-prev:before {
      line-height: 2
    }

    .ios .slick-next:before,
    .ios .slick-prev:before {
      height: 40.017px;
      height: 2.2232222222rem
    }

    .slick-prev {
      left: -69.987px;
      left: -3.8882222222rem
    }

    .slick-next {
      right: -69.987px;
      right: -3.8882222222rem
    }

    .slick-dots {
      list-style: none;
      display: block;
      text-align: center;
      padding: 0;
      margin: 0;
      width: 100%;
      position: relative;
      margin-top: 18.988px;
      margin-top: 1.0548888889rem;
      line-height: 0
    }

    .slick-dots li {
      position: relative;
      display: inline-block;
      margin: 0 3px;
      padding: 0
    }

    .slick-dots li,
    .slick-dots li button {
      height: 21.999px;
      height: 1.2222222222rem;
      width: 21.999px;
      width: 1.2222222222rem;
      cursor: pointer
    }

    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      min-width: 0;
      outline: none;
      line-height: 0;
      font-size: 0;
      color: transparent;
      padding: 5px
    }

    .slick-dots li button:focus,
    .slick-dots li button:hover {
      outline: none
    }

    .slick-dots li button:focus:before,
    .slick-dots li button:hover:before {
      opacity: 1
    }

    .slick-dots li button:before {
      position: absolute;
      top: 0;
      left: 0;
      content: "\25CF";
      width: 21.999px;
      width: 1.2222222222rem;
      height: 21.999px;
      height: 1.2222222222rem;
      font-family: SH Icon, "sh-icons-extended";
      font-size: 21.999px;
      font-size: 1.2222222222rem;
      line-height: 1;
      text-align: center;
      color: #b7b7b7;
      opacity: .5;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale
    }

    .slick-dots li.slick-active button:before {
      color: #ec6602;
      opacity: 1
    }
  }

  .slick-arrow-smaller-indent {
    &.slick-next {
      right: -2.6rem;
    }
    &.slick-prev {
      left: -2.6rem;
    }
  }
}

@mixin ln-calendar-scheduler($options: ()) {
  $_defaults: ();
  $config: map-merge($_defaults, $options);

  .cal-scheduler-view {
    * {
      box-sizing: border-box;
    }

    max-height: 650px;

    @include media-query('sm-down') {
      padding: 1px;
      font-size: 0.8rem;
    }

    .unselectable {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .cal-scheduler-headers {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-flow: row wrap;
      flex-flow: row wrap;
      margin-bottom: 3px;
      border-bottom: 3px solid black;
      position: inherit;
      top: 0;
      z-index: 99999;
      background: #fff;


      .aside {
        flex: 1 0;

        &.cal-header-clock {
          max-width: 5em;

          @include media-query('sm-down') {
            max-width: 2em;
          }
        }
      }

      .cal-header {
        flex: 1;
        text-align: center;
        padding: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: default;

        &:not(:last-child) {
          border-right: 1px solid #e1e1e1;
        }

        &:hover {
          background-color: #ededed;
        }

        &.cal-today {
          background-color: #e8fde7;
        }

        &.cal-weekend span {
          color: #8b0000;
        }

        span {
          font-weight: 400;
          opacity: 0.5;
        }
      }

      .cal-header-cols {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-flow: row wrap;
        flex-flow: row wrap;
      }
    }

    .cal-scheduler {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-flow: row wrap;
      flex-flow: row wrap;


      .aside {
        flex: 1 0;

        &.cal-scheduler-hour-rows {
          max-width: 5em; /*5.12em */

          @include media-query('sm-down') {
            max-width: 2em;
          }

        }
      }

      .cal-scheduler-hour-rows {
        width: auto !important;
        border: solid 1px #e1e1e1;
        overflow: hidden;
        position: relative;

        .cal-scheduler-hour {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: flex;

          &:nth-child(odd) {
            background-color: #fafafa;
          }

          &:not(:last-child) {
            border-bottom: 1px solid #e1e1e1;
          }

          .cal-scheduler-time {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-flex-flow: column wrap;
            flex-flow: column wrap;
            width: 100%;
            font-weight: bold;
            text-align: center;

            .cal-scheduler-time-segment {
              /* flex: 1 0; */
              cursor: default;

              &:hover {
                background-color: #ededed;
              }

              /*
                 Used for separation of segments
                 &:not(:last-child) {
                 border-bottom: thin dashed #e1e1e1;
                 }
              */

              &__time-position {
                margin-top: -5px;
              }
            }
          }
        }
      }

      .cal-scheduler-cols {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-flow: row wrap;
        flex-flow: row wrap;
        border-top: 1px solid #e1e1e1;

        .cal-scheduler-col {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-flex-flow: column wrap;
          flex-flow: column wrap;
          flex: 1 0;
          position: relative;
          border-right: 1px solid #e1e1e1;
          /*&:hover {
              background-color: #fafafa;
          }*/
          .cal-scheduler-hour {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            flex-flow: column wrap;
            flex: 1 0;

            &.cal-today {
              background-color: #e8fde7;
            }

            &.cal-disabled {
              background-color: #eee;
              pointer-events: none;
            }

            &.cal-odd {
              background: #ffffff;
            }

            &.cal-even {
              background: #fafafa;
            }

            .cal-scheduler-hour-segments {
              display: -webkit-box;
              display: -moz-box;
              display: -ms-flexbox;
              display: flex;
              flex-flow: column wrap;
              flex: 1 0;
              border-bottom: 1px solid #e1e1e1;

              &.no-border {
                border-bottom: 0 !important;
              }

              &.cal-disabled {
                background-color: #eee;
                pointer-events: none;

                .cal-scheduler-event {
                  filter: opacity(50%);
                  -webkit-filter: opacity(50%);
                }
              }

              :not(:last-child) .cal-scheduler-hour-segment {
                border-bottom: thin dashed #e1e1e1;
              }

              .cal-scheduler-hour-segment {
                flex: 1 0;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: flex;
                flex-flow: column wrap;

                /*
                &:not(.has-events):hover {
                     background-color: #ededed;
                 }
                */


                &.no-border {
                  border-bottom: 0 !important;
                }

                &.cal-disabled {
                  background-color: #eee;
                  pointer-events: none;
                }

                &.cal-drag-over {
                  background-color: #ededed;
                }

                .cal-scheduler-time {
                  font-weight: bold;
                  font-size: .7em;
                  color: #6c757d;
                  cursor: default;
                }
              }
            }
          }

          .cal-scheduler-event-container {
            flex: 1 0;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            /* display: flex; */
            flex-flow: column wrap;
            position: absolute;

            &.zoom-on-hover {
              -webkit-transition: all ease-out 0.2s;
              -moz-transition: all ease-out 0.2s;
              -o-transition: all ease-out 0.2s;
              transition: all ease-out 0.2s;

              &:hover {
                -webkit-transform: scale(1.05);
                -moz-transform: scale(1.05);
                -o-transform: scale(1.05);
                transform: scale(1.05);
                z-index: 9999999;
              }
            }

            .cal-scheduler-event {
              flex: 1 0;
              display: -webkit-box;
              display: -moz-box;
              display: -ms-flexbox;
              display: flex;
              flex-flow: column wrap;
              padding: 5px 10px;
              font-size: 12px;
              margin: 2px;
              /* line-height: 30px;  */
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              border-radius: .3em;
              height: calc(100% - 5px);

              -webkit-transition: all ease-out 0.2s;
              -moz-transition: all ease-out 0.2s;
              -o-transition: all ease-out 0.2s;
              transition: all ease-out 0.2s;

              filter: brightness(100%);
              -webkit-filter: brightness(100%);
              -webkit-backface-visibility: hidden; /* Fix for transition flickering */
              -moz-backface-visibility: hidden;
              backface-visibility: hidden;

              position: absolute;
              width: calc(100% - 5px);

              &.cal-disabled, &.cal-cancelled {
                background-color: gray !important;
                /* pointer-events: none;  */
                filter: grayscale(100%);
                -webkit-filter: grayscale(100%);
              }

              &.cal-not-clickable {
                cursor: not-allowed !important;
              }

              &:not(.cal-disabled):not(.cal-cancelled):hover {
                cursor: pointer;
                filter: brightness(95%);
                -webkit-filter: brightness(95%);
              }

              &:not(.cal-disabled):not(.cal-cancelled).cal-draggable {
                cursor: move;
              }

              .cal-scheduler-event-title-container {
                width: 100%;
                display: flex;
                flex-flow: row wrap;
                align-items: center;

                .cal-scheduler-event-status {
                  /* margin: 7px 0;  */
                  width: 16px;
                  height: 16px;
                  background: grey;
                  -moz-border-radius: 50px;
                  -webkit-border-radius: 50px;
                  border-radius: 50px;
                  border: 1px solid black;
                }
              }

              .cal-scheduler-event-content-container {
                flex: 1 auto;
                width: 100%;
                /* padding-right: 1.5em;  */

                .cal-scheduler-event-content {
                  /* white-space: pre-wrap;    */
                  /* CSS3 */
                  /* white-space: -moz-pre-wrap;  */ /* Firefox */
                  /* white-space: -pre-wrap;      */
                  /* Opera <7 */
                  /* white-space: -o-pre-wrap;   */
                  /* Opera 7 */
                  /* word-wrap: break-word;      */
                  /* IE */
                  /* overflow: hidden; */
                  /* text-overflow: ellipsis;  */
                  white-space: normal;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  /*max-height: 5rem; */

                  -webkit-user-select: none; /* Safari */
                  -moz-user-select: none; /* Firefox */
                  -ms-user-select: none; /* IE10+/Edge */
                  user-select: none; /* Standard */
                }
              }

              .cal-scheduler-event-actions-container {
                width: 100%;
                position: relative;
                text-align: end;

                &.no-content-actions {
                  flex: 1 0;
                }

                &:not(.no-content-actions) {
                  .cal-scheduler-event-actions {
                    width: auto;
                    height: auto;
                  }
                }

                .cal-scheduler-event-actions {

                  .cal-scheduler-event-action {
                    text-decoration: none;

                    -webkit-user-select: none; /* Safari */
                    -moz-user-select: none; /* Firefox */
                    -ms-user-select: none; /* IE10+/Edge */
                    user-select: none; /* Standard */
                  }
                }
              }
            }

            .cal-resize-handle {
              width: 100%;
              height: 4px;
              cursor: row-resize;
              position: absolute;

              &.cal-resize-handle-after-end {
                bottom: 0;
              }
            }

            &.cal-drag-active {
              pointer-events: none;
              z-index: 999;

              & * {
                pointer-events: none;
              }
            }
          }
        }

        .crosshatch {
          background-image: linear-gradient(
              45deg,
              #f1f1f1 25%,
              #0000001a 25%,
              #0000001a 50%,
              #f1f1f1 50%,
              #f1f1f1 75%,
              #0000001a 75%,
              #0000001a
          );
          background-size: 8px 8px;
        }

        .slot-height {
          height: 100%;
        }
      }

      .duplicate-spinner-position {
        position: inherit;
      }

      .arrow-menu {
        position: absolute;
        right: -23px;
        top: -7px;
        height: 35px;
        width: 40px;

        &:before {
          color: black;
        }

        &-dropup-content {
          bottom: 0;
          top: auto;
        }
      }

      .arrow-menu:hover {
        color: sh-color('orange') !important;
        cursor: pointer;
      }
    }
  }

  .duplicate-dialog-width {
    width: auto !important;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      width: 32em !important;
    }

  }
}


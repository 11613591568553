///
/// @access public
/// @param {Map} $options
/// @prop {Map} $options.colors [sh-color-list()] - Map of color names and values
@mixin sh-u-colors($options: ()) {

  $_defaults: (
    colors: sh-color-list()
  );

  $_config: map-merge($_defaults, $options);
  
  $_colors: map-get($_config, "colors");

  @each $name in $_colors {
    .color-#{$name} { 
      color: sh-color($name) !important;
    }
  }

  .color-inherit {
    color: inherit !important;
  }
}

/// Card
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Card
/// sh-aspect-ratio($options: (
///
/// ));
///
@use "sass:math";

@mixin sh-card($options: ()) {
  $_defaults: (
    bottom-margin: 1.666666666rem,
    // 30px
    x-padding: 1.666666666rem,
    // 30px
    y-padding: 1.666666666rem,
    // 30px
  );

  $_config: map-merge($_defaults, $options);
  $_transition: sh-transition("fade");
  $_gradient-height: 100px;
  $_background-height: $_gradient-height * 4.2;

  .#{$prefix}card {
    @include sh-background-color("white");
    @include sh-color("black-90");
    @include border-radius(1px);
    @include sh-shadow("flat");
    border: none;
    display: flex;
    flex-direction: column;
    margin-bottom: map-get($_config, "bottom-margin");
    position: relative;
    transition: all map-get($_transition, "duration") map-get($_transition, "easing");

    // [1] Enable `flex-grow: 1` for decks and groups so that card blocks take up
    //     as much space as possible, ensuring footers are aligned to the bottom.

    &__image {
      display: block;
    }

    &__body {
      @include respect-inner-padding();
      flex: 1 1 auto; // [1]
      margin: map-get($_config, "y-padding") 0;
      padding: 0 map-get($_config, "x-padding");
      display: block;
    }

    &__title {
      margin-top: 0;
      margin-bottom: to-rem(10);
    }

    &__subtitle {
      margin-top: 0;
      margin-bottom: to-rem(10);
    }

    // [1] align bottom with flexbox
    &__footer {
      margin: map-get($_config, "y-padding") 0;
      margin-top: auto; // [1]
      padding: 0 map-get($_config, "x-padding");
      display: block;
    }

    &--linked {
    
      .#{$prefix}card__image {
        transition: filter map-get($_transition, "duration") map-get($_transition, "easing");}

      @include sh-hover(("bgColor": "white", "transition": false)) {
        @include sh-shadow("raised");
        @include sh-color("black-90");

        .#{$prefix}card__image {
          // filter: opacity(1) contrast(1.2) brightness(.85); // on website since DDC 0.x
          // filter: opacity(1) contrast(.95) brightness(1.1); // DDC 1.x
          filter: opacity(1) contrast(1) brightness(0.95); // DDC 2.x
        }
      }

      // @include focus {
      //   @include sh-shadow("focused");
      //   color: inherit;
      // }

      @include active {
        @include sh-shadow("flat");
      }

      &:focus:not(:focus-visible) {
        @include sh-shadow("flat");
      }
    }

    &--rimless {
      border-width: 0;
      box-shadow: none;
      background: transparent;

      .#{$prefix}card__body {
        margin: (map-get($_config, "y-padding") * 0.5) 0;
        padding: 0;
      }

      @include hover-active {
        box-shadow: none;
      }
    }

    // Covered Card

    &--covered {
      perspective: 1000px;

      .#{$prefix}card__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: translateZ(0);
        transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);filter: opacity(1) contrast(1) brightness(1); // remove filter from image
      }

      .#{$prefix}card__label {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        padding: to-rem(20);
        line-height: 1;
        z-index: 100;
      }

      .#{$prefix}card__body {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        color: #ffffff;
        padding: to-rem(20);
        padding-top: to-rem($_gradient-height);
        background-size: 100% to-rem($_background-height), 100% 100%;
        background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.03) 2%,
            rgba(0, 0, 0, 0.08) 4%,
            rgba(0, 0, 0, 0.15) 7%,
            rgba(0, 0, 0, 0.31) 13%,
            rgba(0, 0, 0, 0.51) 21%,
            rgba(0, 0, 0, 0.55) 25%,
            rgba(0, 0, 0, 0.55) 100%
          ),
          linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.55) 0%,
            rgba(0, 0, 0, 0.55) 100%
          );
        background-position: left top, left to-rem($_background-height);
        background-repeat: no-repeat;
      }

      .#{$prefix}card__radial-gradient {
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: radial-gradient(
          100% 107.78% at 0% 100%,
          rgba(0, 0, 0, 0.5) 0%,
          rgba(0, 0, 0, 0.1) 41.67%,
          rgba(0, 0, 0, 0) 76.56%,
          rgba(0, 0, 0, 0) 100%
        );
        background-position: left bottom;
        background-repeat: no-repeat;
        background-size: clamp(200px, 70%, 700px) clamp(200px, 50%, 500px);
      }

      .#{$prefix}card__title {
        font-weight: 900;
        font-size: to-rem(20);
        line-height: to-rem(25);
        margin-top: to-rem(5);
        @include media-query("md") {
          font-size: to-rem(21);
        }
      }

      .#{$prefix}card__subtitle {
        font-weight: 600;
        font-size: to-rem(14);
        line-height: to-rem(20);
        margin-bottom: 0;
      }

      @include sh-hover(("bgColor": "white", "transition": false)) {
        .#{$prefix}card__image {
          transform: translateZ(100px); // replace scaling with Z-translate to omit gradient flickering
          filter: opacity(1) contrast(1) brightness(1); // remove filter from image
        }
      }
    }

    @each $media-query in sh-breakpoint-list() {
      @include media-query($media-query) {
        @include _card-horizontal(
          map-merge(
            $_config,
            (
              media-query: $media-query,
            )
          )
        );
      }
    }
    @content;
  }
}

@mixin _card-horizontal($options) {
  $media-query: map-get($options, "media-query");

  @if ($media-query == "xs") {
    $media-query: "";
  } @else {
    $media-query: "-#{$media-query}-up";
  }

  $x-padding: map-get($options, "x-padding");
  $y-padding: map-get($options, "y-padding");

  $ratios: ((2, 1), (1, 2), (1, 3));

  &--horizontal#{$media-query} {
    flex-direction: row;
    margin: 0;
    width: 100%;

    .#{$prefix}card__image {
      flex: 1 0 0;
    }

    &.card--image-right {
      .#{$prefix}card__image {
        order: 2;
      }
    }

    .#{$prefix}card__body {
      flex: 1 1 0;
    }

    &.#{$prefix}card--small {
      .#{$prefix}card__body {
        margin: math.div(map-get($options, "y-padding"), 3) 0;
        padding: 0 math.div(map-get($options, "x-padding") * 2, 3);
      }
    }

    @each $ratio in $ratios {
      $left: nth($ratio, 1);
      $right: nth($ratio, 2);
      $total: $left + $right;

      &.#{$prefix}card--#{$left}x#{$right} {
        .#{$prefix}card__image {
          flex: 0 0 ratio($left, $total);
        }

        .#{$prefix}card__body {
          flex: 1 1 ratio($right, $total);
        }
      }
    }
  }

  [class*="bg-color-gray-shade"] &,
  .bg-color-black &,
  :host([theme="dark"]) & {
    @include sh-color("black-10");
    @include sh-border-color("black-70");
    @include sh-background-color("black-90");

    // States
    @include sh-state("disabled");
    @include sh-state("focus");
    @include sh-state(
      "active",
      (
        "bgColor": "black-90",
      )
    );

    &::before,
    i::before {
      @include sh-color("black-10");
    }

    &--linked {
      @include sh-hover(("bgColor": "black-90", "transition": false)) {
        @include sh-shadow("raised-dark");
        @include sh-color("black-10");

        .#{$prefix}card__image {
          filter: opacity(1) contrast(1) brightness(1.05); // oposite of brightness filter in light theme
        }
      } 
    }
  }
}

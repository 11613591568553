///
/// @access public
///
///
@mixin sh-u-alignments($options: ()) {
  $_defaults: (
    text-alignments: (
      left,
      right,
      center,
      justify,
    ),
  );

  $_config: map-merge($_defaults, $options);

  $_text-alignments: map-get($_config, "text-alignments");

  @each $_alignment in $_text-alignments {
    .text-align-#{$_alignment} {
      text-align: #{$_alignment} !important;
    }
  }
}

@mixin ln-icon-container($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .icon-container {
    border-radius: 50%;
    width: 70px;
    height: 70px;
    display: inline-block;

    .icon {
      color: sh-color('white');
      width: 1.7em;
      height: 1.7em;
      font-size: 0.85em;
      line-height: 1.6;
      border-radius: 50%;
      text-align: center;
    }

    &--success {
      background-color: sh-color('green');
    }

    &--error {
      background-color: sh-color('red');
    }
  }

  .button-info-icon {
    @extend .button ;

    font-size: 0.67rem;
    width: 18px;
    height: 18px;
    top: -6px;
    padding: 0.1rem .92rem 0 0;
    min-width: 18px;
    cursor: pointer;

    i::before {
      margin-left: -.15em;
    }

  }

  .button-info-icon-align {
    padding-left: .1rem;
  }

  .arrow-icon-disabled {
    color: gray;
    opacity: 0.5;
    pointer-events: none;
  }

}

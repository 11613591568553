///
/// @access public
///
///
@mixin sh-u-font-styles($options: ()) {
  $_defaults: ();

  $_config: map-merge($_defaults, $options);

  .font-style-normal {
    font-style: normal !important;
  }

  .font-style-italic {
    font-style: italic !important;
  }

  .font-style-oblique {
    font-style: oblique !important;
  }

  .text-decoration-none {
    text-decoration: none !important;
  }

  .text-decoration-underline {
    text-decoration: underline !important;
  }

  .text-decoration-line-through {
    text-decoration: line-through !important;
  }

  .text-muted {
    opacity: 0.7 !important;
  }
}

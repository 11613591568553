@mixin ln-terms-and-conditions($options: ()) {
    
    $_defaults: ();
    $_config: map-merge($_defaults, $options);
  
    .terms-and-conditions {
        &__introtext {
            padding: 0 1.5rem;
        }

        &__content-container {
            display: none;
            width: 100%;
            height: 50vh;
            border: none;
            @media screen and (min-width: 1025px) {
              display: block;
            }
        }
    }
  }

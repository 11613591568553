/// Component: Legal Links
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Legal Links
/// sh-legal-links($options: (
///
/// ));
///
@mixin sh-legal-links($options: ()) {
  $_defaults: ();
  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}legal-links";

  #{$_component} {
    list-style-type: none;
    margin: 0;
    margin-top: to-rem(20);
    padding-left: 0;
    line-height: to-rem(20);
    @include sh-font-size("xsmall");
    @include sh-font-family("text");

    &__item {
      display: inline-block;
      margin: 0;

      &:after {
        display: inline-block;
        content: "|";
        @include sh-color("black-60");
        margin: 0 0.2em;
      }

      &:last-of-type:after {
        display: none;
      }
    }

    &__link,
    &__item:first-child {
      @include sh-color("black-60");
    }

    &__link:hover {
      @include sh-color("black-90");
    }
  }

  // Dark theme

  [class*="bg-color-gray-shade"],
  .bg-color-black,
  :host([theme="dark"]) {
    #{$_component} {  
      &__item {
        &:after {
          @include sh-color("black-40");
        }
      }

      &__link,
      &__item:first-child {
        @include sh-color("black-40");
      }

      &__link:hover {
        @include sh-color("black-10");
      }
    }
    
    @content;
  }

}

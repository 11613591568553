@mixin ln-widget($options: ()) {

  $_default: ();
  $config: map-merge($_default, $options);

  .widget {
    padding: to-rem(16);
    background-color: sh-color('white');
    margin-bottom: to-rem(32);
    height: calc(100% - #{to-rem(32)});

    &__new {
      cursor: pointer;

      &__span-content {
        font-size: 5rem;

        @include media-query('md') {
          font-size: 10rem;
        }
      }

      &:hover &__span-content {
        @include sh-color('orange');
      }
    }

    &__no-margin {
      margin-bottom: 0;
    }

    &__header {
      display: flex;
      align-items: baseline;
      justify-content: space-between;

      > * {
        margin-bottom: 0.611rem;
      }
    }

    &__title {
      font-size: 1.222rem;
      @include sh-font-weight('bold');

      & .responsive_font {
        @extend .word-break-break-word;
        @media(max-width: 420px) {
          font-size: calc(0.1rem + 4.2vw);
        }
        @media(min-width: 1200px) and (max-width: 1499px) {
          font-size: calc(0.1rem + 0.84vw);
        }
        @media(min-width: 1500px) and (max-width: 1710px) {
          font-size: calc(0.1rem + 1vw);
        }
      }

      & .service_metrics_widget {
        display: inline-block;
        width: 95%;
        height: 6vh;
      }

      .service_metrics_title_checkbox {
        position: relative;
        top: -20px;
        display: inline-block;
      }

      .service_metrics_drag_and_drop {
        position: absolute;
        right: 20px;
        top: 22px;
        display: inline-block;
      }
    }

    &__eq_title {
      @include sh-font-weight('bold');

      & .responsive_font {
        font-size: 1rem;
        @extend .word-break-break-word;
        @media(max-width: 420px) {
          font-size: calc(0.1rem + 4.2vw);
        }
        @media(min-width: 1200px) and (max-width: 1499px) {
          font-size: calc(0.1rem + 0.84vw);
        }
        @media(min-width: 1500px) and (max-width: 1710px) {
          font-size: calc(0.1rem + 1vw);
        }
      }
    }

    &__eq_body {
      & .responsive_font {
        font-size: 1rem;
        @extend .word-break-break-word;
        @media(max-width: 420px) {
          font-size: calc(0.1rem + 4.2vw);
        }
        @media(min-width: 1200px) and (max-width: 1499px) {
          font-size: calc(0.1rem + 0.84vw);
        }
        @media(min-width: 1500px) and (max-width: 1710px) {
          font-size: calc(0.1rem + 1vw);
        }
      }
    }

    &__date_label {
      font-size: 1.1rem;
      position: absolute;
      bottom: 20px;
      @extend .margin-left-tiny-1;

      & .responsive_font {
        @extend .word-break-break-word;
        @media(max-width: 420px) {
          font-size: calc(0.08rem + 4.2vw);
        }
        @media(min-width: 1200px) and (max-width: 1499px) {
          font-size: calc(0.08rem + 0.84vw);
        }
        @media(min-width: 1500px) and (max-width: 1710px) {
          font-size: calc(0.08rem + 1vw);
        }
      }
    }

    &__button {
      font-size: 1.1rem;
      position: absolute;
      bottom: 20px;
      margin-left: 10rem;

      & .responsive_font {
        @extend .word-break-break-word;
        @media(max-width: 420px) {
          font-size: calc(0.08rem + 4.2vw);
        }
        @media(min-width: 1200px) and (max-width: 1499px) {
          font-size: calc(0.08rem + 0.84vw);
        }
        @media(min-width: 1500px) and (max-width: 1710px) {
          font-size: calc(0.08rem + 1vw);
        }
      }
    }

    &__body {
      min-height: 200px;
    }

    &__eq-body {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      min-height: 140px !important;
    }

    &__toolbar {
      &-item {
        @include media-query('md') {
          display: inline-block;
          margin-top: 0;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    &__link {
      line-height: 1.22;
    }

    &__background_grey {
      background-color: sh-color('black-7');
    }

    &__info-icon {
      float: right;
    }

    &__transparent {
      opacity: .5;
    }

    .eq-widget-label {
      border-radius: 0.3em;
      text-align: center;
      @include sh-color('white');

      &--red {
        @include sh-background-color('red-dark');
      }

      &--yellow {
        @include sh-background-color('yellow');
        @include sh-color('black');
      }

      &--green {
        @include sh-background-color('green-dark');
      }

      &--gray {
        background-color: #bfbfbf ;
        @include sh-color('black');
      }
      &--grey {
        background-color: #bfbfbf ;
        @include sh-color('black');
      }

      &--cursor-pointer:hover {
        cursor: pointer
      }
    }

    &__slot {
      display: flex;
      justify-content: flex-end;
      height: 100%;
    }

    &__action-button {
      align-self: flex-end;
    }

    &__container {
      @media screen and ( min-width: 800px ) and ( max-width: 1920px ) and ( min-height: 600px ) and ( max-height: 1024px )
      {
        min-height: 48vh !important;
      }
      min-height: 32vh !important;
    }

  }
}

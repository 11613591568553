///
/// @access public
///
///
@mixin sh-u-image-replacements($options: ()) {
  $_defaults: ();

  $_config: map-merge($_defaults, $options);

  .image-replacement {
    background-color: transparent;
    border: 0;
    overflow: hidden;
    display: block;
    text-indent: -100%;
    white-space: nowrap;

    &:before {
      content: "";
      display: block;
      width: 0;
      height: 110%;
    }
  }
}

@mixin ln-item-panel($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .item-panel {

    position: absolute;
    top: 0;
    width: 100%;
    right: 0;
    bottom: 0;
    overflow: hidden;
    overflow-y: auto;
    z-index: 1;
    padding: 0 0.833rem 1rem;
    @include sh-background-color('white');

    @include media-query('md') {
      padding-top: 1rem;
    }

    @include media-query('xl') {
      width: 66.6666666%;
      padding-left: 1.66666rem;
      transform: translate3d(0, 0, 0);

      &--half-width {
        width: 50%;
      }

      &--content-scrollable {
        position: absolute;
        inset: 0 0 4.5rem 0;
        padding: 1rem 1rem 2rem;
        overflow: hidden;
        overflow-y: auto;
      }

      &--sticky-footer {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 1rem 1rem 0;
        box-shadow: 0 0 1rem rgba(sh-color('black-90'), .2);
      }

    }

    @include media-query('xl-down') {
      .item-panel--always-sticky-footer {
        height: 3rem;
        padding-top: 0.5rem;
      }

      .item-panel {
        overflow-x: scroll;
        padding-top: 0;
      }
    }

    &--equipment {
      padding-top: 1rem;
      transition: transform 0.3s ease-in;
      @include sh-z-index('content-raised');

      @include media-query('xl-down') {
        transform: translate3d(100%, 0, 0);
      }

      &.is-active {
        transform: translate3d(0, 0, 0);
      }
    }


    &__remove-all {
      float: right;
    }

    &--always-sticky-footer {
      position: fixed;
      background-color: white;
      padding: 1rem 1rem 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-shadow: 0 0 1rem rgba(sh-color('black-90'), .2);
      z-index: sh-z-index('dropdown') + 1;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        @include media-query('xl') {
          position: absolute;
        }
      }
    }
  }
}

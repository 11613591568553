/// Component: Accordion
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Accordion
/// sh-accordion($options: (
///
/// ));
///
@mixin sh-accordion($options: ()) {
  $_defaults: (
    x-spacer: to-rem(20),
    y-spacer: to-rem(15),
    active-color: sh-color("black-90"),
  );
  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}accordion";

  $_active-color: map-get($_config, "active-color");

  #{$_component} {
    border-top: 1px solid sh-color("black-30");
    @include sh-color("black-90");

    &__header {
      margin: 0;
    }

    &__title {
      @include sh-font-family("text");
      @include sh-font-weight("bold");
      align-items: center;
      border: none;
      border-bottom: 1px solid sh-color("black-30");
      display: flex;
      font-size: to-rem(18); // 18px
      line-height: to-rem(25); // 25px
      margin: 0;
      padding: map-get($_config, "y-spacer") map-get($_config, "x-spacer");
      position: relative;
      text-align: left;
      width: 100%;

      &::before {
        @include sh-color("black-60");
        position: absolute;
        right: map-get($_config, "x-spacer");
      }

      &[aria-expanded="true"] {
        border-bottom: none;

        &::before {
          transform: rotate(180deg);
        }
      }

      @include sh-state("focus");
      @include sh-state("hover", ("bgColor": "white"));
      @include sh-state("active", ("bgColor": "white")) {
        border-bottom-color: $_active-color;
      }
    }

    &__panel {
      display: none;
      padding: 0 map-get($_config, "x-spacer");
      margin: 0;
      border-bottom: 1px solid sh-color("black-30");
      overflow: hidden;

      &[aria-hidden="false"] {
        display: block;
      }
    }

    // dark theme
    :host(.bg-color-black) &,
    :host(.bg-color-black-90) &,
    :host([theme="dark"]) &,
    &.dark {
      @include sh-border-color("black-70");
      @include sh-color("black-10");

      &__title {
        @include sh-border-color("black-70");

        &::before {
          @include sh-color("black-40");
        }

        @include sh-hover(("bgColor": "black")) {
          &::before {
            @include sh-color("black-10");
          }
        };
      }

      &__panel {
        @include sh-border-color("black-70");
      }
    }
    
    @content;
  }
}


@mixin ln-overlay-bottom-panel($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .overlay-bottom-panel {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    box-shadow: 0 -4px 0.4rem rgba(27, 27, 27, 0.2);
    padding: 0.75rem 0.75rem;
    background-color: white;
    z-index: 1;
 
    & > .button:not(:last-child) {
      margin-right: 0.75rem;
    }

    &__pagination {
      justify-content: center !important;
      flex-direction: row !important;

      .pagination__item {
        @include sh-color('black-90');
      }
    }

    @include media-query('sm-down') {
      flex-direction: column;

      & > .button:not(:last-child) {
        margin-bottom: 0.75rem !important;
        margin-right: 0 !important;
      }
    }

  }
}

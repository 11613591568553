// settings
$link-color: sh-color("black-60") !default;
$link-hover-color: sh-color("black-90") !default;
$link-inverted-color: sh-color("black-40") !default;
$link-hover-inverted-color: sh-color("black-10") !default;
$link-decoration: none !default;
$link-hover-decoration: none !default;

//
@mixin link-color {
  color: $link-color;
  text-decoration: $link-decoration;

  @include sh-state("focus") {
    color: inherit;

    /*
    Override focus styles for mouse users ONLY if
    :focus-visible is supported on this platform.
    */
    &:not(:focus-visible) {
      box-shadow: none;
    }
  }

  @include hover-active {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

a {
  @include link-color;
  text-decoration: none;
  line-height: inherit;
  user-select: none;
  transition: color map-get(sh-transition("fade"), "duration")
    map-get(sh-transition("fade"), "easing");

  &[class*="icon"]::before {
    color: inherit;
    margin-right: 0.3em; // add whitespace between icon and link
  }

  // Dark theme
  [class*="bg-color-gray-shade"] &,
  .bg-color-black & {
    color: $link-inverted-color;

    @include sh-state("focus") {
      color: $link-inverted-color;
    }

    @include hover-active {
      color: $link-hover-inverted-color;
    }
  }
}

// links inline text
.wysiwyg a {
  border-bottom: 1px solid currentColor;

  &[class*="icon"] {
    margin-left: 1.3em;
    position: relative;

    &::before {
      position: absolute;
      left: -1.3em;
      line-height: 1;
      margin-top: 0.44em; // fits paragraph line height 1.44
    }
  }
}

// And undo these styles for placeholder links/named anchors (without href)
// which have not been made explicitly keyboard-focusable (without tabindex).
// It would be more straightforward to just use a[href] in previous block, but that
// causes specificity issues in many other styles that are too complex to fix.
// See https://github.com/twbs/bootstrap/issues/19402

// a:not([href]):not([tabindex]):not(.button) {
//   color: inherit;
//   text-decoration: none;

//   @include hover-focus {
//     outline: 0;
//     color: inherit;
//     text-decoration: none;
//   }
// }

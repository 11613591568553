/// Component: Search Field
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Search Field
/// sh-textfield($options: (
///
/// ));
///
@mixin sh-search-field($options: ()) {
  $_defaults: (
    border-color: sh-color("black-10"),
    box-shadow: sh-shadow("navigation") // from global setting,
  );
  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}search-field";

  #{$_component} {
    height: 42px;

    & > .icon-search {
      @include sh-font-size("xlarge");
    }

    &__input {
      padding-top: 2px !important;
      @include sh-font-size("medium");
      @include sh-font-family("text");
    }

    &__autocomplete {
      position: absolute;
      top: 56px;
      left: 0;
      width: 100%;
      z-index: sh-z-index("dropdown");
      overflow: hidden;
      overflow-y: auto;
      max-height: to-rem(200); // scrolls if more then 4 items added
      display: none;
      background-color: sh-color("white");
      box-shadow: map-get($_config, "box-shadow");
      border: 1px solid map-get($_config, "border-color");
      border-top: none;
    }

    &.has-results {
      .#{$prefix}search-field__autocomplete {
        display: block;
      }
    }

    &--outlined {
      border: 1px solid sh-color("black-30");
      border-color: rgba(0, 0, 0, 0.24);
      border-radius: 4px !important;
      background-color: sh-color("white") !important;
    }
    
    @content;
  }
}

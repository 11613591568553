@mixin ln-detail-header($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .detail-information-header {

    border-left: 5px solid sh-color('black-60');
    padding-left: to-rem(11);

    &--status-red {
      @include sh-border-color('red');
    }

    &--status-orange {
      @include sh-border-color('orange');
    }

    &--status-yellow {
      @include sh-border-color('yellow');
    }

    &--status-green {
      @include sh-border-color('green');
    }

    &--status-gray {
      border-left-color: #009A38;
    }

    &--status-cyan {
      border-left-color: rgb(58, 191, 237);
    }
  }
}

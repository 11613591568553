@mixin ln-multiline-radio($options: ()) {
  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .multiline-radio {
    height: 56px;
    margin-bottom: 0.888rem;

    & > label {
      width: calc(100% - 2rem);
      padding-top: .25rem;
      padding-bottom: .25rem;
      margin-left: 2rem;
      margin-bottom: 0;

      border-bottom: 1px solid sh-color('orange');
    }

    & > label::before {
      top: 1.05rem;
      margin-left: 0;
      left: -2rem;
    }

    
    &__small-label {
      @include sh-font-size('xsmall');
      @include sh-color('black-60');
      @include sh-font-family('text');

      &:last-child {
        @include sh-font-size('medium');
        padding-top: .6rem;
      }
    }

    &__value-label {
      @include sh-font-size('medium');
      @include sh-color('black-90');
      @include sh-font-family('text');
    }

    &.is-disabled > label {
      border-bottom: 0;
    }
  }

  .multiline-radio--editor {
    .textfield, label {
      padding: 0;
      margin-top: 0;
      margin-bottom: 0;
    }

    > label {
      border-bottom: 0;
    }
  }  
}

/*
 @access public
*/
@mixin ln-u-dimensions($options: ()) {

  $_defaults: (

  );

  $_config: map-merge($_defaults, $options);

  @for $i from 0 to 101 {
    .width-#{$i} {
      width: $i#{'%'} !important;
    }

    .height-#{$i} {
      height: $i#{'%'} !important;
    }
  }
}

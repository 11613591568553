/// Component: Tabs
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Tabs
/// sh-textfield($options: (
///
/// ));
///
@mixin sh-tabs($options: ()) {
  $_defaults: (
    x-spacer: to-rem(20),
    y-spacer: to-rem(20),
    line-height: to-rem(30),
  );
  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}tabs";
  
  #{$_component} {
    &__list {
      border-bottom: 1px solid sh-color("black-30");
      display: flex;
      margin-bottom: 2rem;
      margin-left: map-get($_config, "x-spacer") * -1;
      position: relative;
      white-space: nowrap;
    }

    &__item {
      @include sh-color("black-60");
      cursor: pointer;
      display: inline-block;
      flex: 0 0 auto;
      font-weight: sh-font-weight("semibold");
      line-height: map-get($_config, "line-height");
      margin: 0;
      padding: map-get($_config, "y-spacer") map-get($_config, "x-spacer");
      position: relative;
      text-align: center;
      white-space: nowrap;

      @include focus {
        box-shadow: none;
        color: sh-color("black-60");
        outline: none;

        &::after {
          border-bottom: 2px solid rgba(sh-color("orange"), 0.5);
          bottom: 0;
          content: "";
          display: block;
          left: 0;
          position: absolute;
          right: 0;
        }
      }

      @include hover-active {
        @include sh-color("black-90");

        &::after {
          border-bottom: none; // override orange focus line
        }
      }

      &.is-active {
        @include sh-color("black-90");
      }
    }

    &__panel {
      display: none;

      &.is-active {
        display: block;
      }
    }

    &__dropdown-panel {
      color: sh-color("black-60");
    }

    &__dropdown-toggle {
      font-family: inherit;
      font-size: inherit;
      font-weight: sh-font-weight("semibold");

      .is-open + & {
        @include sh-color("black-90");
      }
    }

    &__active-line {
      background: sh-color("orange");
      bottom: 0;
      height: 2px;
      left: map-get($_config, "x-spacer");
      position: absolute;
      transition: left 0.15s cubic-bezier(0.35, 0, 0.25, 1), width 0.15s cubic-bezier(0.35, 0, 0.25, 1);
      will-change: left, width;
    }

    @content;
  }
}

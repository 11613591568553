@mixin ln-tooltip($options: ()) {

  $_default: ();
  $config: map-merge($_default, $options);

  .ln-tooltip {
    padding: 0.375rem 0.5rem;
    z-index: 1;
    position: fixed;

    &--dark-75 {
      $transparency-75-percent: 0.75;
      opacity: 1;
      transition: opacity .3s, transform .3s;
      transform: translate3d(0, 0, 0);
      color: white;
      background-color: rgba(0, 0, 0, $transparency-75-percent);

      &::after {
        background: -moz-linear-gradient(135deg, transparent 0%, transparent 50%, rgba(0, 0, 0, $transparency-75-percent) 50%, rgba(0, 0, 0, $transparency-75-percent) 100%);
        background: -webkit-linear-gradient(135deg, transparent 0%, transparent 50%, rgba(0, 0, 0, $transparency-75-percent) 50%, rgba(0, 0, 0, $transparency-75-percent) 100%);
        background: linear-gradient(135deg, transparent 0%, transparent 50%, rgba(0, 0, 0, $transparency-75-percent) 50%, rgba(0, 0, 0, $transparency-75-percent) 100%);
      }
    }

    &--overlay-tooltip {
      z-index: 9999;
    }

    &--warning-icon {
      font-size: 1.3rem;
      color: red;
    }
  }
  .document-overlay {
    z-index: 9999;
  }

  .icon-info-with-scrollbar {
    overflow-y: auto;
    max-height: 100px;
    min-width: 120px;
  }

  .icon-info-area {
    position: fixed;
    height: 100px;
    min-width: 120px;
    z-index: 1;
  }
}

@mixin ln-date-time-picker($options: ()) {

  $_default: ();
  $config: map-merge($_default, $options);

  .date-time-picker {
    border-bottom: 1px solid sh-color("black-30");

    &__is-disabled {
      @include sh-background-color('black-10')
    }

    &__is-disabled + .date-time-picker__label {
      @include sh-background-color('black-10')
    }
  }

  .flatpickr-calendar {
    .flatpickr-prev-month, .flatpickr-next-month {
      &.flatpickr-disabled {
        cursor: default;
        @include sh-color('black-10');
        &:hover {
          @include sh-color('black-10');
        }
      }
    }

    .flatpickr-days .dayContainer .flatpickr-day.flatpickr-disabled {
      cursor: default;
      @include sh-color('black-10');
      &:hover {
        @include sh-color('black-10');
      }
    }

    .numInput:disabled  {
      background-color: sh-color('white') !important;
      color: sh-color('black') !important;
    }

  }

  // remove up & down input spin buttons present on Firefox (UI MarCom removes these for Chrome)
  .flatpickr-calendar,
  .flatpickr-time {
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }

  .flatpickr-calendar.hasTime.open .flatpickr-time {
    display: flex;
  }

  .date-time-picker > .icon-calendar {
    line-height: 1.7rem;
    font-size: x-large;
    left: 0.5rem;
  }

  .date-time-picker > .icon-close {
    line-height: 1.5rem;
    font-size: x-large;
    color: #666666;
  }

  .date-time-picker > .icon-close:hover {
    color: inherit;
  }
}

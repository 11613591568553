@mixin ln-remote-work-force($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .remote-work-force {
    &__details {
      &__appointments {
        .accordion__title {
          @media (max-width: 520px) {
            justify-content: space-between;
          }
        }

        &__info {
          flex-direction: row;
          justify-content: space-between;
          flex: 1;
          display: flex;

          span {
            height: 20px;
            margin-top: auto;
            margin-bottom: auto;
          }

          @media (max-width: 520px) {
            flex-direction: column;
            max-width: 80px;
            text-align: center;

            button {
              margin-top: 0.4rem;
            }
          }
        }
      }
    }

    &__create-form {
      &__contact-area {
        h5 {
          font-weight: 400;
          line-height: 1.44;
          margin-top: 0.2rem !important;
        }
      }
    }
  }

  .week-align {
    width: 130px;
    text-align: center;
  }

  .time-period-width {
    flex: 0 0 auto;
    width: 250px;
    max-width: none;
    .selectbox__select {
      padding-right: 36px;
    }
  }

  .make-90-height {
    height: 90%;
  }

  .make-90-height-body {
    min-height: calc(90vh - 9rem);
    max-height: calc(90vh - 9rem);
  }
}  

@mixin ln-selectbox__hint($options: ()) {

  $_default: ();
  $config: map-merge($_default, $options);

  .selectbox__hint--validation {
    color: #e7001d;
    margin-top: -0.888rem;
  }
}

@mixin ln-form($options: ()) {
    
    $_defaults: ();
    $config: map-merge($_defaults, $options);
    
    .form {
        &__label {
            @include sh-font-size('xsmall');
            @include sh-color('black-70');
            margin-bottom: 0.5rem;
        }

        &__helper-container {

            @include media-query('lg') {
                position: absolute;
                border-left: 3px solid sh-color('black-30');
                padding: 0;
                padding-left: 0.8rem;
                left: 0.2rem;
                top: 0;
                min-height: 56px;
                opacity: 0;
                transition: opacity 0.3s ease-in-out;
                @include sh-color('black-40');

                &.is-visible {
                  display: block;
                  opacity: 1;
                }

                &::before {
                    display: block;
                    content: "";
                    position: absolute;
                    border-top: 2px solid sh-color('black-30');
                    border-left: 2px solid sh-color('black-30');
                    transform: rotate(-45deg);
                    width: 20px;
                    height: 20px;
                    top: 18px;
                    left: -12px;
                    @include sh-background-color('white');
                }
            }
        }

        &__helper-text {
            @include media-query('lg') {
                margin-bottom: 0;
            }
        }
    }
} 

///
/// @access public
///
///
@mixin sh-u-visibilities($options: ()) {
  $_defaults: (
    media-queries: sh-breakpoint-list(),
  );

  $_config: map-merge($_defaults, $options);

  $_media-queries: map-get($_config, "media-queries");

  /*
  * Hide from both screenreaders and browsers: h5bp.com/u
  */

  .hidden {
    display: none !important;
    visibility: hidden;
  }

  .overflow-hidden {
    overflow: hidden !important;
  }

  .overflow-x-scroll {
    overflow-x: scroll !important;
  }

  .overflow-x-auto {
    overflow-x: auto !important;
  }

  /*
  * Hide only visually, but have it available for screenreaders: h5bp.com/v
  */

  .visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  /*
  * Extends the .visuallyhidden class to allow the element to be focusable
  * when navigated to via the keyboard: h5bp.com/p
  */

  .visually-hidden.focusable:active,
  .visually-hidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }

  /*
  * Hide visually and from screenreaders, but maintain layout
  */

  .invisible {
    visibility: hidden;
  }

  // Responsive visibility utilities

  @each $_media-query in $_media-queries {
    .hidden-#{$_media-query}-up {
      @include media-breakpoint-up($_media-query) {
        display: none !important;
      }
    }
    .hidden-#{$_media-query}-down {
      @include media-breakpoint-down($_media-query) {
        display: none !important;
      }
    }
  }

  .hidden-for-screen {
    @media screen {
      display: none;
    }
  }
  .hidden-for-print {
    @media print {
      display: none;
    }
  }
}

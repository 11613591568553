/// Component: Pagination
/// @access public
/// @author Sebastian Liebner (sl@click-solutions.de)
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Pagination
/// sh-pagination($options: (
///
/// ));
///
@mixin sh-pagination($options: ()) {
  $_default: ();

  $_config: map-merge($_default, $options);
  $_component: ".#{$prefix}pagination";

  #{$_component} {
    display: flex;
    font-size: sh-font-size("small");
    align-items: center;

    &__item {
      @include sh-color("black-90");
      width: 30px;
      height: 30px;
      margin-right: to-rem(1);
      border-radius: 99rem;
      text-align: center;
      transition: all map-get(sh-transition('fade'), 'duration') map-get(sh-transition('fade'), 'easing');
    
      &:not([href]) {
        @include sh-background-color("black-10");
        @include sh-color("black-60");
        @include sh-font-weight("semibold");
      }

      &[href] {
        @include sh-hover(("bgColor": "white"));
      }
      &--next[href],
      &--prev[href] {
        @include sh-color("black-60");
        background-color: transparent;

        @include sh-hover(("bgColor": "white")) {
          @include sh-color("black-90");
          background: transparent; // no background hover yet
        }
      }

      &:first-child,
      &:last-child {
        @include sh-font-weight('normal');
        background-color: transparent;
        

        &:not([href]) {
          @include sh-opacity("disabled");
          pointer-events: none;
        }
      }
    }

    @content;
  }
}

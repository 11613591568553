/// Component: Tag
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Tag
/// sh-textfield($options: (
///
/// ));
///
@mixin sh-tag($options: ()) {
  $_default: (
    "icon": 'close'
  );

  $_config: map-merge($_default, $options);
  $_component: ".#{$prefix}tag ";

  $_tag-icon: map-get($_config, "icon");
  $_transition: sh-transition("fade");
  $_tag-transition: all map-get($_transition, "duration") map-get($_transition, "easing");

  #{$_component} {
    @include sh-font-weight("semibold");
    @include sh-font-size("xsmall");
    @include sh-background-color("white");
    @include sh-color("black-90");
    border-radius: to-rem(3);
    border: 1px solid sh-color("black-30");
    cursor: pointer;
    display: inline-block;
    font-family: inherit;
    line-height: 1;
    margin: 0 0 1.25em;
    min-width: auto;
    padding: to-rem(7) to-rem(8) to-rem(7) to-rem(9);
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: $_tag-transition;
    -webkit-appearance: none;

    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }

    & .icon::before {
      @include sh-color("black-60");
      @include sh-icon-reset() {
        @include sh-icon-char($_tag-icon);
      };
      font-size: 90%;
      display: inline-block;
      font-size: to-rem(12);
      margin-left: 0.5em;
      position: relative;
      top: to-rem(-1);
      transform: rotate(-45deg); // rotated close icon looks like plus
      transition: $_tag-transition;
    }

    @include sh-state("focus");
    @include sh-state("hover", ("bgColor": "white")) {
      & .icon::before {
        @include sh-color("black-90");
      }
    }
    @include sh-state("focus");
    @include sh-state("active", ("bgColor": "white"));

    &.is-selected {
      @include sh-color("black-90");
      @include sh-background-color("black-10");
      @include sh-border-color("black-30");
      @include sh-state("hover", ("bgColor": "black-10"));
      @include sh-state("focus");
      @include sh-state("active", ("bgColor": "black-10"));

      & .icon::before {
        transform: rotate(0deg); // becomes a close icon to look like an x
      }
    }

    &.is-disabled {
      @include sh-opacity("disabled");
    }

    // Dark theme

    [class*="bg-color-gray-shade"] &,
    .bg-color-black &,
    :host([theme="dark"]) & {
      @include sh-border-color("black-70");
      @include sh-color("black-10");
      @include sh-background-color("black-90");

      @include sh-state("hover", ("bgColor": "black-90"));
      @include sh-state("focus");
      @include sh-state("active", ("bgColor": "black-90"));
      
      & .icon::before {
        @include sh-color("black-10");
      }

      &.is-selected {
        @include sh-color("black-10");
        @include sh-background-color("black-70");
        @include sh-state("hover", ("bgColor": "black-80"));
        @include sh-state("focus");
        @include sh-state("active", ("bgColor": "black-80"));
      }
    }

    @content;
  }
}

@mixin ln-checkbox__hint($options: ()) {

  $_default: ();
  $config: map-merge($_default, $options);

  .checkbox__hint {
    color: #e7001d;
    margin-top: 0;
    display: block;
    margin-bottom: 0.888rem;
    padding: 0.5rem 0.888rem 0;
    font-size: 0.775rem;
    line-height: 1.2;
  }
}

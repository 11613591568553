///
/// @access public
///
///
@mixin sh-u-borders($options: ()) {
  $_defaults: ();

  $_config: map-merge($_defaults, $options);

  .border-none {
    border: none !important;
  }

  .border-top-none {
    border-top: none !important;
  }

  .border-right-none {
    border-right: none !important;
  }

  .border-bottom-none {
    border-bottom: none !important;
  }

  .border-left-none {
    border-left: none !important;
  }
}

/// Component: Tooltip
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Tooltip
/// sh-textfield($options: (
///
/// ));
///
@mixin sh-tooltip($options: ()) {
  $_defaults: (
    "text-color": sh-color("black-90"),
    "background-color": sh-color("white"),
    "font-size": sh-font-size("xsmall"),
    "rectangle-size": 0px,
    "rectangle-margin": 0px,
    "box-shadow": sh-shadow("modal"),
    "box-shadow-dark": sh-shadow("modal-dark"),
  );
  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}tooltip";

  $tooltip-rectangle-size: map-get($_config, "rectangle-size");
  $tooltip-rectangle-margin: map-get($_config, "rectangle-margin");

  #{$_component} {
    color: map-get($_config, "text-color");
    background-color: map-get($_config, "background-color");
    display: none; // only displays in is-visible state
    position: absolute;
    z-index: sh-z-index("tooltip");
    max-width: 340px;
    margin-top: -16px;
    padding: to-rem(10);
    text-align: left;
    line-height: to-rem(20);
    font-size: map-get($_config, "font-size");
    box-shadow: map-get($_config, "box-shadow");

    &.is-visible {
      display: block;
    }

    &__close-button {
      @include sh-color("black-60");
      position: absolute;
      right: to-rem(10);
      top: to-rem(5);
      font-size: sh-font-size("xsmall");
      display: none;
    }

    &.is-clickable &__close-button {
      display: block;
    }

    &__title {
      margin-top: 0;
      font-weight: bold;
    }

    &__content {
      margin-bottom: 0;
    }

    @content;
  }
}

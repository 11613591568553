///
/// @access public
///
///
@mixin sh-u-border-radius($options: ()) {
  $_defaults: (
    max-radius: 5,
  );

  $_config: map-merge($_defaults, $options);

  $_max-radius: map-get($_config, "max-radius");

  @for $i from 1 through $_max-radius {
    .border-radius-#{$i} {
      border-radius: #{$i}px;
    }
  }
  .border-radius-round {
    border-radius: 99rem;
  }
}

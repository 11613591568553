@mixin ln-spr-glossary($options: ()) {
  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .glossary-table {

    &--scroll-normal-user {
      height: calc(100% - 6.2rem);
      overflow-y: auto;
    }

    &--scroll-admin-user {
      height: calc(100% - 10rem);
      overflow-y: auto;
    }

    &__widget-name-column {
      width: 20%;
    }

    &__kpi-name-column {
      width: 25%;
    }

    &__widget-name-header {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        width: 10rem
      }
    }

    &__kpi-name-header {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        width: 12rem;
      }
    }

    &__description-header {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        width: 25rem;
      }
    }
  }

  .responsive-font {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      thead tr {
        width: 100%;
        top: 5rem;
        position: relative;
      }

      th {
        max-width: 100%;
      }
    }

    th, td {
      @extend .word-break-break-word;
      @media(max-width: 420px) {
        font-size: calc(0.1rem + 4.2vw);
      }
      @media(min-width: 1200px) and (max-width: 1499px) {
        font-size: calc(0.1rem + 0.84vw);
      }
      @media(min-width: 1500px) and (max-width: 1710px) {
        font-size: calc(0.1rem + 1vw);
      }
    }

    textarea {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        min-width: 100%;
        max-width: 100%;
      }
      width: 100%;
      max-width: 100%;
    }
  }
}

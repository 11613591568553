@mixin ln-u-scroll($options: ()) {
  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .scroll-container {
    position: absolute; 
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden; 
    overflow-y: scroll;
  }
}

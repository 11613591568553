@use 'sass:math';

@mixin ln-width-flex-calc($flex-units, $offset) {
  max-width: calc(((100% - #{$offset}) / 12) * #{$flex-units});
}

@mixin ln-margin-flex-calc($side, $flex-units, $offset) {
  margin-#{$side}: calc(((100% - #{$offset}) / 12) * #{$flex-units});
}

@mixin ln-spr-configuration($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  $_padding: sh-grid('gutter-width');
  $_half-padding: math.div($_padding, 2);

  .spr-configuration {

    .scrollable-table-content {
      overflow: auto;
      position: absolute;
      top: 95px;
      left: 0;
      right: 0;
      bottom: 87px;
      padding: 0 1rem 2rem;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        top: 150px;
      }

      @include media-query('md') {
        top: 130px;
        bottom: 87px;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          top: 180px;
        }
      }
      @media only screen and (min-width: 75em) {
        top: 87px;
        bottom: 87px;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          top: 150px;
        }
      }
    }

    &__external-table-button {
      height: 100%;
      width: 100%;
      min-width: 175px;
      position: relative;
      line-height: 57px;
      font-size: 1rem;
      cursor: pointer;

      overflow: hidden;
      white-space: nowrap;

      &::before {
        @include sh-color('black-60');
        font-family: "sh-icons-extended" !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-variant: normal !important;
        font-size: 0.6em;
        text-transform: none !important;
        speak: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        vertical-align: baseline;
        content: "\e81f";
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-30%);
        pointer-events: none;
      }

      &:hover::before {
        @include sh-color('orange');
      }

      &__disabled {
        cursor: default;
        opacity: 0.6;

        &:hover::before {
          @include sh-color('black-60');
        }
      }

      @media only screen and (max-width: 1200px) {
        min-width: 105px;
        max-width: 105px;
      }

      .align-content {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &__applicable-modal {
      .accordion__header {
        padding: 0;

        .accordion__title {
          padding: 0;
          white-space: nowrap;

          &:hover {
            color: unset;
          }
        }

        .checkbox-wrapper {
          width: 100%;
          max-width: calc(100% - 2.4rem);
          padding: $_half-padding $_padding $_half-padding $_half-padding;

          &:hover {
            color: sh-color('orange');
          }

          :before {
            top: 10%;
          }
          
          label {
            width: calc(100% - 1rem);
            vertical-align: middle;

            span {
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        button {
          align-self: center;
          width: 100%;
          height: 100%;
          text-align: right;
          padding: $_half-padding $_half-padding $_half-padding 0;

          &:hover {
            color: sh-color('orange');
          }

          &[aria-expanded=true]:before {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
            color: sh-color('orange');
          }
        }
      }

      .accordion__panel {
        .checkbox-wrapper:hover {
          color: sh-color('orange');
        }
      }
    }

    &__start-end-modal {
      .modal-dialog__container {
        @include media-query('md') {
          width: 870px;
        }
      }

      &__body {
        height: 100%;

        .selectbox__option {
          height: 49px;
        }
      }

      @include media-query('sm') {
        &__body {
          padding: $_half-padding $_half-padding 0;

          &__row {
            height: 56px;
            width: 100%;
            display: block;
            margin: 0;

            & > div {
              padding: 0 2px;
              position: absolute;

              & .selectbox {
                position: unset;
                margin: 0;

                & .selectbox__option-list {
                  float: left;
                }
              }
            }

            & > div:nth-child(1) {
              @include ln-width-flex-calc(2, 30px);
              margin-left: 0;
            }

            & > div:nth-child(2) {
              height: 56px;
              @include ln-width-flex-calc(2, 30px);
              @include ln-margin-flex-calc(left, 2, 30px);
            }

            & > div:nth-child(3) {
              height: 56px;
              @include ln-width-flex-calc(2, 30px);
              @include ln-margin-flex-calc(left, 4, 30px);
            }

            & > div:nth-child(4) {
              height: 56px;
              @include ln-width-flex-calc(2, 30px);
              @include ln-margin-flex-calc(left, 6, 30px);
            }

            & > div:nth-child(5) {
              height: 56px;
              @include ln-width-flex-calc(2, 30px);
              @include ln-margin-flex-calc(left, 8, 30px);

              & select {
                text-align-last: center;
              }
            }

            & > div:nth-child(6) {
              @include ln-width-flex-calc(2, 30px);
              @include ln-margin-flex-calc(left, 10, 30px);

              & select {
                text-align-last: center;
              }
            }
          }
        }
      }

      &__invalid-selection .selectbox {
        border: 2px solid sh-color('red');
      }
    }

    &__based-on-modal {
      &__body {
        height: 100%;

        .selectbox__option {
          height: 49px;
        }
      }

      @include media-query('sm') {
        &__body {
          padding: $_half-padding $_half-padding 0;

          &__row {
            height: 56px;
            width: 100%;
            display: block;
            margin: 0;

            & > div {
              padding: 0 2px;
              position: absolute;

              & .selectbox {
                position: unset;
                margin: 0;

                & .selectbox__option-list {
                  float: left;
                }
              }
            }

            & > div:nth-child(1) {
              @include ln-width-flex-calc(5, 30px);
              margin-left: 0;
            }

            & > div:nth-child(2) {
              height: 56px;
              @include ln-width-flex-calc(4, 30px);
              @include ln-margin-flex-calc(left, 5, 30px);

              & select {
                text-align-last: center;
              }
            }

            & > div:nth-child(3) {
              @include ln-width-flex-calc(3, 30px);
              @include ln-margin-flex-calc(left, 9, 30px);

              & select {
                text-align-last: center;
              }
            }
          }
        }
      }

      &__invalid-selection .selectbox {
        border: 2px solid sh-color('red');
      }
    }

    &__new-widget-modal {
      &__body {
        height: 100%;

        & .button-group {
          flex-direction: row;

          & .button {
            & i::before {
              margin: 0;
            }
          }
        }

        &__kpi-selection-optional {
          & button {
            margin: 14px 15px 30px -9px;
            font-size: 18px;

            &:hover {
              @include sh-color('orange');
            }
          }
        }
      }

      &__invalid-selection {
        & > div > .textfield, & .button, & > .selectbox {
          border: 2px solid sh-color('red');
        }
      }

      .button-item-style {
        text-align: left;
        border-radius: unset;
        border: none;
        width: 100%;
      }

      .save-button {
        border-radius: 100px 0 0 100px;
        min-width: 3rem;
        padding-right: 20px;
        padding-left: 28px;
        z-index: sh-z-index('dropdown') + 1;
      }

      .button-dropdown {
        margin-left: 0.05em;
        border-radius: 0 100px 100px 0;
        padding-left: 15px;
        padding-right: 12px;
        min-width: 1rem;
        height: fit-content;
        z-index: sh-z-index('dropdown') + 1;
      }

      .button-arrow-icon {
        margin-left: -1em;
        margin-right: -1.2em;
      }

      .dropdown_buttons {
        display: none;
        position: absolute;
        z-index: sh-z-index('dropdown');
        margin: 0;
        bottom: -73px;
        right: 22px;

        background-color: #ffffff;
        box-shadow: 0 0.1rem 0.6rem rgba(27, 27, 27, 0.1);
        border: 1px solid #e0e0e0;
        border-top-style: none;
        padding-top: 24.2px;

        @include media-query('md-down') {
          bottom: 35.8px;
          padding-top: 0;
          padding-bottom: 24.2px;
        }

        &.is-open {
          display: block;
        }

        .menu__item {
          padding: 12px 16px;
        }
      }

      .exists_dropdown_buttons {
        bottom: -33px;

        @include media-query('md-down') {
          bottom: 35.8px;
        }
        width: 146px;
      }

      .style-donut-graph-icon {
        position: relative;
        top: 32%;
        transform: translateY(-50%);
        padding-left: 1rem;
        font-size: 25px;
      }
    }
  }
}

big,
sub,
sub,
em,
small,
strong {
  line-height: inherit;
}

b,
strong {
  @include sh-font-weight("bold");
}

big {
  @include sh-font-size("xlarge");
}

sub {
  @include sh-color("black-60");
  line-height: 0.7;
}

sup {
  color: inherit;
  line-height: 0.7;
}

em {
  font-style: italic;
}

small {
  @include sh-font-size("small");
}

abbr,
acronym {
  text-transform: uppercase;
  @include sh-font-size("small");
  @include sh-color("black-60");
  cursor: help;
}

abbr {
  text-transform: none; 
  
  &[title] {
    border-bottom: 1px dotted sh-color("black-60");
    cursor: help;
  }
}

mark {
  @include sh-background-color("yellow");
}

///
/// @access public
///
///
@mixin sh-u-displays($options: ()) {

    $_defaults: (
        displays: (none, inline, inline-block, block, flex)
    );
  
    $_config: map-merge($_defaults, $options);

    $_displays: map-get($_config, "displays");

    @each $_display in $_displays {
        .display-#{$_display} {
            display: #{$_display} !important;
        }
    }
}

@mixin ln-equipment-checksheet($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .equipment-checksheet {
    &--detail {
      &--content.scrollable-content {
        bottom: 165px;
      }

      &--overlay-action-panel {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 4.8rem;
        box-shadow: 0 -4px 0.4rem rgba(27, 27, 27, 0.1);
        padding: 1rem;
        background-color: white;

        & .button {
          margin-bottom: 0;
        }
      }

      &--date-exclude {
        label {
          white-space: nowrap;
        }
      }
    }
  }

}

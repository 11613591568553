@mixin ln-quill($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .ql-container {
    font-size: inherit;
    font-family: inherit;
  }
  .ql-tooltip {
    z-index: 999;
  }
  .ql-editor {
    min-height: 84px;

    &__hint--validation {
      color: #e7001d;
      display: block;
      margin-bottom: 0.888rem;
      padding: 0.5rem 0.888rem 0;
      font-size: 0.775rem;
      line-height: 1.2;
    }
  }
}

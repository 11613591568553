/// Mixin that includes a proper :hover state
/// @access private
/// @group States
/// @param {Map} $options - Options to hand over values as a calculation basis
/// @prop {String} $options.bgColor [transparent] - Color name of the original color to apply effect on
/// @prop {String} $options.borderColor [false] - Color name to apply effect to the border, too
@mixin sh-hover($options:()) {

  $_defaults: (
    "bgColor": transparent,
    "borderColor": false,
    "transition": true,
  );
  $_config: map-merge($_defaults, $options);
  $_bgColor: map-get($_config, "bgColor");
  $_borderColor: map-get($_config, "borderColor");
  $_transition: map-get($_config, "transition");

  @if $_transition {
    transition: all map-get(sh-transition("fade"), "duration") map-get(sh-transition("fade"), "easing");
  }

  &:hover {
    background-color: sh-shift-darkness(sh-color($_bgColor));
    @if $_borderColor {
      border-color: sh-shift-darkness(sh-color($_borderColor));
    }  
    @content;
  }
  /*
  Override focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  // it overrides the cards shadow on hover
  // &:hover:not(:focus-visible) {
  //   box-shadow: none;
  // }
}

/// Mixin that includes a proper :focus state
/// @access private
/// @group States
/// @param {Map} $options - Options to hand over values as a calculation basis
/// @prop {String} $options.color ["orange"] - Color name to set the focus color
@mixin sh-focus($options:()) {

  $_defaults: (
    "color": "orange"
  );
  $_config: map-merge($_defaults, $options);
  $_color: map-get($_config, "color");
  
  /*
  Provide basic, default focus styles.
  */
  &:focus {
    @include sh-outline($_color);
    @content;
  }

  /*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
  */
  @at-root .js-focus-visible &:focus:not(.focus-visible) {
    box-shadow: none;
    outline: none;
  }

  /*
  Define a strong focus indicator for keyboard focus.
  */
  @at-root .js-focus-visible &:focus.focus-visible {
    @include sh-outline($_color);
    @content;
  }

  /*
  Remove default focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  &:focus:not(:focus-visible) {
    box-shadow: none;
    outline: none;
  }

  /*
  If :focus-visible is supported on this
  platform, provide enhanced focus styles for keyboard
  focus.
  */
  &:focus-visible {
    @include sh-outline($_color);
    @content;
  }
}

/// Mixin that includes a proper :active state
/// @access private
/// @group States
/// @param {Map} $options - Options to hand over values as a calculation basis
/// @prop {String} $options.bgColor [transparent] - Color name of the original color to apply effect on
/// @prop {String} $options.borderColor [false] - Color name to apply effect to the border, too
@mixin sh-active($options:()) {

  $_defaults: (
    "bgColor": transparent,
    "borderColor": false
  );
  $_config: map-merge($_defaults, $options);
  $_bgColor: map-get($_config, "bgColor");
  $_borderColor: map-get($_config, "borderColor");

  &:active {
    background-color: sh-shift-darkness(sh-color($_bgColor));
    @if $_borderColor {
      border-color: sh-shift-darkness(sh-color($_borderColor));
    }
    /*
    Remove default focus styles for mouse users ONLY if
    :focus-visible is supported on this platform.
    */
    &:not(:focus-visible) {
      box-shadow: none;
    }
    @content;
  }
}

/// Mixin that includes a proper :disabled state
/// @access private
/// @group States
@mixin sh-disabled() {
  &:disabled {
    @include sh-opacity("disabled");
    pointer-events: none;
    cursor: not-allowed;
    @content;
  }
}

/// Mixin that includes a named state
/// @access public
/// @group States
/// @param {String} $name - Name of the desired state
/// @param {Map} $options - Options to hand over values as a calculation basis
/// @prop {String} $options.bgColor [transparent] - Color name of the original color to apply effect on
/// @prop {String} $options.borderColor [false] - Color name to apply effect to the border, too

@mixin sh-state($name, $options: ()) {
  @if $name == "hover" {
    @include sh-hover($options) {
      @content;
    };
  }

  @if $name == "focus" {
    @include sh-focus($options) {
      @content;
    };
  }

  @if $name == "active" {
    @include sh-active($options) {
      @content;
    };
  }

  @if $name == "disabled" {
    @include sh-disabled() {
      @content;
    };
  }
}

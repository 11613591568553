@mixin ln-dispatch-of-tomorrow($options: ()) {
  $_defaults: ();
  $config: map-merge($_defaults, $options);

  .dispatch-of-tomorrow {
    &__button {
      &:disabled {
        pointer-events: auto;
        cursor: default;
      }

      &__container {
        display: inline-block;
        position: relative;
      }

      &__loading-animation {
        min-width: 1em;
        min-height: 1em;
        width: 20px;
        height: 20px;
        font-size: sh-font-size("xsmall");
        line-height: sh-line-height("xsmall");
        position: absolute;
        top: 10%;
      }
    }

    &__header {
      @extend h4;
      display: flex;
      justify-content: space-between;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;

      @media screen and (max-width: breakpoint-max('sm')) {
        display: block;

        .countdown-timer {
          margin-top: 0.5em;
        }
      }

      h4 {
        margin: 0;
      }
    }

    &__time-slot-wrapper {
      min-height: 12em;

      .time-slot {
        margin-top: 0.4em;
      }
    }

    &__schedule-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 25rem;
    }

    &__schedule-result {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      max-width: 30em;

      p {
        margin-bottom: 1em;
      }
    }

    &__icon-success {
      @include _dot-icon($icon: 'check', $bgColor: 'green');
    }

    &__icon-failure {
      @include _dot-icon($icon: 'close', $bgColor: 'red');
    }

    &__icon-timeout:before {
      color: sh-color('black-60');
      font-size: 4rem;
    }
  }
}

@mixin _dot-icon($icon, $bgColor) {
  &:after {
    @include sh-indicator((
      'icon': $icon,
      'bgColor': $bgColor,
    )) {
      content: unquote('\\' + sh-icon-char($icon));
      font-size: 2.5em;
      width: 4rem;
      height: 4rem;
    }
  }
}

@use 'sass:math';

@mixin ln-timeline($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  $_check_mark_color: white;
  $_empty_color: #f1f1f1;
  $_empty_color_border: #ddd;
  $_main_color: #009999;

  $_point_size: 22px;
  $_timeline_text_size: 13px;

  $_first_item_offset: 30px;

  $_z_index_baseline: 99;

  .timeline {
    list-style-type: none;
    font-size: $_timeline_text_size;
    padding-left: 0;

    /* against issue when there are more timelineMilestones than can be displayed in one row */
    overflow-x: auto;

    ul {
      display: table-row;
    }

    li {
      transition: all 200ms ease-in;
      display: table-cell;
    }

    .status {
      padding: 10px 5px 0 5px;
      position: relative;
      transition: all 200ms ease-in;
      text-align: center;
      margin-top: 10px;

      &.border {
        border-top: 8px solid $_empty_color;
        z-index: ($_z_index_baseline - 2);
      }

      &.no-border {
        border-top: 8px solid transparent;
        z-index: ($_z_index_baseline - 3);
      }

      &:before {
        content: '';
        width: $_point_size;
        height: $_point_size;
        background-color: $_empty_color;
        border-radius: $_point_size;
        border: 1px solid $_empty_color_border;
        position: absolute;
        top: -(4.2px + math.div($_point_size, 2));
        transition: all 200ms ease-in;
        left: 50%;
        margin-left: -(math.div($_point_size, 2));
        z-index: ($_z_index_baseline - 1);
        text-align: center;
      }

      &.status-ok {
        &:before {
          content: '\2713';
          font-weight: bolder;
          padding-right: 1px;
          color: $_check_mark_color;
          font-size: ($_point_size*0.65);
          z-index: $_z_index_baseline;
          line-height: 1.3rem;
        }
      }

      &.status-first {
        text-align: center;

        &:before {
          margin-left: $_first_item_offset;
          left: 0;
        }

        .milestone-text-wrapper {
          margin-left: -2%;
        }
      }
    }

    .status-line-start {
      position: absolute;
      top: -8px;
      left: 0;
      width: 50%;
      height: 8px;
      background-color: $_empty_color;
      z-index: ($_z_index_baseline - 8);
    }

    .status-line-end {
      position: absolute;
      top: -8px;
      left: $_first_item_offset + (math.div($_point_size, 2));
      width: 100%;
      height: 8px;
      background-color: $_empty_color;
      z-index: ($_z_index_baseline - 8);
      overflow-y: hidden;
    }

    li.complete {
      .status {

        &.border {
          border-top: 8px solid $_main_color;
        }

        &.no-border {
          border-top: 8px solid transparent;
        }

        &:before {
          background-color: $_main_color;
          border: none;
          transition: all 200ms ease-in;
        }
      }

      .status-line-start {
        background-color: $_main_color;
      }

      .status-line-end {
        background-color: $_main_color;
      }
    }

    @media (min-device-width: 320px) and (max-device-width: 700px) {
      .timeline {
        list-style-type: none;
        display: block;

        .li {
          transition: all 200ms ease-in;
          display: flex;
          width: inherit;
        }

        .status {
          &:before {
            transition: all 200ms ease-in;
          }
        }
      }
    }
  }
}

/// Component: Loading Animation
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Loading Animation
/// sh-textfield($options: (
///
/// ));
///
@mixin sh-loading-animation($options: ()) {
  $_defaults: (
    "color": sh-color("orange"),
    "size": 2.222222rem,
    "duration": 1.2s,
  );
  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}loading-animation";

  $_color: map-get($_config, "color");
  $_size: map-get($_config, "size");
  $_duration: map-get($_config, "duration");

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  #{$_component} {
    animation: spin 1s linear infinite;
    border: 5px solid sh-color("black-10");
    border-top-color: sh-color("orange");
    border-radius: 50%;
    height: 60px;
    width: 60px;

    @content;
  }
}

@mixin ln-u-positionings($options: ()) {

  $_defaults: (
    directions: ("top", "right", "bottom", "left")
  );

  $_config: map-merge($_defaults, $options);
  
  $_directions: map-get($_config, 'directions');

  @each $_direction in $_directions {
    @include _util-layout-direction-percent-helper($_direction);
  }
}

@mixin _util-layout-direction-percent-helper($_direction) {
    @for $i from 0 to 101 {
      .#{$_direction}-percent-#{$i} {
        #{$_direction}: $i#{'%'} !important;
      }
    }
}

@mixin ln-positions($options: ()) {
  $_defaults: (
  );

  $config: map-merge($_defaults, $options);
  
  .vertical-align-middle, .vertical-align-before-middle:before {
    vertical-align: middle;
  }
}

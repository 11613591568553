@mixin ln-grid($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .container {
    &__panel {
      padding-top: 1rem;
    }

    &--full-width {
      max-width: 100%;

      @include media-query('xl') {
        max-width: 80em;
      }
    }
  }
}

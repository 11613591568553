///
/// @access public
///
///
@mixin sh-u-floatings($options: ()) {
  $_defaults: ();

  $_config: map-merge($_defaults, $options);

  .float-left {
    float: left !important;
  }

  .float-right {
    float: right !important;
  }
}

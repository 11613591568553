input {
  // Normalize includes `font: inherit;`, so `font-family`. `font-size`, etc are
  // properly inherited. However, `line-height` isn"t inherited there.
  line-height: inherit;
}

input[type="radio"],
input[type="checkbox"] {
  // Apply a disabled cursor for radios and checkboxes.
  //
  // Note: Neither radios nor checkboxes can be readonly.
  &:disabled {
    cursor: not-allowed;
  }
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  // Remove the default appearance of temporal inputs to avoid a Mobile Safari
  // bug where setting a custom line-height prevents text from being vertically
  // centered within the input.
  // See https://bugs.webkit.org/show_bug.cgi?id=139848
  // and https://github.com/twbs/bootstrap/issues/11266
  -webkit-appearance: listbox;
}

input[type="search"] {
  // This overrides the extra rounded corners on search inputs in iOS so that our
  // `.form-control` class can properly style them. Note that this cannot simply
  // be added to `.form-control` as it"s not specific enough. For details, see
  // https://github.com/twbs/bootstrap/issues/11586.
  -webkit-appearance: none;
}

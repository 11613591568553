/// Component: Steps Bar
/// @access public
/// @author Sebastian Liebner (sl@click-solutions.de), Michael Schultze (ms@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Steps Bar
/// @include sh-steps-bar($options: (
///     color: sh-color('orange')
/// ));
///
@mixin sh-steps-bar($options: ()) {
  $_default: (
    color: sh-color("orange-dark"),
  );
  $_config: map-merge($_default, $options);
  $_component: ".#{$prefix}steps-bar";
  $_color: map-get($_config, "color");

  #{$_component} {
    display: flex;
    width: 100%;

    &__item {
      flex: 1 1 0%;
      position: relative;

      &::before,
      &::after {
        border: 1px dashed sh-color("black-10");
        display: block;
        content: "";
        position: absolute;
        top: calc(1rem + 20px);
      }

      &::after {
        left: calc(50% + 30px);
        right: 0;
      }

      &::before {
        right: calc(50% + 30px);
        left: 1px;
      }

      &:first-child {
        &::before {
          display: none;
        }
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }

    &__number {
      @include sh-font-weight("bold");
      @include sh-background-color("white");
      @include sh-color("black-90");
      @include sh-opacity("disabled");
      width: 40px;
      height: 40px;
      border: 1px solid sh-color("black-30");
      border-radius: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1rem auto;
    }

    &__title,
    &__text {
      @include sh-color("black-60");
      @include sh-font-size("small");
      @include sh-opacity("disabled");
      text-align: center;
      line-height: 20px;
    }

    &__title {
      font-weight: 600;
      line-height: 30px;
    }

    &__item {
      &.is-completed {
        .steps-bar__number {
          @include sh-border-color("black-10");
          @include sh-background-color("black-10");
          @include sh-color("black-90");
          opacity: 1;
        }

        .steps-bar__title,
        .steps-bar__text {
          @include sh-color("black-60");
          opacity: 1;
        }
      }

      &.is-current {
        .steps-bar__number {
          @include sh-color("white");
          border-color: $_color;
          background-color: $_color;
          opacity: 1;
        }

        .steps-bar__title,
        .steps-bar__text {
          @include sh-color("black-60");
          opacity: 1;
        }
      }
    }

    @content;
  }
}

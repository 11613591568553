@import 'assets/sass/ui-marcom';

@import 'quill/dist/quill.bubble';
@import 'quill/dist/quill.snow';

@import 'assets/sass/base/reset';
@import 'assets/sass/objects/aspect-ratio';
@import 'assets/sass/objects/grid';
@import 'assets/sass/components/calendar-scheduler';
@import 'assets/sass/components/detail-overlay';
@import 'assets/sass/components/detail-header';
@import 'assets/sass/components/detail-filter-panel';
@import 'assets/sass/components/detail-information-panel';
@import 'assets/sass/components/diagram';
@import 'assets/sass/components/legend';
@import 'assets/sass/components/loading-animation';
@import 'assets/sass/components/overlay-bottom-panel';
@import 'assets/sass/components/list-item';
@import 'assets/sass/components/toggle-button';
@import 'assets/sass/components/button-group';
@import 'assets/sass/components/filter-panel';
@import 'assets/sass/components/item-panel';
@import 'assets/sass/components/header';
@import 'assets/sass/components/external-links';
@import 'assets/sass/components/multi-column';
@import 'assets/sass/components/my-equipment-switch';
@import 'assets/sass/components/activities';
@import 'assets/sass/components/multi-selectbox';
@import 'assets/sass/components/widget';
@import 'assets/sass/components/drag-and-drop-widgets';
@import 'assets/sass/components/textarea';
@import 'assets/sass/components/info-list';
@import 'assets/sass/components/bare-list';
@import 'assets/sass/components/tabs';
@import 'assets/sass/components/file-list';
@import 'assets/sass/components/calendar-sheet';
@import 'assets/sass/components/toast-message';
@import 'assets/sass/components/google-maps';
@import 'assets/sass/components/overview-list';
@import 'assets/sass/components/form';
@import 'assets/sass/components/collection';
@import 'assets/sass/components/messaging-center';
@import 'assets/sass/components/additional-text';
@import 'assets/sass/components/selectbox';
@import 'assets/sass/components/main-container';
@import 'assets/sass/components/navigation-bar';
@import 'assets/sass/components/grid-layout';
@import 'assets/sass/components/intro-slider-gallery';
@import 'assets/sass/components/hero-block';
@import 'assets/sass/components/fixed-flex-layout';
@import 'assets/sass/components/search-field';
@import 'assets/sass/components/calendar';
@import 'assets/sass/components/terms-and-conditions';
@import 'assets/sass/components/accordion-reverse';
@import 'assets/sass/components/table';
@import 'assets/sass/components/tooltip';
@import 'assets/sass/components/date-time-picker';
@import 'assets/sass/components/button';
@import 'assets/sass/components/progress';
@import 'assets/sass/components/multiline-radio';
@import 'assets/sass/components/checkbox';
@import 'assets/sass/components/month-picker';
@import 'assets/sass/components/alert';
@import 'assets/sass/components/quill';
@import 'assets/sass/components/timeline';
@import 'assets/sass/components/modal-dialog';
@import 'assets/sass/components/my-customer-panel';
@import 'assets/sass/components/icon-container';
@import 'assets/sass/components/equipment-checksheet';
@import 'assets/sass/components/equipment-documents';
@import 'assets/sass/components/selectbox__hint';
@import 'assets/sass/components/textfield__hint';
@import 'assets/sass/components/textfield';
@import 'assets/sass/components/checkbox__hint';
@import 'assets/sass/components/accordion';
@import 'assets/sass/components/nested-multi-selectbox';
@import 'assets/sass/components/spr-table';
@import 'assets/sass/components/spr-configuration';
@import 'assets/sass/components/search-multi-selectbox';
@import 'assets/sass/components/spr-widget';
@import 'assets/sass/components/spr-detail-overlay';
@import 'assets/sass/components/nested-selectbox';
@import 'assets/sass/components/spr-glossary';
@import 'assets/sass/components/service-metrics';
@import 'assets/sass/components/card-slider';
@import 'assets/sass/components/digital-compass';
@import 'assets/sass/components/multiline-input';
@import 'assets/sass/components/checkout-steps';
@import 'assets/sass/components/remote-work-force';
@import 'assets/sass/components/meta-menu';
@import 'assets/sass/components/pagination';
@import 'assets/sass/components/menu';
@import 'assets/sass/components/evaluation';
@import 'assets/sass/components/equipment-redesign';
@import 'assets/sass/chat-bot/chat-bot';
@import 'assets/sass/components/label-status';
@import 'assets/sass/components/_spr_guardian_overview.scss';
@import 'assets/sass/components/dispatch-of-tomorrow';

@import 'assets/sass/utilities/text-overflow-ellipsis';
@import 'assets/sass/utilities/only-ie';
@import 'assets/sass/utilities/margin';
@import 'assets/sass/utilities/dimensions';
@import 'assets/sass/utilities/positionings';
@import 'assets/sass/utilities/text-utils';
@import 'assets/sass/utilities/padding';
@import 'assets/sass/utilities/scroll';
@import 'assets/sass/utilities/overflow';
@import 'assets/sass/utilities/border';
@import 'assets/sass/components/varian';
@import 'assets/sass/components/soa';

@import '@angular/cdk/overlay-prebuilt.css';

/* UI MarCom Objects */

@include sh-aspect-ratio();
@include sh-bare-list();
@include sh-block-grid();
@include sh-card();
@include sh-collection();
@include sh-collection-item();
@include sh-grid();
@include sh-icon();
@include sh-media-object();
@include sh-multi-column();

/* teamplay Fleet objects */

@include ln-aspect-ratio();
@include ln-grid();

/* UI MarCom Components */

@include sh-accordion();
@include sh-alert();
@include sh-application-bar();
@include sh-back-to-top();
@include sh-breadcrumbs();
@include sh-button();
@include sh-card-slider();
@include sh-checkbox();
@include sh-date-time-picker();
@include sh-disclaimer();
@include sh-dropdown() {
  &__panel .menu .bare-list .is-open > div {
    word-wrap: break-word;
  }
  &--right-unused &__panel {
    right: unset;
  }
  &--center &__panel.menu {
    left: inherit;
    right: -120px;
    @include media-query('xs') {
      right: -40px;
    }
  }
  &--center-mobile {
    @include media-query('sm-down') {
      word-wrap: break-word;
      width: 200px;
      white-space: normal;
    }
  }
}

@include sh-feedback();
@include sh-figure();
@include sh-footer();
@include sh-hero-block();
@include sh-label();
@include sh-legal-links();
@include sh-list() {
  margin-left: 0;
  &__item {
    margin-right: 0.888rem;
  }
}

@include sh-logo(
  (
    file-path: '/assets/images'
  )
);
@include sh-meta-menu();
@include sh-menu();
@include sh-modal-dialog() {
  &--form {
    .modal-dialog__container {
      @include media-query('md') {
        width: 800px;
      }
    }
  }

  &--wide {
    .modal-dialog__container {
      @include media-query('md') {
        width: 80%;
      }
    }
  }

  &--full-content {
    .modal-dialog__body {
      overflow: inherit;
    }
  }
}

@include sh-multimedia-container();
@include sh-navigation-bar();

/*
 This module has a name clash with d3 charts. I copied this code to sh-detail-overlay and changed it to use a prefix.
 The best course of action would be to set the prefix variable for the whole DDC. Too much work at this point.
 @include sh-overlay();
*/

@include sh-pagination();
@include sh-progress();
@include sh-radio() {
  &--inline + label {
    padding-left: 0.5rem;
  }
}

@include sh-search-field();
@include sh-selectbox();
@include sh-sitemap() {
  &--font-size {
    font-size: 1rem;
  }
}

@include sh-social-list();
@include sh-stage();
@include sh-steps-bar();
@include sh-tabs();
@include sh-tag();
@include sh-textfield();
@include sh-toggle-switch() {
  .my-customer-tab &__checkbox {
    top: unset;
  }
}

@include sh-tooltip();
@include sh-user-identifier();
@include sh-inpage-navigation();
@include ln-calendar-scheduler();

/* teamplay Fleet components */

@include ln-accordion-reverse();
@include ln-button-group();
@include ln-calendar();
@include ln-overlay(
  (
    lib-prefix: 'ddc-'
  )
);
@include ln-detail-overlay();
@include ln-detail-information-panel();
@include ln-detail-filter-panel();
@include ln-detail-header();
@include ln-external-links();
@include ln-header();
@include ln-filter-panel();
@include ln-info-list();
@include ln-item-panel();
@include ln-list-item();
@include ln-multi-column(
  (
    item-spacing-bottom: 1rem
  )
);
@include ln-multi-selectbox();
@include ln-my-equipment-switch();
@include ln-toggle-button();
@include ln-overlay-bottom-panel();
@include ln-widget();
@include ln-drag-and-drop-widgets();
@include ln-textarea();
@include sh-diagram();
@include sh-legend();
@include sh-loading-animation();
@include ln-loading-animation();
@include ln-file-list();
@include ln-calendar-sheet();
@include ln-toast-message();
@include ln-google-maps();
@include ln-overview-list();
@include ln-form();
@include ln-collection();
@include ln-messaging-center();
@include ln-additional-text();
@include ln-selectbox();
@include ln-main-container();
@include ln-navigation-bar();
@include ln-grid-layout();
@include ln-hero-block();
@include ln-fixed-flex-layout();
@include ln-search-field();
@include ln-terms-and-conditions();
@include ln-table();
@include ln-tooltip();
@include ln-bare-list();
@include ln-tabs();
@include ln-date-time-picker();
@include ln-button();
@include ln-progress();
@include ln-multiline-radio();
@include ln-alert();
@include ln-quill();
@include ln-checkbox();
@include ln-month-picker();
@include ln-timeline();
@include ln-modal-dialog();
@include ln-my-customer-panel();
@include ln-icon-container();
@include ln-equipment-checksheet();
@include ln-equipment-documents();
@include ln-selectbox__hint();
@include ln-textfield__hint();
@include ln-textfield();
@include ln-checkbox__hint();
@include ln-accordion();
@include ln-nested-multi-selectbox();
@include ln-spr-table();
@include ln-spr-configuration();
@include ln-search-multi-selectbox();
@include ln-spr-widget();
@include ln-spr-detail-overlay();
@include ln-nested-selectbox();
@include ln-spr-glossary();
@include ln-service-metrics();
@include ln-card-slider();
@include ln-digital-compass();
@include ln-multiline-input();
@include ln-checkout-steps();
@include ln-remote-work-force();
@include ln-meta-menu();
@include ln-pagination();
@include ln-menu();
@include ln-evaluation();
@include ln-equipment-redesign();
@include ln-label-status();
@include ln-spr-guardian-overview();
@include ln-dispatch-of-tomorrow();

// Relevant for dummy???
@include ln-varian();
@include ln-soa();

/* UI MarCom Utilities */

@include sh-u-alignments();
@include sh-u-background-colors();
@include sh-u-background-images();
@include sh-u-border-radius();
@include sh-u-borders();
@include sh-u-colors();
@include sh-u-dimensions();
@include sh-u-displays();
@include sh-u-flex();
@include sh-u-floatings();
@include sh-u-font-families();
@include sh-u-font-sizes();
@include sh-u-font-styles();
@include sh-u-font-weights();
@include sh-u-image-replacements();
@include sh-u-image-sizes();
@include sh-u-margin();
@include sh-u-padding();
@include sh-u-positionings();
@include sh-u-text-decorations();
@include sh-u-visibilities();

/* teamplay Fleet utilities */

@include ln-text-overflow-ellipsis();
@include ln-u-margin();
@include ln-text-utils();
@include ln-u-padding();
@include ln-u-scroll();
@include ln-overflow();
@include ln-u-dimensions();
@include ln-u-positionings();
@include ln-positions();
@include ln-u-border();

/* Service Now Chat Bot */
@include sn-chat-bot();

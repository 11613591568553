@mixin ln-checkbox($options: ()) {
  $_defaults: ();
  $config: map-merge($_defaults, $options);


  .checkbox-aligned + label {
    margin: 0;
    padding-left: 2.5rem;
    border-bottom: 1px solid sh-color('black-10');
    display: block;

    &::before {
      /* margin-top: 1px;  */
      left: 1rem !important;
      top: 32% !important;
    }

    &:focus + label::before {
      border-bottom-color: sh-color('orange');
    }
    
    &:hover {
      background: sh-color('black-5');
    }
  }

  .checkbox-aligned + label:hover > button[aria-expanded='true'] {
    background: sh-color('black-5');
    border-bottom-style: none;
  }

  .checkbox-aligned + label > button[aria-expanded='false'] {
    border-bottom-style: none;
  }

  .checkbox + .checkbox-vertical_align {
    &::before {
      top: inherit !important;
    }
  }

  .#{$prefix}checkbox {
    &:indeterminate + label::before {
      background-color: sh-color('orange');
      border: 1px solid sh-color('orange');
      content: sh-icon('minus');
      font-family: 'sh-icons-extended' !important;
      font-size: 0.98rem !important;
      font-weight: sh-font-weight('light');
      color: sh-color('white');
      text-align: center;
      padding-top: 1px;
      text-indent: -1px;
    }
  }

  .spr-centered-checkbox {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    overflow: visible;
    text-align: center;
  }
}

/// Component: Menu
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Menu
/// sh-textfield($options: (
///
/// ));
///
@mixin sh-menu($options: ()) {
  $_defaults: (
    "x-spacer": to-rem(15),
    "y-spacer": to-rem(10)
  );
  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}menu";

  #{$_component} {
    padding: 0;
    list-style: none;
    
    &__item {
      @include sh-background-color("white");
      @include sh-color("black-90");
      cursor: pointer;
      margin: 0;
      padding: map-get($_config, "y-spacer") map-get($_config, "x-spacer");
      text-overflow: ellipsis;
      display: block;
      white-space: nowrap;
      text-align: left;

      // States
      @include sh-hover((bgColor: "white")) {
        color: inherit;
      };
      @include sh-focus() {
          position: relative; // elivates element to show focus ring correctly
      };
      @include sh-active((bgColor: "white")) {
        box-shadow: none;
      };

      &.is-active,
      &.is-current,
      &.is-selected {
        @include sh-background-color("black-10");
        color: inherit;
        // box-shadow: none; // disabled overriding focus outline in version 2.4
        outline: none;
      }
    }
    
    @content;
  }
}

///
/// @access public
///
///
@mixin sh-u-font-sizes($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);
    
  .font-size-xxlarge {
    font-size: sh-font-size("xxlarge") !important;
  }
  
  .font-size-xlarge {
    font-size: sh-font-size("xlarge") !important;
    line-height: sh-line-height("xlarge") !important;
  }

  .font-size-large {
    font-size: sh-font-size("large") !important;
    line-height: sh-line-height("large") !important;
  }
  
  .font-size-medium {
    font-size: sh-font-size("medium") !important;
    line-height: sh-line-height("medium") !important;
  }
  
  .font-size-small {
    font-size: sh-font-size("small") !important;
    line-height: sh-line-height("small") !important;
  }
  
  .font-size-xsmall {
    font-size: sh-font-size("xsmall") !important;
    line-height: sh-line-height("xsmall") !important;
  }

  .font-size-xxsmall {
    font-size: sh-font-size("xxsmall") !important;
  }
}

/// Component: Toggle Switch
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Toggle Switch
/// sh-textfield($options: (
///
/// ));
///
@mixin sh-toggle-switch($options: ()) {
  $_default: ();
  $_config: map-merge($_default, $options);
  $_component: ".#{$prefix}toggle-switch";

  $_label-margin: to-rem(15);

  $_width: to-rem(40);
  $_height: to-rem(20);
  $_border-color: sh-color("black-60");
  $_border-width: to-rem(1);
  $_padding: to-rem(3);
  $_paddle-size: $_height - $_padding * 2;

  $_width-large: to-rem(60);
  $_height-large: to-rem(30);
  $_padding-large: to-rem(4);
  $_paddle-size-large: $_height-large - $_padding-large * 2;

  $transition: sh-transition("move");

  #{$_component} {
    @include sh-color("black-90");
    position: relative;

    &__checkbox {
      width: $_width;
      height: $_height;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      cursor: pointer;
    }

    &__checkbox + &__label {
      position: relative;
      padding-left: $_width + $_label-margin;
      height: $_height;
      display: inline-flex;
      align-items: center;
      cursor: pointer;

      // track
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: $_width;
        height: $_height;
        top: 0;
        left: 0;
        border-radius: 99rem;
        border: $_border-width solid $_border-color;
        transition: border-color map-get($transition, "duration") * 0.5,
                    background-color map-get($transition, "duration") * 0.5; // improve UX by reducing low contrast phase during transition
        background-color: sh-color("white");
      }

      // paddle
      &::after {
        content: "";
        display: block;
        position: absolute;
        left: $_padding;
        top: $_padding;
        width: $_paddle-size;
        height: $_paddle-size;
        border-radius: 99rem;
        background: sh-color("black-60");
        transition: background-color map-get($transition, "duration") * 0.5,
              left map-get($transition, "duration") map-get($transition, "easing");
      }
    }

    &__checkbox:checked + &__label {
      // track
      &::before {
        @include sh-background-color("orange");
        border-color: sh-color("orange");
      }

      // paddle
      &::after {
        @include sh-background-color("white");
        left: $_width - $_paddle-size - $_padding;
      }
    }

    &__checkbox:focus-visible + &__label::before {
      @include sh-outline;
    }

    &__checkbox:hover + &__label::before {
      @include sh-hover-background-color("white");
        // hover-transition disabled since it overrides check-transition 
        // transition: background-color map-get(sh-transition("fade"), "duration") map-get(sh-transition("fade"), "easing"),
        //     color map-get(sh-transition("fade"), "duration") map-get(sh-transition("fade"), "easing");
    }
    &__checkbox:checked:hover + &__label::before {
      @include sh-hover-background-color("orange");
    }

    &__checkbox:disabled + &__label {
      cursor: not-allowed;
      @include sh-opacity("disabled");
    }

    &--large {
      #{$_component}__checkbox {
        width: $_width-large;
        height: $_height-large;
        background: transparent;
      }

      #{$_component}__checkbox + #{$_component}__label {
        padding-left: $_width-large + $_label-margin;
        height: $_height-large;

        // track
        &::before {
          width: $_width-large;
          height: $_height-large;
        }

        // paddle
        &::after {
          left: $_padding-large;
          top: $_padding-large;
          width: $_paddle-size-large;
          height: $_paddle-size-large;
        }
      }

      #{$_component}__checkbox:checked + #{$_component}__label {
        // paddle
        &::after {
          left: $_width-large - $_paddle-size-large - $_padding-large;
        }
      }
    }

    @content;
  }
}


@mixin _margin-helper-tiny($options) {

  $direction: map-get($options, 'direction');
  $max-items: map-get($options, 'max-items');

  $prefix: '';

  @if($direction) {
    $prefix: "-#{$direction}-tiny";
  }

  .margin#{$prefix}-auto {
    margin#{$prefix}: auto !important;
  }

  @for $i from 0 to $max-items {
    .margin#{$prefix}-#{$i} {
      margin-#{$direction}: $i * 0.2rem !important;
    }
  }
}

@mixin ln-u-margin($options: ()) {

  $_defaults: (
    directions: ("top", "right", "bottom", "left"),
    max-items: 11
  );

  $_config: map-merge($_defaults, $options);


  @each $direction in map-get($_config, 'directions') {
    @include _margin-helper-tiny((
      direction: $direction,
      max-items: map-get($_config, 'max-items')
    ));
  }
}

/// Component: Feedback
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Feedback
/// sh-feedback($options: (
///
/// ));
///
@mixin sh-feedback($options: ()) {
  $_defaults: ();
  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}feedback";

  #{$_component} {
    &__button {
      color: sh-color("black-60");

      i::before {
        font-size: 2rem;
        left: -6px;
        position: relative;
      }

      &:disabled.is-selected,
      &:hover {
        color: sh-color("black-90");
      }

      @include sh-state("disabled");
    }

    // Dark theme

    [class*="bg-color-gray-shade"] &,
    .bg-color-black & {
      &__button {
        color: sh-color("black-40");

        &:disabled.is-selected,
        &:hover {
          color: sh-color("white");
        }
      }

      .feedback__modal-container {
        @include sh-background-color("black");
        @include sh-shadow("modal-dark");
      }
    }

    @content;
  }
}

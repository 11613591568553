/// Component: Footer
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Footer
/// sh-footer($options: (
///
/// ));
///
@mixin sh-footer($options: ()) {
  $_defaults: ();
  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}footer";

  #{$_component} {
    margin-top: 5rem;
    padding-bottom: 3rem;

    &__col {
      display: flex;
      flex-wrap: wrap;
    }

    &__col-title {
      font-size: sh-font-size("small");
      margin: to-rem(27) 0 to-rem(20);
    }

    &__legal-links {
      width: 100%;
      margin-top: 0.1rem;

      @include media-query("md") {
        order: 1;
      }
    }

    &__social-channels {
      margin-top: 1.1rem;
      margin-bottom: 1.5rem;
      width: 100%;

      @include media-query("md") {
        order: 2;
      }
    }

    &__search {
      display: none;

      @include media-query("lg") {
        display: block;
      }
    }

    // custom cascading styles

    hr {
      margin: to-rem(20) 0 to-rem(20);
    }

    @content;
  }
}

///
/// @access public
///
///
@mixin sh-u-flex($options: ()) {

  $_defaults: (
    media-queries: sh-breakpoint-list()
  );

  $_config: map-merge($_defaults, $options);

  $_media-queries: map-get($_config, "media-queries");
  
  @each $_breakpoint in $_media-queries {
    @include media-breakpoint-up($_breakpoint) {
      $_infix: "-#{$_breakpoint}";
      
      @if ($_breakpoint == "xs") {
        $_infix: ""; 
      }

      .flex#{$_infix}-row            { flex-direction: row !important; }
      .flex#{$_infix}-column         { flex-direction: column !important; }
      .flex#{$_infix}-row-reverse    { flex-direction: row-reverse !important; }
      .flex#{$_infix}-column-reverse { flex-direction: column-reverse !important; }

      .flex#{$_infix}-wrap         { flex-wrap: wrap !important; }
      .flex#{$_infix}-nowrap       { flex-wrap: nowrap !important; }
      .flex#{$_infix}-wrap-reverse { flex-wrap: wrap-reverse !important; }
      .flex#{$_infix}-fill         { flex: 1 1 auto !important; }
      .flex#{$_infix}-grow-0       { flex-grow: 0 !important; }
      .flex#{$_infix}-grow-1       { flex-grow: 1 !important; }
      .flex#{$_infix}-shrink-0     { flex-shrink: 0 !important; }
      .flex#{$_infix}-shrink-1     { flex-shrink: 1 !important; }

      .justify-content#{$_infix}-start   { justify-content: flex-start !important; }
      .justify-content#{$_infix}-end     { justify-content: flex-end !important; }
      .justify-content#{$_infix}-center  { justify-content: center !important; }
      .justify-content#{$_infix}-between { justify-content: space-between !important; }
      .justify-content#{$_infix}-around  { justify-content: space-around !important; }

      .align-items#{$_infix}-start    { align-items: flex-start !important; }
      .align-items#{$_infix}-end      { align-items: flex-end !important; }
      .align-items#{$_infix}-center   { align-items: center !important; }
      .align-items#{$_infix}-baseline { align-items: baseline !important; }
      .align-items#{$_infix}-stretch  { align-items: stretch !important; }

      .align-content#{$_infix}-start   { align-content: flex-start !important; }
      .align-content#{$_infix}-end     { align-content: flex-end !important; }
      .align-content#{$_infix}-center  { align-content: center !important; }
      .align-content#{$_infix}-between { align-content: space-between !important; }
      .align-content#{$_infix}-around  { align-content: space-around !important; }
      .align-content#{$_infix}-stretch { align-content: stretch !important; }

      .align-self#{$_infix}-auto     { align-self: auto !important; }
      .align-self#{$_infix}-start    { align-self: flex-start !important; }
      .align-self#{$_infix}-end      { align-self: flex-end !important; }
      .align-self#{$_infix}-center   { align-self: center !important; }
      .align-self#{$_infix}-baseline { align-self: baseline !important; }
      .align-self#{$_infix}-stretch  { align-self: stretch !important; }
      
    }
  }
  
  .flex-auto {
    flex: auto !important; // enlarge a flex-child to fill it"s flex-container
  }
}

@mixin sn-chat-bot($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .sn-chat-bot {
    &__wrapper {
      width: 400px;
      max-height: 600px;
      height: calc(100% - 150px);
      position: fixed;
      z-index: 9999;
      bottom: 145px;
      right: 50px;
      display: block;

      @media only screen and (max-width: 1075px) {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: auto;
      }

      &.hidden {
        display: none;
      }

      iframe {
        border: none;
        background-color: #68A1AF;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16), 0 3px 12px 0 rgba(0, 0, 0, 0.08);
        height: 100%;
        width: 100%;

        @media only screen and (max-width: 1075px) {
          box-shadow: none;
          position: fixed;
          top: 40px;
          left: 0;
          bottom: 0;
          right: 0;
          height: calc(100% - 40px);
        }
      }

      &__header {
        display: none;

        @media only screen and (max-width: 1075px) {
          display: block;
          background-color: #68A1AF;
          width: 100%;
          height: 40px;

          span {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 30px;
            height: 30px;
            cursor: pointer;
            background-size: 25px 25px;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZpZXdCb3g9IjAgMCAxNzIgMTcyIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2UtbGluZWNhcD0iYnV0dCIgc3Ryb2tlLWxpbmVqb2luPSJtaXRlciIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2UtZGFzaGFycmF5PSIiIHN0cm9rZS1kYXNob2Zmc2V0PSIwIiBmb250LWZhbWlseT0ibm9uZSIgZm9udC13ZWlnaHQ9Im5vbmUiIGZvbnQtc2l6ZT0ibm9uZSIgdGV4dC1hbmNob3I9Im5vbmUiIHN0eWxlPSJtaXgtYmxlbmQtbW9kZTogbm9ybWFsIj48cGF0aCBkPSJNMCwxNzJ2LTE3MmgxNzJ2MTcyeiIgZmlsbD0ibm9uZSI+PC9wYXRoPjxnIGZpbGw9IiNmZmZmZmYiPjxwYXRoIGQ9Ik00NS43NDM0OSwzNC4yODgwMmMtNC42NjQwMSwwLjAwNTc4IC04Ljg1OTkyLDIuODM1NjcgLTEwLjYxMjg1LDcuMTU3NzVjLTEuNzUyOTIsNC4zMjIwNyAtMC43MTM2Miw5LjI3NTI0IDIuNjI4NzMsMTIuNTI4MTlsMzIuMDI2MDQsMzIuMDI2MDRsLTMyLjAyNjA0LDMyLjAyNjA0Yy0yLjk5NTUyLDIuODc2MDQgLTQuMjAyMTgsNy4xNDY3OCAtMy4xNTQ2MSwxMS4xNjUxNmMxLjA0NzU2LDQuMDE4MzggNC4xODU2Niw3LjE1NjQ3IDguMjA0MDQsOC4yMDQwM2M0LjAxODM4LDEuMDQ3NTYgOC4yODkxMiwtMC4xNTkxIDExLjE2NTE2LC0zLjE1NDYybDMyLjAyNjA0LC0zMi4wMjYwNGwzMi4wMjYwNCwzMi4wMjYwNGMyLjg3NjAzLDIuOTk1NTYgNy4xNDY3OSw0LjIwMjI1IDExLjE2NTIsMy4xNTQ3YzQuMDE4NCwtMS4wNDc1NiA3LjE1NjUyLC00LjE4NTY3IDguMjA0MDgsLTguMjA0MDhjMS4wNDc1NiwtNC4wMTg0IC0wLjE1OTEzLC04LjI4OTE3IC0zLjE1NDcsLTExLjE2NTJsLTMyLjAyNjA0LC0zMi4wMjYwNGwzMi4wMjYwNCwtMzIuMDI2MDRjMy4zOTEzOSwtMy4yOTY1NyA0LjQxMTEsLTguMzM3MDIgMi41Njc3OCwtMTIuNjkyNmMtMS44NDMzMSwtNC4zNTU1OCAtNi4xNzE1MSwtNy4xMzI3NSAtMTAuODk5MDMsLTYuOTkzMzRjLTIuOTc5MDIsMC4wODg3NiAtNS44MDY0NywxLjMzMzgxIC03Ljg4MzMzLDMuNDcxMzVsLTMyLjAyNjA0LDMyLjAyNjA0bC0zMi4wMjYwNCwtMzIuMDI2MDRjLTIuMTYxNTUsLTIuMjIxOTYgLTUuMTMwNTYsLTMuNDc0MiAtOC4yMzA0NywtMy40NzEzNXoiPjwvcGF0aD48L2c+PC9nPjwvc3ZnPg==);
          }
        }
      }
    }

    &__launcher {
      background-image: url(../../images/virtual-agent-chat-icon.png);
      background-repeat: no-repeat;
      background-size: 40px 40px;
      background-color: #009999;
      background-position: center;
      position: fixed;
      z-index: 9998;
      bottom: 40px;
      right: 50px;
      width: 80px;
      height: 80px;
      border-radius: 40px;
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16), 0 3px 12px 0 rgba(0, 0, 0, 0.08);

      @media only screen and (max-width: 1075px) {
        background-size: 35px 35px;
        bottom: 30px;
        right: 30px;
        width: 60px;
        height: 60px;
        border-radius: 30px;
      }

      &.as-close {
        background-image: url(../../images/virtual-agent-chat-icon-close.png);
      }
    }

    &__spinner {
      position: fixed;
      bottom: 370px;
      right: 225px;
    }

    &__spinner-background {
      width: 400px;
      max-height: 600px;
      height: calc(100% - 150px);
      position: fixed;
      z-index: 9998;
      bottom: 145px;
      right: 50px;
      display: block;
      background-color: white;
    }
  }

}

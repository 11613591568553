@mixin ln-meta-menu($options: ()) {
  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .meta-menu {
    &__title {
      display: block;
    }

    &__list {
      overflow: inherit;
    }

    &__link {
      &:focus-visible {
        box-shadow: none;
      }

      [class^="icon-"]::before,
      [class*=" icon-"]::before {
        position: relative;
        top: 5px;
        line-height: 1px;
      }
    }
  }
}

hgroup {
  margin-bottom: 1.2rem;
}

hgroup > *:nth-child(-n + 1) {
  margin-bottom: 0;
}

hgroup > *:nth-child(n + 2) {
  margin-top: 0;
}

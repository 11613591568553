@mixin text-overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

/// Component: Inpage Navigation
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Inpage Navigation
/// sh-textfield($options: (
///
/// ));
///
@mixin sh-inpage-navigation($options: ()) {
  $_defaults: (
    x-spacer: to-rem(20),
    y-spacer: to-rem(20),
    line-height: to-rem(30),
    font-size: 1rem,
  );
  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}inpage-navigation";

  #{$_component} {
    width: 100%;
    margin-bottom: map-get($_config, "y-spacer");

    &__panel {
      border-bottom: 1px solid sh-color("black-30");
      position: relative;
      display: flex;
      justify-content: space-between;
      align-content: center;
      flex-wrap: wrap;
      padding: map-get($_config, "y-padding") 0;

      @include media-query("md") {
        flex-direction: row;
      }
    }

    &__container {
      display: flex;
      align-items: center;
      @include media-query("md") {
        margin: 0;
        z-index: sh-z-index("sticky");
      }
    }

    &__cta {
      align-self: center;
      width: 100%;
      margin: map-get($_config, "y-spacer") * 0.25 0 to-rem(14);

      @include media-query("md") {
        width: inherit;
        white-space: nowrap;
        margin: map-get($_config, "y-spacer") * 0.25 0;
      }
    }

    &__selectbox {
      display: block;
      height: auto;
      margin: 0;
      background: transparent;
      border: 0;

      @include media-query("md") {
        display: none;
      }
    }

    &__select {
      height: auto;
      padding: map-get($_config, "y-spacer") map-get($_config, "x-spacer");
      line-height: map-get($_config, "line-height");
      white-space: nowrap;
      text-align: center;
      cursor: pointer;
      color: sh-color("black-90");
      font-weight: sh-font-weight("semibold");
    }

    & .selectbox__option {
      color: sh-color("black-60");
      font-weight: sh-font-weight("semibold");
    }

    &__list {
      display: none;
      list-style: none;
      white-space: nowrap;
      margin: 0;
      padding: 0;

      @include media-query("md") {
        display: flex; // makes list horizontal
      }
    }

    &__item {
      display: inline-block; // have to be inline-block for JavaScript _checkCollapse() to work
      margin: 0;
      padding: 0;
    }

    &__link {
      padding: map-get($_config, "y-spacer") map-get($_config, "x-spacer");
      line-height: map-get($_config, "line-height");
      white-space: nowrap;
      text-align: center;
      cursor: pointer;
      color: sh-color("black-60");
      font-weight: sh-font-weight("semibold");
      display: block;

      @include focus {
        outline: none;
        box-shadow: none;
        color: sh-color("black-60");

        &::after {
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          border-bottom: 2px solid rgba(sh-color("orange"), 0.5);
        }
      }

      @include hover-active {
        color: sh-color("black-90");

        &::after {
          border-bottom: none;
        }
      }

      &.is-active {
        color: inherit;

        &::after {
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          border-bottom: 2px solid sh-color("orange");
        }
      }
    }

    &.is-collapsed {
      .#{$prefix}inpage-navigation {
        &__list {
          display: none;
        }
        &__selectbox {
          display: block;
        }
        &__panel {
          @include media-query("md") {
            padding: 0;
          }
        }
      }
    }

    @content;
  }

  //sticky navigation appears head-up when page is scrolled down
  .#{$prefix}inpage-navigation--clone {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transform: translate(0, -100%);
    transition: transform 0.3s ease-in;
    background-color: sh-color("black-7");
    border-bottom: 1px solid sh-color("black-30");
    z-index: sh-z-index("tooltip");

    &.is-visible {
      transform: translate(0, 0);
    }

    .#{$prefix}inpage-navigation__panel {
      border: none;
    }
  }
}

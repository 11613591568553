@mixin ln-soa($options: ()) {

    $_default: ();
    $config: map-merge($_default, $options);

    .soa-overlay {
        background-color: #f5f5f5;
    }

    .soa-overview {
        display: flex;
        gap: 20px;

        &-item {
            margin-bottom: 10px;

            &-text {
                margin-left: 10px;
            }
        }
    }

    .soa-icon {
        width: 50px;
        height: 50px;

        img {
            width:100%;
            height:100%;
            object-fit: contain;
            overflow: hidden;
        }
    }

    .soa-filter {
        position: sticky;
        top: 0;
        z-index: 666;

        .tabs__list {
            margin-bottom: 0 !important;
        }
    }

    .soa-widget {
        padding: 1.666667rem;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px -1px 2px rgba(0, 0, 0, 0.05);
        max-width: 500px;
    }

    .soa-overview-widget {
        box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.2);
        height: initial !important;
    }

    .fleet-overview-cards {
        gap: 4rem;
    }

    .soa-alert {
        margin-top: 1.3333rem;
        background-color: #EDEDED;

        &__indicator-container {
            padding: 0.8333rem;
        }

        .alert__container {
            padding-top: 0.8333rem;
            padding-bottom: 0.8333rem;
        }
    }

    .icon-soa-green::after {
        font-family: "sh-icons-extended";
        speak: none;
        -webkit-font-smoothing: antialiased;
        text-align: center;
        vertical-align: baseline;
        background-color: rgb(0, 154, 56);
        color: rgb(255, 255, 255);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1em;
        width: 1.66667rem;
        height: 1.66667rem;
        line-height: 1.7;
        border-radius: 50%;
        content: "\e818";
        font-weight: normal !important;
        font-style: normal !important;
        font-variant: normal !important;
        text-transform: none !important;
    }

    .icon-soa-yellow::after {
        font-family: "sh-icons-extended";
        speak: none;
        -webkit-font-smoothing: antialiased;
        text-align: center;
        vertical-align: baseline;
        background-color: rgb(255, 210, 0);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1em;
        width: 1.66667rem;
        height: 1.66667rem;
        line-height: 1.7;
        border-radius: 50%;
        content: "\e836";
        color: rgb(0, 0, 0);
        font-weight: normal !important;
        font-style: normal !important;
        font-variant: normal !important;
        text-transform: none !important;
    }

    .icon-soa-red::after {
        font-family: "sh-icons-extended";
        speak: none;
        -webkit-font-smoothing: antialiased;
        text-align: center;
        vertical-align: baseline;
        background-color: rgb(231, 0, 29);
        color: rgb(255, 255, 255);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1em;
        width: 1.66667rem;
        height: 1.66667rem;
        line-height: 1.7;
        border-radius: 50%;
        content: "\e817";
        font-weight: normal !important;
        font-style: normal !important;
        font-variant: normal !important;
        text-transform: none !important;
    }

    .soa-progress {
        @include sh-font-family("text");

        &__status {
            @include sh-font-weight("bold");
            @include sh-font-size("large");
            color: #1a1a1a;
            width: 100%;
            text-align: left;
            letter-spacing: 0;
            line-height: to-rem(20px);
            margin-bottom: to-rem(10px);
        }

        &__bar-container {
            width: 100%;
            background-color: #e6e6e6;
            border-radius: 5px;
        }

        &__bar {
            border-radius: 5px 0 0 5px;
            background-color: sh-color("orange");
            transition: width map-get(sh-transition("base"), "duration") linear;
            text-align: right;
            padding-right: 0.5em;
            color: inherit;
            white-space: nowrap;
            overflow: hidden;
            height: 10px;
        }

        &__additional-text {
            @include sh-font-size("xsmall");
            color: #666666;
            letter-spacing: 0;
            margin-top: to-rem(10px);
        }

        &--tiny {
            font-size: 2px;
            color: sh-color("orange");
        }
    }
}
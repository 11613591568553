@mixin ln-spr-detail-overlay($options: ()) {
  .spr-detail-overlay {

    &__y-axis-pointer {
      .ngx-charts {
        .y {
          .tick {
            text {
              cursor: pointer;
            }
          }
        }
      }
    }

    .filter-panel__tabs {
      width: calc(100% - 60px);
      display: inline-block;

      &.small {
        width: calc(100% - 100px);
      }
    }

    .widget-filter {
      .spr-modality-filter {
        position: absolute;
        display: inline-block;
        top: 75px;
        right: 30px;
        z-index: sh-z-index('dropdown');
      }

      .filter-btn {
        @extend .button ;
        @extend .margin-bottom-tiny-2;
        @extend .margin-right-tiny-2;
        @extend .float-right;

        &.small {
          @extend .button--small;

          &.primary {
            @extend .button--primary;
          }
        }

        &.icon {
          @extend .button--control;
          display: inline-block;
          position: absolute;
          top: 55px;
          right: 10px;
        }
      }

      .drilldown-back-btn {
        @extend .button ;
        @extend .margin-bottom-tiny-2;
        @extend .margin-right-tiny-2;
        @extend .float-right;

        &.icon {
          @extend .button--control;
          display: inline-block;
          position: absolute;
          top: 75px;
          right: 70px;

          img {
            height: 20px;
          }
        }
      }

      .dropdown__panel {
        left: auto;
        min-width: 481px;
        min-height: 200px;
        right: 25px;

        @include media-query('md-down') {
          position: fixed;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          min-height: 100%;
          min-width: auto;
          transform: translate3d(0, 0, 0);
          z-index: sh-z-index('dropdown');

          .spr-configuration__applicable-modal {
            margin-top: 10px;
          }
        }

        .ddc-overlay__close {
          @include media-query('md-up') {
            display: none;
          }
        }
      }
    }

    .modal-dialog__body {
      max-height: calc(100vh - 13rem);
    }

    .spr-tubeguard-details {
      &__status {
        width: 1rem;
        height: 1rem;
        border-radius: 0.5rem;
        display: inline-block;
        vertical-align: middle;
        @include sh-background-color('black-60');

        &--1 {
          background-color: #009999;
        }

        &--2 {
          background-color: sh-color('green-50');
        }

        &--3 {
          background-color: #D9D9D9;
        }

        &--4 {
          background-color: #000000;
        }
      }

      &__action-list {
        & > div:first-child {
          display: inline-block;
          vertical-align: top;
          width: calc(25% - 5px);
        }

        & > div:last-child {
          display: inline-block;
        }

        &__link {
          i {
            vertical-align: middle;
            line-height: 1.1;
          }

          span {
            cursor: pointer;

            &:hover {
              @include sh-color('orange');
            }
          }
        }

        @include media-query('lg-down') {
          & > div:first-child {
            display: block;
            width: 100%;
          }

          & > div:last-child {
            display: block;
          }
        }
      }
    }

    .spr-helium-level-overview {
      &__status {
        width: 1rem;
        height: 1rem;
        border-radius: 0.5rem;
        display: inline-block;
        vertical-align: middle;
        @include sh-background-color('black-60');

        &--1 {
          @include sh-background-color('red');
        }

        &--2 {
          @include sh-background-color('black-60');
        }

        &--3 {
          @include sh-background-color('green')
        }
      }
    }

    .spr-spare-parts-overview {
      &__arrow-align {
        float: left;
      }

      &__card-icon {
        position: relative;
        line-height: 1px;
      }

      &__clickable {
        cursor: pointer;
        @include sh-color('black-60');

        &:hover {
          @include sh-color('black');
        }
      }
    }

    .spr-coil-care-overview {
      &__icon-align {
        float: left;
        margin-right: 0.3em;
      }

      &__status {
        width: 1rem;
        height: 1rem;
        border-radius: 0.5rem;
        display: inline-block;
        vertical-align: middle;
        @include sh-background-color('black-60');

        &--1 {
          @include sh-background-color('green');
        }

        &--2 {
          @include sh-background-color('yellow');
        }
      }
    }


    .spr-env-data-overview {
      &__icon-align {
        float: left;
        margin-right: 0.3em;
      }

      &__status {
        width: 1rem;
        height: 1rem;
        border-radius: 0.5rem;
        display: inline-block;
        vertical-align: middle;
        @include sh-background-color('black-60');

        &--1 {
          @include sh-background-color('green');
        }

        &--2 {
          @include sh-background-color('yellow');
        }
      }
    }

    @include media-query('md-down') {
      ngx-charts-legend ul.legend-labels {
        white-space: normal;
      }
    }
  }

  .button-group--views {
    .icon::before {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    @include media-query('sm-down') {
      display: inline-block;
      margin-left: 1rem;
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .grid-layout {
      p {
        display: inline-block;
        width: 50%;
        vertical-align: middle;
      }
    }
  }
}

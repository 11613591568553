body {
  @include sh-background-color("white");
  @include sh-font-family("text");
  @include sh-font-weight("normal");
  @include sh-line-height("medium");
  @include sh-color("black-90");
  font-style: normal;
  padding: 0;
  margin: 0;
  position: relative;
  cursor: auto;
  height: 100%;
  font-size: 100%;
}

//
//
//

body:after {
  content: "xs";
  display: none; /* comment this line for debugging purposes */
}

@include media-query("sm") {
  body:after {
    content: "sm"
  }
}

@include media-query("md") {
  body:after {
    content: "md"
  }
}

@include media-query("lg") {
  body:after {
    content: "lg"
  }
}

@include media-query("xl") {
  body:after {
    content: "xl"
  }
}

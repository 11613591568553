@mixin ln-text-utils($options: ()) {

  $_default: ();
  $config: map-merge($_default, $options);

  .word-wrap-break-word {
    word-wrap: break-word
  }

  .word-break-break-word {
    word-break: break-word;
  }

  .white-space-pre {
    white-space: pre;
  }

  .white-space-nowrap {
    white-space: nowrap;
  }
  
  .overflow-wrap-anywhere {
    overflow-wrap: anywhere;
  }

  .disable-tab-link {
    pointer-events: none;
    color: #cccccc !important;
  }

  .capitalize {
    text-transform: capitalize;
  }
}

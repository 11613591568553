@mixin ln-fixed-flex-layout($options: ()) {
    $_defaults: ();
  
    $_config: map-merge($_defaults, $options);
  
    .#{$prefix}fixed-flex-layout {
        
        display: flex;
        width: 100%;

        &__fixed-container {
            flex-grow: 0;
            min-width: 0;
            min-height: 0;
        }

        &__flex-container {
            flex: 1 1 auto;
        }

        &--vertical {
            flex-direction: column;
            height: 100%;
            width: inherit;
        }
    }
  
    .webkit-only-flex-basis-full {
      -webkit-flex-basis: 100%;
    }
  }

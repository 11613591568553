@mixin ln-my-customer-panel($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .my-customer-panel {


    position: absolute;
    top: 0;
    width: 100%;
    right: 0;
    bottom: 0;
    overflow: hidden;
    overflow-y: auto;
    padding-top: 0;
    padding-right: 0.833rem;
    padding-left: 0.833rem;
    padding-bottom: 1rem;
    @include sh-background-color('white');

    &--equipment {
      padding-top: 1rem;
      transform: translate3d(100%, 0, 0);
      transition: transform 0.3s ease-in;
      @include sh-z-index('content-raised');

      &.is-active {
        transform: translate3d(0, 0, 0);
      }
    }

    &__remove-all {
      float: right;
    }

    &--sticky-footer {
      position: absolute;
      padding: 1rem 1rem 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-shadow: 0 0 1rem (rgba(sh-color('black-90'), .2));
    }

    @include media-query('md') {
      padding-top: 1rem;
    }

    @include media-query('xl') {
      width: 66.6666666%;
      padding-left: 1.66666rem;
      transform: translate3d(0, 0, 0);

      &--half-width {
        width: 50%;
      }

    }
  }
}

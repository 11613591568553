@mixin ln-detail-filter-panel($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .detail-filter-panel {
    padding: 0.8333rem;

    &__margin-top-negative {
      margin-top: -1rem;
    }
  }
}

@mixin ln-text-overflow-ellipsis($options: ()) {
  
    $_default: ();
    $config: map-merge($_default, $options);

    .ln-text-overflow-ellipsis {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
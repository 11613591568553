//
// Dependencies
//

@if not function-exists(to-em) {
  @warn "Missing dependency: function => to-em";
}

@if not variable-exists("sh-breakpoints") {
  @warn "Missing dependency: variable => $sh-breakpoints";
}

//
// Parameters
//

//
$media-query-up-offset: to-em(0px, false) !default;

//
$media-query-down-offset: to-em(1px, false) !default;

//
$media-query-screen: "only screen";

//
//
//

@function media-query-create-up($breakpoint) {
  $min-breakpoint: $breakpoint + $media-query-up-offset;
  $media-query: "#{$media-query-screen} and (min-width: #{$min-breakpoint})";

  @return $media-query;
}

@function media-query-create-down($breakpoint) {
  $max-breakpoint: $breakpoint - $media-query-down-offset;
  $media-query: "#{$media-query-screen} and (max-width: #{$max-breakpoint})";

  @return $media-query;
}

@function media-query-create-only($min-value, $max-value) {
  $max-breakpoint: $max-value - $media-query-down-offset;
  $media-query: "#{$min-value} and (max-width: #{$max-breakpoint})";

  @return $media-query;
}

@mixin media-query($request) {
  @if str-length($request) == 2 {
    $request: $request + "-up";
  }

  $name: str-slice($request, 0, 2);
  $direction: str-slice($request, 4);

  $lower-bound: map-get($sh-breakpoints, $name);
  $upper-bound: breakpoint-next($name, $sh-breakpoints, map-keys($sh-breakpoints));
  $media-query: null;

  @if $direction == "down" {
    $media-query: media-query-create-down($lower-bound);
  } @else {
    @if direction == "only" {
      $media-query: media-query-create-only(media-query-create-up($lower-bound), $upper-bound);
    } @else {
      $media-query: media-query-create-up($lower-bound);
    }
  }

  @media #{$media-query} {
    @content;
  }
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $sh-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $sh-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $sh-breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}

// Media between the breakpoint"s minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin media-breakpoint-only($name, $breakpoints: $sh-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $max: breakpoint-max($name, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($name);
  } @else if $min == null {
    @include media-breakpoint-down($name);
  }
}

@mixin ln-navigation-bar($options: ()) {

  $_defaults: ();
  $_config: map-merge($_defaults, $options);

  .navigation-bar {
    &__link.is-active:not(.dropdown__toggle-button) {
      pointer-events: none;
      cursor: default;
    }

    .menu__item.is-active {
      @include sh-background-color('black-7');
    }

    @include media-query('md-down') {
      &__list {
        overflow-y: auto;
        bottom: 53px !important;
      }

      &__container {
        top: auto !important;
      }

      &__panel, &__wrapper {
        height: 50px;
      }
    }

    &__group-menu {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: .833rem;

      .position-relative {
        position: relative;
      }

      .padding-left {
        padding-left: .833rem;
      }
      
      .mobile-view-layout {
        min-height: 100vh;
        @extend  .navigation-bar__wrapper;
        align-items: inherit;
        padding-top: .35rem;
        
        .tabs__item {
          @extend .tabs__item;
          text-align: left;
        }
      }
      
      .navbar-menu-font-size {
        font-size: .8888888889rem;
      }
    }
  }
}

@mixin ln-google-maps($options: ()) {
    
    $_defaults: ();
    $config: map-merge($_defaults, $options);
    
    .google-maps {

        min-height: 440px;

        &--dashboard {
            height: calc(100% - #{to-rem(54px)});
        }
      
        &-popup {
            
            p {
                font-size: to-rem(22);
                font-weight: bold;
                margin-bottom: 0;
            }
            
            ul {
                list-style-type: none; /* no default bullets */
                padding: 0;
                margin: 0;
            }
        
            li {

                font-size: to-rem(15);
            
                &.green {
                    &:before {
                    background-color: sh-color('green');
                    }
                }
            
                &.yellow {
                    &:before {
                    background-color: sh-color('yellow');
                    }
                }
            
                &.red {
                    &:before {
                    background-color: sh-color('red');
                    }
                }
                &.All{
                    &:before {
                    display: none;
                    }
                }

                &:before { /* the custom styled bullets */
                    border-radius: 50%;
                    content: "";
                    display: inline-block;
                    margin-right: 10px;
                    margin-bottom: 2px;
                    height: 10px;
                    width: 10px;
                }


                a {
                  cursor: pointer;
                }
            }
        }
    }
}

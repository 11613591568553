/// Component: Checkbox
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Checkbox
/// sh-checkbox($options: (
///
/// ));
///
@use "sass:math";

@mixin sh-checkbox($options: ()) {
  $_defaults: ();
  $_config: map-merge($_defaults, $options);
  $_component: ".#{$prefix}checkbox";

  // Font Sizes
  $font-size: 1rem !default;
  $active-font-size: $font-size * 0.8 !default;
  $active-top: -$font-size !default;

  // Margin & Paddings
  $gap: $font-size !default;
  $spacer: $font-size * 0.125 !default;
  $fieldset-margin: 0 0 $gap * 2 !default;
  $checkbox-gap: $font-size * 2 !default;
  $checkbox-top: 0.3rem !default;
  $radio-margin: math.div($font-size, -4) !default;

  // Colors
  $input-color: sh-color("black-90") !default;
  $border-color: sh-color("black-20") !default;
  $label-color: sh-color("black-20") !default;
  $active-color: sh-color("black-30") !default;
  $focus-color: sh-color("orange") !default;
  $error-color: sh-color("red") !default;

  $icon-font-family: sh-font-family("icon");

  // Animation
  $transition-speed: 0.28s !default;

  //z-index
  $select-open-zindex: sh-z-index("tooltip") !default; // vendor default is 9999 for selectric and pikaday

  ///

  #{$_component} {
    opacity: 0;
    width: to-rem(20);
    height: to-rem(20);
    position: absolute;
  }

  #{$_component} + label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    width: auto;
    padding-left: to-rem(15);
    margin: 0 to-rem(20) 0 to-rem(20);
    user-select: none;
    @include sh-font-family("text");

    &::before {
      // margin-top: 1px;
      line-height: 1;
      content: "";
      display: inline-block;
      width: to-rem(20);
      height: to-rem(20);
      position: absolute;
      left: to-rem(-20);
      background-color: sh-color("white");
      border: 1px solid sh-color("black-60");
      top: $checkbox-top;
      border-radius: 2px;
    }
  }

  #{$_component} {
    @include hover {
      & + label::before {
        @include sh-hover-background-color("white");
      }
    }

    &:checked + label::before {
      background-color: sh-color("orange");
      border: 1px solid sh-color("orange");
      content: "\e818";
      font-family: $icon-font-family;
      font-size: sh-font-size("xsmall");
      font-weight: sh-font-weight("normal");
      line-height: 1rem;
      color: sh-color("white");
      text-align: center;
    }
    @include hover {
      &:checked + label::before {
        @include sh-hover-background-color("orange");
        border-color: sh-shift-darkness(sh-color("orange"));
      }
    }

    &:indeterminate + label::before {
      background-color: sh-color("orange");
      border: 1px solid sh-color("orange");
      content: "\e82e";
      font-family: $icon-font-family;
      font-size: sh-font-size("small");
      font-weight: sh-font-weight("normal");
      line-height: 1rem;
      color: sh-color("white");
      text-align: center;
    }

    @include focus {
      & + label::before {
        @include sh-outline();
      }
    }

    &:disabled + label {
      @include sh-opacity("disabled");
      pointer-events: none;
      cursor: default;
    }

    &.is-invalid {
      + label:before {
        border-color: sh-color("red");
      }

      ~ #{$_component}__hint {
        @include sh-font-size("xsmall");
        @include sh-color("black-60");
        display: block;
        margin-left: 2rem;
        line-height: to-rem(20); // suits spacing concept
        display: flex;
        align-items: center;

        &::before {
          @include sh-indicator(
            (
              "icon": "warning",
              "bgColor": "red",
            )
          )  {
            content: "\e817";
          };
          flex-shrink: 0;
          margin-right: to-rem(5);
          margin-left: -0.1em; // subtile offset
          margin-top: calc((30px - 1.7em) / 2); // position depends on indicator size and hint line-height
        }
      }
    }

    // Dark theme
    .bg-color-black-90 &,
    .bg-color-black &,
    :host([theme="dark"]) & {
      
      & + label::before {
        background-color: sh-color("black-90");
        border-color: sh-color("black-40");
      }

      @include hover {
        & + label::before {
          @include sh-hover-background-color("black-90");
        }
      }
      
      &:checked + label::before,
      &:indeterminate + label::before {
        background-color: sh-color("orange");
        border-color: sh-color("orange");
      }
      @include hover {
        &:checked + label::before,
        &:indeterminate + label::before {
          @include sh-hover-background-color("orange");
          border-color: sh-shift-darkness(sh-color("orange"));
        }
      }
      
      &.is-invalid {
        + label:before {
          border-color: sh-color("red");
        }
        ~ #{$_component}__hint {
          @include sh-color("black-40");
        }
      }
    }

    @content;
  }
}

/// Collection
/// @access public
/// @author Paul Facklam (pf@click-solutions.de)
/// @param {Map} $options [()] - Option map for configuration. Items with default values can be seen in the example.
/// @example scss - Collection
/// sh-aspect-ratio($options: (
///   gutter-width: 1rem
/// ));
///
@mixin sh-collection($options: ()) {
  $_defaults: (
    text-leading: 0.611rem,
    border-width: 1px,
    border-color: sh-color("black-10"),
    y-spacing: 1.66rem,
    x-spacing: 1rem,
  );

  $_config: map-merge($_defaults, $options);

  $_border-width: map-get($_config, "border-width");
  $_border-color: map-get($_config, "border-color");

  $_y-spacing: map-get($_config, "y-spacing");
  $_x-spacing: map-get($_config, "x-spacing");
  $_padding: $_y-spacing $_x-spacing;

  .#{$prefix}collection {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    list-style: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
  }
}

@mixin ln-header($options: ()) {
    
    $_defaults: ();
    $_config: map-merge($_defaults, $options);
  
    .header {
        z-index: 2;
        position: fixed;
        left: 0;
        right: 0;
        background-color: sh-color('white');
    }    
}
